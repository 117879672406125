import produce from "immer";
import { Reducer } from "redux";
import * as d3 from "d3";
import {
  EPerformanceLevel,
  IProductState,
  ProductActionTypes,
  REQUEST_PRODUCTS,
  REQUEST_PRODUCTS_ERROR,
  REQUEST_PRODUCTS_SUCCESS,
  SET_STOCK_SUCCESS,
  REQUEST_INSIGHTS,
  REQUEST_INSIGHTS_ERROR,
  REQUEST_INSIGHTS_SUCCESS
} from "./types";
import { LOWER_PERFORMANCE_THRESHOLD, UPPER_PERFORMANCE_THRESHOLD } from "../../constants";

export const initialState: IProductState = {
  error: false,
  insights: {},
  insightsError: false,
  insightsLoading: false,
  loading: false,
  products: {}
};

const reducer: Reducer<IProductState, ProductActionTypes> = (
  state = initialState,
  action: ProductActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_PRODUCTS:
        draft.error = false;
        draft.loading = true;

        break;

      case REQUEST_PRODUCTS_SUCCESS:
        Object.keys(action.payload).forEach(locationId => {
          if (!draft.products[locationId]) {
            draft.products[locationId] = {};
          }

          Object.keys(action.payload[locationId]).forEach(beverageId => {
            const { tapPositions, validVolumePercentage, volume } = action.payload[locationId][
              beverageId
            ];

            const volumePerTap = !(
              volume !== null &&
              validVolumePercentage !== null &&
              validVolumePercentage !== 0
            )
              ? 0
              : tapPositions.length
              ? (volume * 100.0) / validVolumePercentage / tapPositions.length
              : (volume * 100.0) / validVolumePercentage;

            const performance = validVolumePercentage
              ? volumePerTap > UPPER_PERFORMANCE_THRESHOLD * (validVolumePercentage / 100)
                ? EPerformanceLevel.overperforming
                : volumePerTap < LOWER_PERFORMANCE_THRESHOLD * (validVolumePercentage / 100)
                ? EPerformanceLevel.underperforming
                : EPerformanceLevel.good
              : EPerformanceLevel.underperforming;

            draft.products[locationId][beverageId] = {
              ...action.payload[locationId][beverageId],
              avgKegsPerWeek: d3.mean(action.payload[locationId][beverageId].kegsPerWeek) ?? 0,
              performance,
              volumePerTap
            };
          });
        });

        draft.loading = false;
        draft.error = false;
        break;

      case REQUEST_PRODUCTS_ERROR:
        draft.products = {};
        draft.error = true;
        draft.loading = false;
        break;

      case SET_STOCK_SUCCESS:
        const products = state.products[action.payload.locationId];
        action.payload.stock.forEach(s => {
          products[s.beverageId].kegsInStock = s.numberKegs;
        });

        draft.products = {
          ...state.products,
          [action.payload.locationId]: products
        };
        break;

      case REQUEST_INSIGHTS:
        draft.insightsLoading = true;
        break;

      case REQUEST_INSIGHTS_ERROR:
        draft.insights = {};
        draft.insightsError = true;
        draft.insightsLoading = false;
        break;

      case REQUEST_INSIGHTS_SUCCESS:
        draft.insights = action.payload;
        draft.insightsLoading = false;
        break;

      default:
        break;
    }
  });

export default reducer;
