import React from "react";
import * as d3 from "d3";
import numeral from "numeral";
import {
  CUSTOM_DAY_TRESHOLD,
  convertToWeekShort,
  translateAxis,
  MONTHS_LONG,
  CUSTOM_MONTH_TRESHOLD,
  CUSTOM_DAY_TRESHOLD_COMPARE
} from "../../../../constants";
import Time from "../../../../lib/Time/Time";
import { day8 } from "../../../../lib/Time/TimeFunctions";

export abstract class Chart<P extends {}, S extends {}> extends React.Component<P, S> {
  public svgElement: any;

  public d3TimeFormat: any;

  public tooltipElement: any;

  public tooltip: any;

  public svg: any;

  public chartContainer: any;

  public margin = {
    top: 25,
    left: 5,
    right: 20,
    bottom: 5
  };

  public height = 0;

  public width = 0;

  public chartHeight = 0;

  public chartWidth = 0;

  public tooltipMarginLeft = 25;

  public borderScale: d3.ScaleLinear<number, number> = d3
    .scaleLinear()
    .domain([4, 8, 16])
    .range([0.5, 3, 4])
    .clamp(true);

  public getChartDimensions() {
    if (this.svgElement) {
      this.height = (
        this.svgElement.height || this.svgElement.parentNode.clientHeight
      ).baseVal.value;
      this.width = (this.svgElement.width || this.svgElement.parentNode.clientWidth).baseVal.value;
      this.chartHeight = this.height - this.margin.top - this.margin.bottom;
      this.chartWidth = this.width - this.margin.right - this.margin.left;
    }
  }

  getType(type: any, startDate: Time | null, endDate: Time | null, isCompare = false) {
    if (!startDate || !endDate) {
      return null;
    }
    if (type === "CUSTOM") {
      const CUSTOM_DAY_TRESH = isCompare ? CUSTOM_DAY_TRESHOLD_COMPARE : CUSTOM_DAY_TRESHOLD;
      const diff = startDate.diff(endDate, "day");
      return diff > CUSTOM_DAY_TRESH
        ? diff > CUSTOM_MONTH_TRESHOLD
          ? "CUSTOM_MONTH"
          : "CUSTOM_DAY"
        : "CUSTOM_HOUR";
    }
    return type;
  }

  getTooltipTitleText(time: number, type?: string) {
    if (!type) {
      return "UNKNOWN";
    }
    const date = new Time(time);
    switch (type) {
      case "DAY":
      case "YESTERDAY":
        return `${date.getHour()}:00`;
      case "WEEK":
        return this.d3TimeFormat.days[date.getWeekDay()];
      case "MONTH":
      case "4WEEKS":
        return this.d3TimeFormat.days[date.getWeekDay()];
      case "CUSTOM_DAY":
        return `${this.d3TimeFormat.shortMonths[date.getMonth()]} ${day8(date).getDay()}`;
      case "CUSTOM_HOUR":
        return `${date.getHour()}:00 - ${
          this.d3TimeFormat.shortMonths[date.getMonth()]
        } ${date.getDay()}`;
      case "CUSTOM_MONTH":
        return this.d3TimeFormat.months[date.getMonth()];
      default:
        break;
    }
    return "";
  }

  get4WeeksTicks(values: any[]) {
    const ticks: any = [];
    values.reverse().forEach((v: any, index: number) => {
      if (index % 7 === 0) {
        const tokens = v.split(" ");
        const t: any = new Time();
        t.set({ day: tokens[0] });
        t.set({ month: MONTHS_LONG[+tokens[1]] });
        t.set({ year: tokens[2] });
        const weekBefore = t.clone().sub({ day: 6 });
        ticks.push({
          tick: v,
          format: `${
            this.d3TimeFormat.shortMonths[weekBefore.getMonth()]
          } ${weekBefore.getDay()} - ${this.d3TimeFormat.shortMonths[t.getMonth()]} ${t.getDay()}`
        });
      }
    });
    return ticks;
  }

  getTooltipDateFormat(type?: any) {
    if (!type) {
      return "UNKNOWN";
    }
    switch (type) {
      case "DAY":
      case "YESTERDAY":
        return { month: "short", day: "numeric" };
      case "WEEK":
        return { month: "short", day: "numeric" };
      case "MONTH":
      case "4WEEKS":
        return { weekday: "short", month: "short", day: "numeric" };
      case "CUSTOM":
        return { weekday: "short", month: "short", day: "numeric" };
      default:
        break;
    }
    return "";
  }

  processNumber(d: any) {
    if (typeof d === "number") {
      return d > 1000 ? `${(d / 1000).toFixed(1)}K` : d;
    }
    return d;
  }

  public buildHorizontalGridSimple(yScale: d3.ScaleLinear<number, number>, tick: number) {
    const grid = this.appendOrSelect(this.chartContainer, ".grid", "g", "grid");
    grid.call(
      this.makeYGridlines(yScale, tick)
        .tickSize(-this.chartWidth)
        .tickFormat(() => "")
    );
    return grid;
  }

  public buildHorizontalGrid(yScale: d3.ScaleLinear<number, number>) {
    const grid = this.appendOrSelect(this.chartContainer, ".grid", "g", "grid");
    grid.call(
      this.makeYGridlines(yScale, 5)
        .tickSize(-this.chartWidth)
        .tickFormat(() => "")
    );
    return grid;
  }

  makeYGridlines(scale: any, tick: number) {
    return d3.axisLeft(scale).ticks(tick);
  }

  makeXGridlines(scale: any) {
    return d3.axisTop(scale).ticks(5);
  }

  buildCustomDomainPathVertical() {
    const domain = this.appendOrSelect(
      this.chartContainer,
      ".custom-domain",
      "line",
      "custom-domain"
    );
    domain.attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this.chartHeight);
  }

  buildCustomDomainPath() {
    const domain = this.appendOrSelect(
      this.chartContainer,
      ".custom-domain",
      "line",
      "custom-domain"
    );
    domain
      .attr("x1", 0)
      .attr("y1", this.chartHeight)
      .attr("x2", this.chartWidth)
      .attr("y2", this.chartHeight);
  }

  public buildVerticalAxis(
    yScale: d3.ScaleLinear<number, number>,
    transitionDuration = 500,
    tick = 5
  ) {
    const yAxisElement = this.appendOrSelect(this.chartContainer, ".y", "g", "axis y");
    const axisY = d3
      .axisLeft(yScale)
      .ticks(tick)
      .tickSize(10)
      .tickFormat((d: any) => (d != null ? `${numeral(d).format("0.[0]a")} L` : ""));
    yAxisElement
      .attr("transform", "translate(0, 0)")
      .transition()
      .duration(transitionDuration)
      .call(axisY);
    return yAxisElement;
  }

  public buildVerticalGrid(xScale: d3.ScaleLinear<number, number>) {
    const grid = this.appendOrSelect(this.chartContainer, ".grid", "g", "grid");
    grid.call(
      this.makeXGridlines(xScale)
        .tickSize(-this.chartHeight)
        .tickFormat(() => "")
    );
    return grid;
  }

  public buildHorizontalTimeBandAxis(
    xScale: d3.ScaleBand<string>,
    type: any = "4WEEKS",
    showTicks = false
  ) {
    const xAxisElemnt = this.appendOrSelect(
      this.chartContainer,
      ".x",
      "g",
      showTicks ? "axis x show-ticks" : "axis x"
    );

    // Get Ticks
    const values = xScale.domain();
    let ticks: any[] = [];
    let ticksAll: any[] = [];

    // Add starting ticks
    if (values.length > 0) {
      if (type === "CUSTOM_HOUR") {
        const tokens = values[0].split(" ");
        if (+tokens[0] > 0) {
          ticks.push(values[0]);
        }
      } else if (type === "CUSTOM_DAY") {
        if (values.length >= 30) {
          const tokens = values[0].split(" ");
          if (+tokens[0] > 1) {
            ticks.push(values[0]);
          }
        }
      }
    }

    values.reverse().forEach((v: any) => {
      if (type === "4WEEKS") {
        ticksAll = this.get4WeeksTicks(values);
        ticks = ticksAll.map((d: any) => d.tick);
      } else if (type === "CUSTOM_DAY") {
        const tokens = v.split(" ");
        if (values.length < 30) {
          ticks.push(v);
        } else if (tokens[0] === "1") {
          ticks.push(v);
        }
      } else if (type === "CUSTOM_HOUR") {
        const tokens = v.split(" ");
        if (tokens[0] === "0") {
          ticks.push(v);
        }
      } else if (type === "CUSTOM_MONTH") {
        ticks.push(v);
      }
    });
    const axisX = d3
      .axisBottom(xScale)
      .tickSize(this.margin.bottom) // -this.chartHeight)
      .tickPadding(16)
      .tickValues(ticks)
      .tickFormat((d: any, i: number) => {
        if (type === "4WEEKS") {
          return ticksAll[i].format;
        }
        if (type === "CUSTOM_DAY") {
          const tokens = d.split(" ");
          if (values.length < 30) {
            return `${this.d3TimeFormat.shortMonths[tokens[1]]} ${tokens[0]}`;
          }
          return this.d3TimeFormat.months[tokens[1]];
        }
        if (type === "CUSTOM_HOUR") {
          const tokens = d.split(" ");
          return `${this.d3TimeFormat.shortMonths[tokens[2]]} ${tokens[1]}`;
        }
        if (type === "CUSTOM_MONTH") {
          const tokens = d.split(" ");
          return this.d3TimeFormat.months[tokens[0]];
        }
        return d;
      });
    xAxisElemnt
      .attr("transform", `translate(0,${this.chartHeight})`)
      // .transition()
      // .duration(transitionDuration)
      .call(axisX);

    xAxisElemnt
      .selectAll("line")
      .attr("transform", () => {
        let pad = 0;
        switch (type) {
          case "4WEEKS":
            pad = xScale.bandwidth() * 0.5;
            break;
          case "CUSTOM_DAY":
            pad = values.length >= 30 ? xScale.bandwidth() * -0.5 : xScale.bandwidth() * 0.5;
            break;
          case "CUSTOM_HOUR":
            pad = -xScale.bandwidth() * 0.5;
            break;
          default:
            break;
        }
        return `translate(${pad}, 0)`;
      })
      .attr("opacity", (d: any, i: number) => {
        let opacity = 1;
        switch (type) {
          case "CUSTOM_DAY":
            opacity = values.length < 30 ? 0 : d === ticks[0] ? 0 : 1;
            break;
          case "CUSTOM_HOUR":
            opacity = i === 0 ? 0 : 1;
            break;
          case "CUSTOM_MONTH":
            opacity = 0;
            break;
          default:
            break;
        }
        return opacity;
      });
    xAxisElemnt
      .selectAll("text")
      .attr("y", 9)
      .attr("transform", (d: any, index: number) => {
        let pad = 0;
        switch (type) {
          case "4WEEKS":
            pad = -xScale.bandwidth() * 3.5 + xScale.bandwidth() * 0.5;
            break;
          case "CUSTOM_HOUR":
            pad = index === 1 || index === 0 ? xScale.bandwidth() * 7 : xScale.bandwidth() * 12;
            break;
          case "CUSTOM_DAY":
            if (values.length >= 30) {
              const end = values[values.length - 1].split(" ");
              const tokens = d.split(" ");
              pad =
                tokens[1] === end[1]
                  ? (xScale.bandwidth() * (31 - +tokens[0])) / 2
                  : xScale.bandwidth() * 15;
            } else {
              pad = 0;
            }
            break;
          default:
            break;
        }
        return `translate(${pad}, 0)`;
      });
    return xAxisElemnt;
  }

  public buildVerticalTimeBandAxis(
    xScale: d3.ScaleBand<string>,
    type: any = "4WEEKS",
    showTicks = false
  ) {
    const yAxisElement = this.appendOrSelect(
      this.chartContainer,
      ".y",
      "g",
      showTicks ? "axis y show-ticks" : "axis y"
    );

    // Get Ticks
    const values = xScale.domain();
    let ticks: any[] = [];
    let ticksAll: any[] = [];

    // Add starting ticks
    if (values.length > 0) {
      if (type === "CUSTOM_HOUR") {
        const tokens = values[0].split(" ");
        if (+tokens[0] > 0) {
          ticks.push(values[0]);
        }
      } else if (type === "CUSTOM_DAY") {
        if (values.length >= 30) {
          const tokens = values[0].split(" ");
          if (+tokens[0] > 1) {
            ticks.push(values[0]);
          }
        }
      }
    }

    values.reverse().forEach((v: any, i: number) => {
      if (type === "4WEEKS") {
        ticksAll = this.get4WeeksTicks(values);
        ticks = ticksAll.map((d: any) => d.tick);
      } else if (type === "CUSTOM_DAY") {
        const tokens = v.split(" ");
        if (values.length < 30) {
          if (values.length > 8) {
            if (i % 3 === 0) {
              ticks.push(v);
            }
          } else {
            ticks.push(v);
          }
        } else if (tokens[0] === "1") {
          ticks.push(v);
        }
      } else if (type === "CUSTOM_HOUR") {
        const tokens = v.split(" ");
        if (tokens[0] === "0") {
          ticks.push(v);
        }
      } else if (type === "CUSTOM_MONTH") {
        ticks.push(v);
      }
    });

    const axisY = d3
      .axisLeft(xScale)
      .tickSize(this.margin.left)
      .tickValues(ticks)
      .tickFormat((d: any, i: number) => {
        if (type === "4WEEKS") {
          return ticksAll[i].format;
        }
        if (type === "CUSTOM_DAY") {
          const tokens = d.split(" ");
          if (values.length < 30) {
            return `${this.d3TimeFormat.shortMonths[tokens[1]]} ${tokens[0]}`;
          }
          return this.d3TimeFormat.months[tokens[1]];
        }
        if (type === "CUSTOM_HOUR") {
          const tokens = d.split(" ");
          return `${this.d3TimeFormat.shortMonths[tokens[2]]} ${tokens[1]}`;
        }
        if (type === "CUSTOM_MONTH") {
          const tokens = d.split(" ");
          return this.d3TimeFormat.months[tokens[0]];
        }
        return "";
      });
    yAxisElement
      .attr("transform", "translate(-12,0)")
      // .transition()
      // .duration(transitionDuration)
      .call(axisY);

    yAxisElement
      .selectAll("line")
      .attr("transform", () => {
        let pad = 0;
        switch (type) {
          case "4WEEKS":
            pad = xScale.bandwidth() * 0.5;
            break;
          case "CUSTOM_DAY":
            pad = xScale.bandwidth() * 0.5;
            break;
          case "CUSTOM_HOUR":
            pad = -xScale.bandwidth() * 0.5;
            break;
          default:
            break;
        }
        return `translate(0, ${pad})`;
      })
      .attr("opacity", (d: any, i: number) => {
        let opacity = 1;
        switch (type) {
          case "CUSTOM_DAY":
            opacity = values.length < 30 ? 0 : d === ticks[0] ? 0 : 1;
            break;
          case "CUSTOM_HOUR":
            opacity = i === 0 ? 0 : 1;
            break;
          case "CUSTOM_MONTH":
            opacity = 0;
            break;
          default:
            break;
        }
        return opacity;
      });
    yAxisElement
      .selectAll("text")
      .attr("x", 0)
      .attr("text-anchor", type === "4WEEKS" ? "start" : "start")
      .attr("transform", (d: any, index: number) => {
        let pad = 0;
        switch (type) {
          case "4WEEKS":
            pad = -xScale.bandwidth() * 3.5;
            break;
          case "CUSTOM_HOUR":
            pad = index === 1 || index === 0 ? -xScale.bandwidth() * 6 : -xScale.bandwidth() * 12;
            break;
          case "CUSTOM_DAY":
            if (values.length >= 30) {
              const end = values[values.length - 1].split(" ");
              const tokens = d.split(" ");
              pad =
                tokens[1] === end[1]
                  ? (-xScale.bandwidth() * (31 - +tokens[0])) / 2
                  : -xScale.bandwidth() * 15;
            } else {
              pad = 0;
            }
            break;
          default:
            break;
        }
        return `rotate(-90) translate(${pad}, 0)`;
      });
    return yAxisElement;
  }

  public prepareChartArea(extraClass?: string, clipAreaId?: string) {
    this.svg = d3.select(this.svgElement);
    if (extraClass) {
      this.svg.classed(extraClass, true);
    }
    if (clipAreaId) {
      const defs = this.appendOrSelect(this.svg, "defs", "defs", "");
      const clip = this.appendOrSelect(defs, "clipPath", "clipPath", "");
      clip.attr("id", `${clipAreaId}-clip`);
      const rect = this.appendOrSelect(clip, "rect", "rect", "");
      rect
        .attr("x", 1)
        .attr("y", 0)
        .attr("height", this.chartHeight)
        .attr("width", this.chartWidth);
    }
    if (this.tooltipElement) {
      this.tooltip = d3.select(this.tooltipElement);
      this.svg.on("mouseout", () => this.tooltip.style("opacity", 0));
    }
    this.chartContainer = this.appendOrSelect(this.svg, ".chart-container", "g", "chart-container");
    this.chartContainer.attr("transform", `translate(${this.margin.left},${this.margin.top})`);
  }

  public prepareTimespan(startDate: Date, endDate: Date) {
    const result = {
      startDate: new Time().toJSDate(),
      endDate: new Time().toJSDate()
    };
    const diff = d3.timeDays(startDate, endDate).length;
    switch (diff) {
      case 1:
        result.startDate = d3.timeHour.offset(d3.timeHour(startDate), -1);
        result.endDate = d3.timeHour.offset(d3.timeHour(endDate), 1);
        break;
      case 7:
        result.startDate = d3.timeDay(startDate);
        result.endDate = d3.timeDay(endDate);
        break;
      default:
        break;
    }
    return result;
  }

  // Axis building options

  public appendXAxis() {
    const xAxisElement = this.appendOrSelect(this.chartContainer, ".x", "g", "axis x");
    return xAxisElement;
  }

  public appendYAxis() {
    const yAxisElement = this.appendOrSelect(this.chartContainer, ".y", "g", "axis y");
    return yAxisElement;
  }

  public buildLinearScaleXAxis(xScale: any) {
    const xAxisElement = this.appendXAxis();
    const axisX = d3
      .axisTop(xScale)
      .ticks(3)
      .tickSize(5)
      .tickFormat((d: any) => (d != null ? `${numeral(d).format("0.[0]a")} L` : ""));
    xAxisElement.call(axisX);
    return xAxisElement;
  }

  buildVerticalBandAxis(yScale: d3.ScaleBand<string>, abridged: any, week = false) {
    const yAxisElement = this.appendYAxis();
    const axisY = abridged
      ? d3
          .axisRight(yScale)
          .tickSize(0)
          .tickFormat((d: any) => (week ? this.d3TimeFormat.shortDays[d] : d))
      : d3
          .axisLeft(yScale)
          .tickSize(10)
          .tickFormat((d: any) => (week ? convertToWeekShort(d, this.d3TimeFormat.shortDays) : d));
    yAxisElement
      .attr("transform", abridged ? `translate(${abridged.left}, ${abridged.top})` : "")
      .transition()
      .call(axisY);
    return yAxisElement;
  }

  buildHorizontalBandAxis(xScale: d3.ScaleBand<string>, week = false, transitionDuration = 500) {
    const xAxisElemnt = this.appendOrSelect(this.chartContainer, ".x", "g", "axis x");

    const axisX = d3
      .axisBottom(xScale)
      .tickSize(5)
      .tickFormat((d: any) =>
        week ? this.d3TimeFormat.shortDays[d] : translateAxis(d, this.d3TimeFormat.days)
      );
    xAxisElemnt
      .attr("transform", `translate(0,${this.chartHeight})`)
      .transition()
      .duration(transitionDuration)
      .call(axisX);
    return xAxisElemnt;
  }

  // D3 building blocks
  appendOrSelect(container: any, selector: any, element: any, classString: string) {
    let result = container.select(selector);
    if (result.empty()) {
      result = container.append(element);
    }
    result.attr("class", classString);
    return result;
  }
}
