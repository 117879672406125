import React, { useRef } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReactDOMServer from "react-dom/server";
import { Provider, useSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import { Route, Router, Switch } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import PullToRefresh from "pulltorefreshjs";
import { SplashLoading } from "@ddm-design-system/icon";
import { Authentication } from "adal-ts";
import {
  INotificationStackRef,
  NotificationProvider,
  NotificationStack,
  useNotificationContext
} from "@ddm-design-system/notification";
import { rootReducer } from "./store";
import { AuthProvider, useAuthContext } from "./components/auth/AuthContext";
import rootSaga from "./sagas";
import Routes from "./routes";
import PrivateRoute from "./components/auth/PrivateRoute";
import { AppContext } from "./services";
import { AnalyticsProvider } from "./services/analytics";
import HomeContainer from "./components/home/HomeContainer";
import history from "./history";
import Logout from "./components/auth/Logout";
import CookiesForm from "./components/common/cookies_form/CookiesForm";
import Footer from "./components/common/footer/footer";
import { AnimatedDDM } from "./components/common/dm_icon/DMIcon";
import MailUnsubscribe from "./components/auth/MailUnsubscribe";
import Login from "./components/auth/Login";
import HomescreenModal from "./components/common/modals/homescreen_modal/HomescreenModal";
import FaultySensorModal from "./components/common/modals/faulty_sensor_modal/FaultySensorModal";
import { getCurrentLanguage, getIsLoadingContentful } from "./store/content/selectors";
import { PlacesProvider } from "./services/places";
import { RECAPTCHA_KEY } from "./constants";
import "./styles/index.scss";
import "./App.css";

export const context = new AppContext();
const sagaMiddleware = createSagaMiddleware();
const initialState = {};
const middleware = [sagaMiddleware];

PullToRefresh.init({
  mainElement: "body",
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  shouldPullToRefresh: () =>
    document.querySelector("#root")!.scrollTop === 0 &&
    !document.querySelector(".diana-portal") &&
    !document.querySelector(".ReactModal__Overlay"),
  onRefresh() {
    window.location.reload();
  },
  instructionsReleaseToRefresh: " ",
  instructionsPullToRefresh: " ",
  instructionsRefreshing: " ",
  iconRefreshing: ReactDOMServer.renderToString(<AnimatedDDM />)
});

// Enable redux devtools chrome extension
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga, context);

history.listen((listener: { pathname: any; search: any }) => {
  context.analyticsService.logPageView(listener.pathname + listener.search);
});

const App: React.FC = () => {
  Authentication.getAadRedirectProcessor().process();
  const authValue = useAuthContext(context.jCoreService, () => {
    persistor.flush();
  });
  const notificationStackRef = useRef<INotificationStackRef>(null);
  const notificationContext = useNotificationContext(notificationStackRef);

  context.setNotificationService(notificationContext);

  return (
    <div className="root-container">
      <AuthProvider value={authValue}>
        <AnalyticsProvider value={context.analyticsService}>
          <PlacesProvider value={context.placesService}>
            <NotificationProvider value={notificationContext}>
              <Provider store={store}>
                <Router history={history}>
                  <PersistGate loading={null} persistor={persistor}>
                    <InnerApp />
                  </PersistGate>
                </Router>
                <NotificationStack wrappedRef={notificationStackRef} />
                <CookiesForm />
              </Provider>
            </NotificationProvider>
          </PlacesProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </div>
  );
};

export const InnerAppComponent: React.FC = () => {
  const language = useSelector(getCurrentLanguage);
  const loadingContentful = useSelector(getIsLoadingContentful);

  const locale = useSelector(getCurrentLanguage);

  return !loadingContentful ? (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language={language}>
      <div className="App">
        <Switch>
          <Route path={Routes.mailUnsubscribe} component={MailUnsubscribe} />
          <Route path={Routes.login} component={Login} />
          <Route path={Routes.logout} component={Logout} />
          <PrivateRoute path={Routes.home} component={HomeContainer} />
        </Switch>
        <Footer locale={locale} />
        <HomescreenModal />
        <FaultySensorModal />
      </div>
    </GoogleReCaptchaProvider>
  ) : (
    <SplashLoading infinite />
  );
};
export const InnerApp = InnerAppComponent;
export default App;
