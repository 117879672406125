import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { PrimaryButton } from "@ddm-design-system/button";
import { Card, CardBody } from "@ddm-design-system/card";
import { StatusChip } from "@ddm-design-system/chip";
import { SectionTitle, Body } from "@ddm-design-system/typography";
import { EPerformanceLevel, IProductInfo } from "../../store/product/types";
import { BeverageTranslation } from "../../store/beers/types";
import useContent from "../../hooks/useContent";
import { BeerType } from "../../services/aggregator";
import { getBeverageLogoOptions, getPerformanceBadgeIcon } from "../../helpers";
import { translatedBeerType } from "../../constants";
import { getIsLoading } from "../../store/product/selectors";
import ProductBeverageDataItem from "./ProductBeverageDataItem";
import { AnalyticsContext } from "../../services/analytics";
import "./product-card.scss";

interface IProductBeverageCard {
  beverage: IProductInfo & { beverage: BeverageTranslation };
  onClick: (beverageInfo: {
    beverage: IProductInfo & { beverage: BeverageTranslation };
    avatarOptions: any;
  }) => void;
  stockAccess?: boolean;
}

const ProductBeverageCard: React.FC<IProductBeverageCard> = ({
  beverage,
  onClick,
  stockAccess
}) => {
  const analytics = useContext(AnalyticsContext);
  const loading = useSelector(getIsLoading);
  const { managerAppProduct: content, managerAppCommon: common } = useContent();
  const [hasError, setHasError] = useState(false);

  const {
    tapPositions,
    avgKegsPerWeek,
    kegsInStock,
    performance,
    validVolumePercentage,
    beverage: { name, carlsShopUrl, craftAndSpeciality }
  } = beverage;

  const avatarOptions = useMemo(() => getBeverageLogoOptions(beverage?.beverage), [beverage]);

  const onError = useCallback(() => setHasError(true), []);
  const handleClick = useCallback(() => onClick({ beverage, avatarOptions }), [
    avatarOptions,
    beverage,
    onClick
  ]);

  const handleCarlshopClick = useCallback(() => {
    analytics.logEvent("CLICK_ORDER_CARLSHOP", name);

    if (window) {
      window.open(carlsShopUrl, "_blank");
    }
  }, [analytics, name, carlsShopUrl]);

  return (
    <div onClick={handleClick}>
      <Card
        className={classnames("product-card", {
          loading
        })}
      >
        <CardBody className="product-card-body">
          <div className="product-card-beverage-description">
            {!loading && avatarOptions.src && !hasError ? (
              <img
                src={avatarOptions.src}
                className="product-card-beverage-logo"
                alt={name}
                onError={onError}
              />
            ) : (
              <div
                className="product-card-beverage-logo product-card-beverage-fallback-logo"
                style={{ backgroundColor: avatarOptions.backgroundColor }}
              >
                <SectionTitle>{avatarOptions.fallbackText}</SectionTitle>
              </div>
            )}
            <SectionTitle className="product-card-beverage-title">{name}</SectionTitle>
            <Body className="product-card-beverage-type">
              {common[`common_beer_type_${translatedBeerType[craftAndSpeciality as BeerType]}`]}
            </Body>
          </div>

          <div className="product-beverage-data">
            <ProductBeverageDataItem label={common.common_tap}>
              {tapPositions.length > 0 ? tapPositions.join(", ") : "--"}
            </ProductBeverageDataItem>

            <ProductBeverageDataItem label={content.manager_app_product_kegs_per_week}>
              {avgKegsPerWeek}
            </ProductBeverageDataItem>

            {stockAccess && (
              <ProductBeverageDataItem label={content.manager_app_product_kegs_in_stock}>
                {kegsInStock !== null ? kegsInStock : "--"}
              </ProductBeverageDataItem>
            )}

            <StatusChip type={getPerformanceBadgeIcon(performance, validVolumePercentage)} selected>
              {!validVolumePercentage
                ? content.manager_app_insights_not_enough_data
                : performance === EPerformanceLevel.overperforming
                ? content.manager_app_insights_outstanding_performance
                : performance === EPerformanceLevel.good
                ? content.manager_app_insights_good_performance
                : content.manager_app_insights_under_performance}
            </StatusChip>

            {carlsShopUrl && (
              <div className="product-card-order-button">
                <PrimaryButton
                  onClick={e => {
                    handleCarlshopClick();
                    e.stopPropagation();
                  }}
                >
                  {content.manager_app_product_order_at_carl_shop}
                </PrimaryButton>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductBeverageCard;
