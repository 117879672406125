import React, { useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { IWithAggRequestProps, withAggRequest } from "../common/hoc/withAggRequest";
import PerformanceCard from "../common/performance/PerformanceCard";
import { PERFORMANCE_TYPE } from "../../store/filter/types";
import { getAllOutlets } from "../../store/outlet/selectors";
import useIsMobile from "../../hooks/useIsMobile";
import StyleConstants from "../../constants";
import {
  setCompareOutlet,
  setPerformanceType as setPerformanceTypeAction
} from "../../store/filter/actions";
import "./sales_header.scss";
import { getOutletCompare, getPerformanceType } from "../../store/filter/selectors";

const SalesHeader: React.FC<IWithAggRequestProps> = ({ data = {}, loading = false, error }) => {
  const { current, similar, target, similarOutlets, nearbyOutlets, outlet } = data;
  const performaceType = useSelector(getPerformanceType);
  const isTablet = useIsMobile(StyleConstants.mobileBreakpoint1);
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);
  const dispatch = useDispatch();
  const outlets = useSelector(getAllOutlets);
  const outletCompare = useSelector(getOutletCompare);
  const isSoloOutlet = outlets.length === 1;
  const useMobileVersion = isSoloOutlet && !isTablet && !isMobile;
  const setPerformanceType = useCallback(
    (type: PERFORMANCE_TYPE) => {
      if (type === PERFORMANCE_TYPE.OUTLET && !outletCompare) {
        dispatch(setCompareOutlet(outlets[0]));
      }
      dispatch(setPerformanceTypeAction(type));
    },
    [dispatch, outletCompare, outlets]
  );

  const defaultProps = {
    loading,
    error,
    horizontal: true,
    useMobileVersion: true
  };

  return (
    <div
      className={classNames(
        "sales-header",
        (isTablet || isSoloOutlet) && "tablet",
        isMobile && "mobile",
        useMobileVersion && "solo-outlet"
      )}
    >
      <PerformanceCard
        {...defaultProps}
        type={PERFORMANCE_TYPE.CURRENT}
        data={current}
        horizontal
        active
        selected
      />
      <PerformanceCard
        {...defaultProps}
        type={PERFORMANCE_TYPE.AVG}
        data={similar}
        selected={performaceType === PERFORMANCE_TYPE.AVG}
        onSelected={setPerformanceType}
      />
      <PerformanceCard
        {...defaultProps}
        type={PERFORMANCE_TYPE.TARGET}
        data={target}
        selected={performaceType === PERFORMANCE_TYPE.TARGET}
        onSelected={setPerformanceType}
      />
      {!isSoloOutlet && (
        <PerformanceCard
          {...defaultProps}
          type={PERFORMANCE_TYPE.OUTLET}
          data={outlet}
          selected={performaceType === PERFORMANCE_TYPE.OUTLET}
          onSelected={setPerformanceType}
        />
      )}
      <PerformanceCard
        {...defaultProps}
        type={PERFORMANCE_TYPE.SIMILAR}
        data={similarOutlets}
        selected={performaceType === PERFORMANCE_TYPE.SIMILAR}
        onSelected={setPerformanceType}
      />
      <PerformanceCard
        {...defaultProps}
        type={PERFORMANCE_TYPE.NEAR}
        data={nearbyOutlets}
        selected={performaceType === PERFORMANCE_TYPE.NEAR}
        onSelected={setPerformanceType}
      />
    </div>
  );
};

export default withAggRequest(SalesHeader);
