import { IJCoreService, JCoreService } from "./jcore";
import { CBarService, ICBarService } from "./cbar";
import { AggregatorService, IAggregatorService } from "./aggregator";
import { IAnalyticsService, GAAnalyticsService } from "./analytics";
import { IPlacesService, PlacesService } from "./places";

export interface IAppContext {
  jCoreService: IJCoreService;
  cBarService: ICBarService;
  aggregatorService: IAggregatorService;
  analyticsService: IAnalyticsService;
  placesService: IPlacesService;
  notificationService: any;
}

export class AppContext implements IAppContext {
  jCoreService: JCoreService;

  cBarService: CBarService;

  aggregatorService: IAggregatorService;

  analyticsService: IAnalyticsService;

  placesService: IPlacesService;

  notificationService: any;

  constructor() {
    const JCoreBaseUrl = process.env.REACT_APP_JCORE_BASE_URL || "https://jcore.dmbuddy.io";
    const CBarBaseUrl =
      process.env.REACT_APP_CBAR_BASE_URL || "https://admin.connectedbar.carlsberg.it";

    this.jCoreService = new JCoreService(JCoreBaseUrl);

    this.cBarService = new CBarService(CBarBaseUrl);
    this.aggregatorService = new AggregatorService(this.cBarService, this.jCoreService);

    this.analyticsService = new GAAnalyticsService();
    this.placesService = new PlacesService();
  }

  public setNotificationService(notificationService: any) {
    this.notificationService = notificationService;
  }
}

export const getAppVersion: () => string = () => process.env.REACT_APP_VERSION || "unknown version";
