import { call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { REHYDRATE } from "redux-persist/lib/constants";
import { IAppContext } from "../services";
import {
  getContentful as getContentfulAction,
  getContentfulError,
  getContentfulSuccess,
  setSyncToken
} from "../store/content/actions";
import { IAppState } from "../store";
import {
  IContentState,
  ISetCurrentLanguageAction,
  SET_CURRENT_LANGUAGE
} from "../store/content/types";
import { IJCoreService } from "../services/jcore";

function* getContentful(jCoreService: IJCoreService) {
  yield take((action: any) => action.type === REHYDRATE && action.key === "language");
  yield put(getContentfulAction());
  try {
    const content: IContentState = yield select((state: IAppState) => state.content);
    const { contentfulToken, dbTimestamp } = content;
    let results = yield jCoreService.getContentfulEntries(contentfulToken, dbTimestamp);
    if (!results) {
      yield put(getContentfulError());
      return;
    }

    results = results.data;

    if (results.entries?.length) {
      yield put(
        getContentfulSuccess({
          entries: results.entries,
          assets: results.assets
        })
      );
    }
    yield put(setSyncToken(results.contentfulToken, results.dbTimestamp));
  } catch (e) {
    yield put(getContentfulError());
  }
}

function* setLanguage(jcoreService: IJCoreService, action: ISetCurrentLanguageAction) {
  try {
    if (action.payload) {
      yield call([jcoreService, jcoreService.setLanguage], action.payload);
    }
  } catch {
    // TODO handle error in set View
  }
}

export function* contentWatcher(context: IAppContext) {
  yield fork(getContentful, context.jCoreService);
  yield takeLatest(SET_CURRENT_LANGUAGE, setLanguage, context.jCoreService);
}

export default contentWatcher;
