import React from "react";
import { Donut as DdmDonut, IDonutProps } from "@ddm-design-system/donut";
import { DescriptionMedium } from "@ddm-design-system/typography";
import "./donut.scss";

// @ts-ignore
export interface IProps extends IDonutProps {
  size?: number;
}

const Donut: React.FC<IProps> = ({ children, className, size = 64, thickness = 8, ...props }) => {
  return (
    <DdmDonut className="donut" size={size} thickness={thickness} {...props}>
      {children && <DescriptionMedium className="donut-label">{children}</DescriptionMedium>}
    </DdmDonut>
  );
};

export default Donut;
