import { Reducer } from "redux";
import produce from "immer";
import {
  SHOW_HOMESCREEN_MODAL,
  HIDE_HOMESCREEN_MODAL,
  SHOW_FAULTY_SENSOR_MODAL,
  HIDE_FAULTY_SENSOR_MODAL,
  IHomescreenModalState,
  modalsActionTypes
} from "./types";
import { IAppState } from "..";
import { getIsMobile, getIsSafari, getIsStandalone } from "../../helpers";
import StyleConstants from "../../constants";

export const initialState: IHomescreenModalState = {
  homescreenModal: { isVisible: false },
  faultySensorModal: { isVisible: false, pressureChambers: undefined }
};

// Only after 10 seconds may the modal be shown
let isTimeoutElapsed = false;
setTimeout(() => {
  isTimeoutElapsed = true;
}, 10000);

const reducer: Reducer<IHomescreenModalState, modalsActionTypes> = (
  state = initialState,
  action: modalsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case SHOW_HOMESCREEN_MODAL:
        const hasSeenOnce = localStorage.getItem("hasSeenHomescreenModal") || "false";
        const isMobile = getIsMobile(StyleConstants.mobileBreakpoint2);
        const isSafari = getIsSafari();
        const isStandalone = getIsStandalone();

        draft.homescreenModal.isVisible =
          isMobile && isSafari && !JSON.parse(hasSeenOnce) && !isStandalone && isTimeoutElapsed;
        break;
      case HIDE_HOMESCREEN_MODAL:
        draft.homescreenModal.isVisible = false;
        break;
      case SHOW_FAULTY_SENSOR_MODAL:
        const sortedPressureChambers = [...new Set(action.payload)].sort();

        draft.faultySensorModal.isVisible = true;
        draft.faultySensorModal.pressureChambers = sortedPressureChambers;
        break;
      case HIDE_FAULTY_SENSOR_MODAL:
        draft.faultySensorModal.isVisible = false;
        draft.faultySensorModal.pressureChambers = undefined;
        break;
      default:
        break;
    }
  });

export default reducer;

export const getIsHomescreenModalVisible = (state: IAppState) =>
  state.modals.homescreenModal.isVisible;

export const getFaultySensorModalData = (state: IAppState) => state.modals.faultySensorModal;
