import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { TertiaryButton } from "@ddm-design-system/button";
import { Divider } from "@ddm-design-system/divider";
import { Icon } from "@ddm-design-system/icon";
import { Menu, MenuItem } from "@ddm-design-system/menu";
import { ModalTooltip } from "@ddm-design-system/modal";
import { MessageTooltip } from "@ddm-design-system/tooltip";
import { Body, ButtonText } from "@ddm-design-system/typography";
import {
  getRank,
  IUser,
  IUserPermissions,
  IUserRankNames,
  RolePermissions
} from "../../store/users/types";
import { setPermissions } from "../../store/users/actions";
import { getUser } from "../../store/profile/reducer";
import useContent from "../../hooks/useContent";

interface IProps {
  user: IUser;
  outletId: string;
  removeMe: () => void;
}

interface IAction {
  id: string;
  text: string;
  icon: string;
  execute?: () => void;
  inactive?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
}

type Action = IAction;

const UserListItem: React.FC<IProps> = ({ user, outletId, removeMe }) => {
  const { managerAppSettings: contentSettings } = useContent();
  const dispatch = useDispatch();

  const me: IUser | null = useSelector(getUser);
  const himself = me != null && me.username === user.username;

  const outletPermissions = useMemo<IUserPermissions>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => user.permissions.find(o => o.locationId === outletId)!,
    [user, outletId]
  );

  const possibleActions: { [key: string]: IAction } = {
    CanView: {
      id: "view",
      text: contentSettings.manager_app_settings_can_view,
      icon: "eye",
      tooltipTitle: contentSettings.manager_app_settings_can_view_title,
      tooltipDescription: contentSettings.manager_app_settings_can_view_description,
      execute: () =>
        dispatch(
          setPermissions({
            outletId: outletPermissions.locationId,
            rank: IUserRankNames.Reader,
            username: user.username,
            actions: RolePermissions.Reader,
            type: "change"
          })
        )
    },
    CanViewAndManage: {
      id: "edit",
      text: contentSettings.manager_app_settings_can_manage,
      icon: "edit",
      tooltipTitle: contentSettings.manager_app_settings_can_manage_title,
      tooltipDescription: contentSettings.manager_app_settings_can_manage_description,
      execute: () =>
        dispatch(
          setPermissions({
            outletId: outletPermissions.locationId,
            rank: IUserRankNames.Staff,
            username: user.username,
            actions: RolePermissions.Staff,
            type: "change"
          })
        )
    },
    RemoveFromOutlet: {
      id: "remove",
      text: contentSettings.manager_app_settings_remove_outlet,
      icon: "delete",
      execute: () =>
        dispatch(
          setPermissions({
            outletId: outletPermissions.locationId,
            rank: IUserRankNames.Reader,
            username: user.username,
            actions: [],
            type: "remove"
          })
        )
    },
    RemoveMeFromOutlet: {
      id: "remove",
      text: contentSettings.manager_app_settings_remove_outlet_me,
      icon: "delete",
      execute: () => removeMe && removeMe()
    },
    Admin: {
      id: "Admin",
      text: contentSettings.manager_app_settings_admin,
      icon: "user",
      tooltipTitle: contentSettings.manager_app_settings_admin_title,
      tooltipDescription: contentSettings.manager_app_settings_admin_description
    }
  };

  function getPossibleActionsAsReader(role: string) {
    switch (role) {
      case "READER":
        return himself
          ? [possibleActions.CanView, possibleActions.RemoveMeFromOutlet]
          : [possibleActions.CanView];
      case "STAFF":
        return [possibleActions.CanViewAndManage];
      case "OWNER":
        return [possibleActions.Admin];
      default:
        return [];
    }
  }

  function getPossibleActionsAsStaff(role: string) {
    switch (role) {
      case "READER":
        return [
          possibleActions.CanView,
          himself ? possibleActions.RemoveMeFromOutlet : possibleActions.RemoveFromOutlet
        ];
      case "STAFF":
        return himself
          ? [possibleActions.CanViewAndManage, possibleActions.RemoveMeFromOutlet]
          : [possibleActions.CanViewAndManage];
      case "OWNER":
        return [possibleActions.Admin];
      default:
        return [];
    }
  }

  function getPossibleActionsAsOwner(role: string) {
    switch (role) {
      case "READER":
        return [
          possibleActions.CanView,
          possibleActions.CanViewAndManage,
          himself ? possibleActions.RemoveMeFromOutlet : possibleActions.RemoveFromOutlet
        ];
      case "STAFF":
        return [
          possibleActions.CanView,
          possibleActions.CanViewAndManage,
          himself ? possibleActions.RemoveMeFromOutlet : possibleActions.RemoveFromOutlet
        ];
      case "OWNER":
        return [possibleActions.Admin];
      default:
        return [];
    }
  }

  function getActions(): IAction[] {
    const myRank: string = me ? getRank(me, outletId) : "READER";
    const userRank = getRank(user, outletId);
    switch (myRank) {
      case "READER":
        return getPossibleActionsAsReader(userRank).map(d => ({
          ...d,
          inactive: d.id !== "remove"
        }));
      case "STAFF":
        return getPossibleActionsAsStaff(userRank).map(d => ({
          ...d,
          inactive: d.id !== "remove"
        }));
      case "OWNER":
        return getPossibleActionsAsOwner(userRank);
      default:
        return [];
    }
  }

  const actions = getActions();

  function getInitialAction() {
    const rank = getRank(user, outletId);
    switch (rank) {
      case "READER":
        return possibleActions.CanView;
      case "STAFF":
        return possibleActions.CanViewAndManage;
      case "OWNER":
        return possibleActions.Admin;
      default:
        return possibleActions.CanView;
    }
  }
  /* TODO change this */
  const [selectedAction, selectAction] = useState<Action>(getInitialAction);
  const isAccountActive = user.name && user.name.length > 0;
  const renderIndicator = () => (
    <MessageTooltip
      icon={isAccountActive ? "ok" : "error"}
      renderHeader={() => (
        <div className={classnames("indicator", { inactive: !isAccountActive })} />
      )}
    >
      {isAccountActive
        ? contentSettings.manager_app_settings_account_active
        : contentSettings.manager_app_settings_account_inactive}
    </MessageTooltip>
  );
  const userInfo =
    user.name && user.name.length > 0 ? (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          {renderIndicator()}
          <Body className="name">{user.name}</Body>
        </div>
        <Body className="username">{user.username}</Body>
      </>
    ) : (
      <div style={{ display: "flex", alignItems: "center" }}>
        {renderIndicator()}
        <Body className="username">{user.username}</Body>
      </div>
    );

  return selectedAction && actions.length > 0 && actions.some(s => s.id === selectedAction.id) ? (
    <div className="user-list-item">
      <div className="info">{userInfo}</div>

      <div className="user-list-item-action">
        <ModalTooltip title={selectedAction.tooltipTitle || ""} titleIcon="devices">
          {selectedAction.tooltipDescription}
        </ModalTooltip>
        <Menu
          direction="bottom-right"
          renderHeader={() => (
            <ButtonText>
              <div style={{ display: "flex", alignItems: "center" }}>
                {selectedAction.text}&nbsp;
                <Icon name="chevron-down" />
              </div>
            </ButtonText>
          )}
        >
          {actions.map((item, i) => (
            <React.Fragment key={item.id}>
              {actions.length > 1 && i === actions.length - 1 && <Divider />}
              <MenuItem>
                <TertiaryButton
                  icon={item.icon}
                  danger={actions.length > 1 && i === actions.length - 1}
                  onClick={() => {
                    if (item.execute && !item.inactive) {
                      item.execute();
                    }
                    if (item.id !== "remove") {
                      selectAction(item);
                    }
                  }}
                >
                  {selectedAction.id === item.id ? <strong>{item.text}</strong> : item.text}
                </TertiaryButton>
              </MenuItem>
            </React.Fragment>
          ))}
        </Menu>
      </div>
    </div>
  ) : (
    <span />
  );
};

export default UserListItem;
