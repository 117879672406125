import { IAppState } from "../index";

export const getAllOutlets = (state: IAppState) => state.outlet.outlets;

export const getHasOutlets = (state: IAppState) => getAllOutlets(state).length > 0;

export const getCurrentOutlet = (state: IAppState) => state.outlet.currentOutlet;

export const getOutletById = (state: IAppState, outletId: string) =>
  getAllOutlets(state).find(outlet => outlet.id === outletId);

export const getOutletLocationDataById = (state: IAppState, outletId: string) =>
  state.outlet.outletsLocationData[outletId];

export const getCurrentOutletPickerTab = (state: IAppState) => state.outlet.currentOutletPickerTab;

export const getOutletLocationData = (state: IAppState, outletId: string) =>
  state.outlet.outletsLocationData[outletId];
