import { Reducer } from "redux";
import produce from "immer";
import {
  IRequestBeersState,
  REQUEST_BEERS_SUCCESS,
  RequestBeersActionTypes,
  REQUEST_BEERS_ERROR,
  BeverageTranslation,
  IBeverage
} from "./types";
import { IAppState } from "..";

export const initialState: IRequestBeersState = {
  beers: []
};

const reducer: Reducer<IRequestBeersState, RequestBeersActionTypes> = (
  state = initialState,
  action: RequestBeersActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_BEERS_SUCCESS:
        const { beverages, beveragesTranslations } = action.payload;
        const translatedBeverages: BeverageTranslation[] = [];
        beverages.forEach((beer: IBeverage) => {
          const translations: any = beveragesTranslations[beer.id]
            ? beveragesTranslations[beer.id]
            : [];
          const b = new BeverageTranslation(beer, translations);
          translatedBeverages.push(b);
        });
        draft.beers = translatedBeverages;
        draft.error = undefined;
        break;
      case REQUEST_BEERS_ERROR:
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const getBeverages = (state: IAppState) => state.beers.beers;

export const getFilteredBeverages = (state: IAppState) =>
  getBeverages(state).filter(beer => beer.brand !== "NONE" && beer.brand !== "empty");

export const getBeverage = (state: IAppState, beverageId: string) =>
  state.beers.beers.find(beer => beer.id === beverageId);

export const getBeverageName = (state: IAppState, beverageId: string) =>
  getBeverage(state, beverageId)?.name;

export const getBeveragesByIds = (state: IAppState, beverageIds: string[]) =>
  beverageIds
    .map(beverageId => getBeverage(state, beverageId))
    .filter(beverage => beverage != null) as BeverageTranslation[];
