import { DEFAULT_LANG, DEFAULT_LOGO_BUCKET, DEFAULT_LOGO_URL } from "../../constants";
import { store } from "../../App";
import { IApiBeverage } from "../installation/types";
import { BeerType } from "../../services/aggregator";

export const REQUEST_BEERS = "beers/REQUEST_BEERS";
export const REQUEST_BEERS_SUCCESS = "beers/REQUEST_BEERS_SUCCESS";
export const REQUEST_BEERS_ERROR = "beers/REQUEST_BEERS_ERROR";
export const EDIT_BEER = "beers/EDIT_BEER";

export interface ICountry {
  code: string;
  name: string;
}
export interface IBeverage {
  id: string;
  brand: string;
  subbrand: string;
  active: boolean;
  countries: ICountry[];
  name?: string;
  type?: string;
  description?: string;
  abv?: number;
  logoUrl?: string;
  countryOfOrigin: string;
  mainColor?: string;
  initials?: string;
  craftAndSpeciality?: string;
}
export interface IBeverageTranslation {
  id: string;
  countries: ICountry[];
  active: boolean;
  abv?: number;
  carlsShopUrl?: string;
  mainColor?: string;
  initials?: string;
  craftAndSpeciality?: string;
  locales: { [key: string]: ILocale };
}

export interface ILocale {
  brand: string;
  subbrand: string;
  type?: string;
  description?: string;
  countryOfOrigin?: string;
  logoUrl?: string;
}

export interface IBeer {
  name: string;
  id: string;
}

export interface IRequestBeersState {
  beers: BeverageTranslation[];
  error?: Error;
}

export interface IRequestBeers {
  type: typeof REQUEST_BEERS;
  payload: {
    locationIds?: string[];
  };
}

export interface IRequestBeerSuccessResponseItem {
  beverages: IBeverage[];
  beveragesTranslations: any;
}

export interface IRequestBeersSuccess {
  type: typeof REQUEST_BEERS_SUCCESS;
  payload: IRequestBeerSuccessResponseItem;
}

export interface IRequestBeersError {
  type: typeof REQUEST_BEERS_ERROR;
  payload: Error;
}

export interface IEditBeerPayload {
  beverageId: string;
  outletId: string;
  thingId: string;
}

export interface IEditBeer {
  type: typeof EDIT_BEER;
  payload: IEditBeerPayload;
}

export interface IBeveragesPayload {
  beverageId: string;
  brand: string;
}

const getPropertyOfBeverage = (beverage: BeverageTranslation, key: keyof ILocale, locale = "") => {
  const lang = store.getState().content.currentLanguage;
  // use default lang as fallback
  return locale
    ? beverage.locales[locale] && beverage.locales[locale][key]
    : (beverage.locales[lang] && beverage.locales[lang][key]) ||
        (beverage.locales[DEFAULT_LANG] && beverage.locales[DEFAULT_LANG][key]);
};

export class BeverageTranslation implements IBeverageTranslation {
  id = "";

  countries: ICountry[] = [];

  active = false;

  abv?: number;

  carlsShopUrl?: string;

  mainColor?: string;

  initials?: string;

  craftAndSpeciality?: BeerType;

  locales: { [key: string]: ILocale } = {
    [DEFAULT_LANG]: {
      brand: "",
      subbrand: "",
      description: "",
      countryOfOrigin: "",
      type: "",
      logoUrl: ""
    }
  };

  constructor(beverage?: any, translations?: any) {
    if (beverage && beverage.locales) {
      this.setupFromBeverage(beverage);
    } else if (beverage) {
      this.setupFromApi(beverage, translations);
    }
  }

  public setupFromApi(beverage: IApiBeverage, translations: any = []) {
    this.id = beverage.id;
    this.countries = beverage.countries;
    this.active = beverage.active;
    this.abv = beverage.abv;
    this.carlsShopUrl = beverage.carlsShopUrl;
    this.mainColor = beverage.mainColor;
    this.initials = beverage.initials;
    this.craftAndSpeciality = beverage.craftAndSpeciality;
    this.locales = {};
    this.locales[DEFAULT_LANG] = {
      brand: beverage.brand,
      subbrand: beverage.subbrand,
      countryOfOrigin: beverage.countryOfOrigin,
      description: beverage.description,
      type: beverage.type,
      logoUrl: beverage.logoUrl
    };
    translations.forEach((t: any) => {
      this.locales[t.locale] = {
        brand: t.brand,
        subbrand: t.subbrand,
        countryOfOrigin: t.countryOfOrigin,
        description: t.description,
        type: t.type,
        logoUrl: t.logoUrl
      };
    });
  }

  public setupFromBeverage(beverage: any) {
    this.id = beverage.id;
    this.countries = beverage.countries;
    this.active = beverage.active;
    this.abv = beverage.abv;
    this.carlsShopUrl = beverage.carlsShopUrl;
    this.mainColor = beverage.mainColor;
    this.initials = beverage.initials;
    this.craftAndSpeciality = beverage.craftAndSpeciality;
    this.locales = beverage.locales || {};
  }

  get brand() {
    const brand = getPropertyOfBeverage(this, "brand");
    return brand === "empty" ? "No Beverage" : brand;
  }

  get subbrand() {
    const subbrand = getPropertyOfBeverage(this, "subbrand");
    return subbrand === "empty" ? " " : subbrand;
  }

  get description() {
    return getPropertyOfBeverage(this, "description");
  }

  get type() {
    return getPropertyOfBeverage(this, "type");
  }

  get countryOfOrigin() {
    return getPropertyOfBeverage(this, "countryOfOrigin");
  }

  get name() {
    return this.brand === "empty" ? "No Beverage" : `${this.brand || ""} ${this.subbrand}`;
  }

  get logoUrl() {
    const key = getPropertyOfBeverage(this, "logoUrl");

    const imageRequest = JSON.stringify({
      bucket: DEFAULT_LOGO_BUCKET,
      key,
      edits: {
        resize: {
          width: 160,
          height: 160
        }
      }
    });
    // use default lang as fallback
    return key ? `${DEFAULT_LOGO_URL}/${btoa(imageRequest)}` : null;
  }
}

export type RequestBeersActionTypes = IRequestBeers | IRequestBeersSuccess | IRequestBeersError;
