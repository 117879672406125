import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import classnames from "classnames";
import Header from "../header/header";
import "./baselayout.scss";
import useContent from "../../../hooks/useContent";
import Routes from "../../../routes";

interface IProps extends RouteComponentProps {}

const REG_EX_ROUTES = new RegExp(/^(\/[a-zA-Z]+)/);

const BaseLayout: React.FC<IProps> = ({ children, location: { pathname } }) => {
  const { managerAppSettings: settingsContent } = useContent();

  let showOutletPicker = true;
  let title = "";
  let customClassName = "";
  switch (REG_EX_ROUTES.exec(pathname)?.[0] || "") {
    case Routes.settings:
      showOutletPicker = false;
      title = settingsContent.manager_app_settings_title;
      customClassName = "base-layout-mobile-settings";
      break;
    case Routes.empty:
      showOutletPicker = false;
      break;
    case Routes.product:
      customClassName = "base-layout-mobile-product";
      break;
    case Routes.installation:
      customClassName = "base-layout-mobile-installation";
      break;
    case Routes.sales:
      customClassName = "base-layout-mobile-sales";
      break;
    case Routes.overviewAll:
      customClassName = "base-layout-mobile-overview";
      break;
    default:
  }

  return (
    <div className={classnames("base-layout-container", customClassName)}>
      <Header showOutletPicker={showOutletPicker} title={title} />
      {children}
    </div>
  );
};

export default withRouter(BaseLayout);
