import Hotjar from "react-hotjar";

const ENV_HJID = process.env.REACT_APP_HOTJAR_ID || "1482827";
const ENV_HJSV = process.env.REACT_APP_HOTJAR_VERSION || "6";

declare let hj: any;

export default {
  setup: (hjid: string = ENV_HJID, hjsv: string = ENV_HJSV) => Hotjar.hotjar.initialize(hjid, hjsv),
  trigger: (poll: string) => hj("trigger", poll)
};
