import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as d3 from "d3";
import { PrimaryButton } from "@ddm-design-system/button";
import { Checkbox } from "@ddm-design-system/checkbox";
import { Divider } from "@ddm-design-system/divider";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "@ddm-design-system/modal";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Body, BodyHighlight } from "@ddm-design-system/typography";
import Time from "../../../lib/Time/Time";
import { isInteger } from "../../../helpers";
import useContent from "../../../hooks/useContent";
import usePrevious from "../../../hooks/usePrevious";
import { ITarget } from "../../../store/targets/types";
import { addTarget } from "../../../store/targets/actions";
import "./target.scss";

export interface IProps {
  isOpen: boolean;
  target?: ITarget;
  onClose: () => void;
}

const AddTargetModal: React.FC<IProps> = ({ isOpen, target, onClose }) => {
  const dispatch = useDispatch();
  const { managerAppOverview: content } = useContent();
  const [targetVolume, setTargetVolume] = useState<any>(null);
  const [targetSpecialityPct, setTargetSpecialityPct] = useState<any>(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const prevTarget = usePrevious(target);

  const hasVolumeError = targetVolume !== null && !isInteger(targetVolume);
  const hasSpecialityPctError =
    targetSpecialityPct !== null &&
    (!isInteger(targetSpecialityPct, { allowZero: true }) || Number(targetSpecialityPct) > 100);
  const month = d3.timeFormat("%B")(target?.date.toJSDate() ?? new Time().toJSDate());
  const title = (
    (target ? content.target_modal_title_edit : content.target_modal_title_add) || ""
  ).replace("%month%", month);

  useEffect(() => {
    if (!prevTarget && target) {
      setTargetVolume(target.volume?.goal);
      setTargetSpecialityPct(target.speciality?.goal);
      setIsRecurring(target.isRecurring ?? false);
    }
  }, [prevTarget, target]);

  const handleClose = useCallback(() => {
    setTargetVolume(null);
    setTargetSpecialityPct(null);
    setIsRecurring(false);
    onClose();
  }, [onClose]);

  const handleAdd = useCallback(() => {
    if (targetVolume !== null && targetSpecialityPct !== null) {
      dispatch(
        addTarget({
          month: d3.timeFormat("%Y%m")(target?.date.toJSDate() ?? new Time().toJSDate()),
          totalVolume: Number(targetVolume),
          craftAndSpecialityPercentage: Number(targetSpecialityPct),
          recurring: isRecurring
        })
      );
    }

    handleClose();
  }, [dispatch, handleClose, isRecurring, target, targetSpecialityPct, targetVolume]);

  return (
    <Modal className="add-target-modal" isOpen={isOpen} onRequestClose={handleClose}>
      <ModalHeader title={title} />
      <ModalBody className="add-target-modal-body">
        <BodyHighlight>{content.target_modal_volume_question}</BodyHighlight>
        <ErrorTextInput
          className="add-target-modal-input"
          error={hasVolumeError ? content.target_modal_volume_error : undefined}
          label={content.target_modal_volume_label}
          suffixIcon={<Body>L</Body>}
          value={targetVolume ?? ""}
          onChange={e => setTargetVolume(e.target.value.length > 0 ? e.target.value : null)}
        />
        <Divider className="add-target-modal-divider" />
        <BodyHighlight className="add-target-modal-speciality-question">
          {content.target_modal_speciality_question}
        </BodyHighlight>
        <ErrorTextInput
          className="add-target-modal-input"
          error={hasSpecialityPctError ? content.target_modal_speciality_error : undefined}
          label={content.target_modal_speciality_label}
          suffixIcon={<Body>%</Body>}
          value={targetSpecialityPct ?? ""}
          onChange={e => setTargetSpecialityPct(e.target.value.length > 0 ? e.target.value : null)}
        />
      </ModalBody>
      <ModalFooter className="add-target-modal-footer">
        <Checkbox checked={isRecurring} onChange={e => setIsRecurring(e.target.checked)}>
          {content.target_modal_checkbox_label}
        </Checkbox>
        <PrimaryButton
          className="add-target-modal-button"
          onClick={handleAdd}
          disabled={
            targetVolume === null ||
            targetSpecialityPct === null ||
            hasVolumeError ||
            hasSpecialityPctError
          }
        >
          {target ? content.target_modal_edit_button : content.target_modal_add_button}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default AddTargetModal;
