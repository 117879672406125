import React, { useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import useScroll from "../../../hooks/useScroll";
import { IAppState } from "../../../store";
import { getOutletInsights } from "../../../store/product/selectors";
import {
  /* AddTapInsightCard, */ UnderperformingInsightCard,
  GreatPerformanceInsightCard
} from ".";
import "./insight-cards.scss";

interface IProps {
  expanded: boolean;
  outletId: string;
}

const InsightCards: React.FC<IProps> = ({ expanded, outletId }) => {
  const scrollElRef = useRef<HTMLDivElement>(null);
  const {
    // goodLowestPerformingTap,
    goodPerformingBeverageIds,
    underPerformingBeverageIds
  } = useSelector((state: IAppState) => getOutletInsights(state, outletId));
  const scrollObject = useScroll(scrollElRef.current ?? undefined, { isHorizontal: true });
  const showScrollGradient = useMemo(() => !scrollObject.hasScrolledToEnd, [
    scrollObject.hasScrolledToEnd
  ]);

  return (
    <div className={classnames("insight-cards-wrapper", expanded && "expanded")}>
      <div className="insight-cards" ref={scrollElRef}>
        {/* Sales reps not happy with AddTapInsightCard, so leave it out for now */}
        {/* <AddTapInsightCard expanded={expanded} goodLowestPerformingTap={goodLowestPerformingTap} /> */}
        <UnderperformingInsightCard
          expanded={false}
          underPerformingBeverageIds={underPerformingBeverageIds}
        />
        <GreatPerformanceInsightCard
          expanded={false}
          greatPerformingBeverageIds={goodPerformingBeverageIds}
        />
        {!expanded && showScrollGradient && <div className="insight-cards-overflow" />}
      </div>
    </div>
  );
};

export default InsightCards;
