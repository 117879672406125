import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { Icon } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { Body } from "@ddm-design-system/typography";
import { ExpandablePanels } from "@ddm-design-system/expandable-panel";
import { useInfiniteScrollList } from "@ddm-design-system/hooks";
import { IAppState } from "../../store";
import { requestInsights } from "../../store/product/actions";
import { getChosenOutletId } from "../../store/filter/selectors";
import {
  getHasError,
  getIsLoading,
  getAllMainstreamBrands,
  getMainstreamBrands,
  getAllSpecialityBrands,
  getSpecialityBrands,
  getTapCount,
  getAllTapsCount,
  getAvgKegsPerWeek,
  getAllAvgKegsPerWeek,
  getProduct,
  getSortedProducts,
  getInsights
} from "../../store/product/selectors";
import useContent from "../../hooks/useContent";
import ProductSummaryGroup from "./ProductSummaryGroup";
import ProductOutlet from "./ProductOutlet";
import StockManagementModal from "./StockManagementModal";
import "./product.scss";

const Product: React.FC = () => {
  const dispatch = useDispatch();
  const { managerAppProduct: content, managerAppInstallation: contentInstallation } = useContent();
  const [stockModalLocationId, setStockModalLocationId] = useState<string | null>();
  const selectedOutletId = useSelector(getChosenOutletId);
  const loading = useSelector(getIsLoading);
  const hasError = useSelector(getHasError);

  const products = useSelector((state: IAppState) =>
    selectedOutletId ? getProduct(state, selectedOutletId) : getSortedProducts(state)
  );
  const mainstreamBrands = useSelector((state: IAppState) =>
    selectedOutletId ? getMainstreamBrands(state, selectedOutletId) : getAllMainstreamBrands(state)
  );
  const specialityBrands = useSelector((state: IAppState) =>
    selectedOutletId ? getSpecialityBrands(state, selectedOutletId) : getAllSpecialityBrands(state)
  );
  const tapCount = useSelector((state: IAppState) =>
    selectedOutletId ? getTapCount(state, selectedOutletId) : getAllTapsCount(state)
  );
  const kegsPerWeek = useSelector((state: IAppState) =>
    selectedOutletId ? getAvgKegsPerWeek(state, selectedOutletId) : getAllAvgKegsPerWeek(state)
  );
  const insights = useSelector(getInsights);

  const hasData = useMemo(() => !loading && products && Object.keys(products).length > 0, [
    loading,
    products
  ]);

  useEffect(() => {
    if (Object.keys(insights).length === 0) {
      dispatch(requestInsights());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStockModalOpen = useCallback(
    (locationId: string) => setStockModalLocationId(locationId),
    []
  );
  const handleStockModalClose = useCallback(() => setStockModalLocationId(null), []);

  const infiniteScrollOptions = useMemo(
    () => ({
      intersectionOptions: {
        root: document.querySelector("#root"),
        rootMargin: "0px 100px 0px 0px"
      }
    }),
    []
  );

  const beverageCards = useMemo(
    () =>
      !selectedOutletId && products
        ? Object.keys(products).map(outletId => (
            <ProductOutlet
              key={outletId}
              outletId={outletId}
              group
              onStockModalOpen={handleStockModalOpen}
            />
          ))
        : [],
    [products, selectedOutletId, handleStockModalOpen]
  );

  const items = useInfiniteScrollList(beverageCards, infiniteScrollOptions);

  return (
    <div className="product-page">
      {hasError ? (
        <span>Error</span>
      ) : (
        <>
          <div
            className={classnames("product-header", {
              loading
            })}
          >
            <ProductSummaryGroup
              title={content.manager_app_product_mainstream_brands}
              brands={mainstreamBrands}
            />
            <ProductSummaryGroup
              title={content.manager_app_product_speciality_brands}
              brands={specialityBrands}
            />
            <Infoicon
              className={classnames("product-summary-group", { loading })}
              title={content.manager_app_product_number_of_taps}
              avatarOptions={{
                children: <Icon name="tap-double" />
              }}
            >
              {loading ? "" : `${tapCount}`}
            </Infoicon>
            <Infoicon
              className={classnames("product-summary-group", { loading })}
              title={content.manager_app_product_kegs_per_week}
              avatarOptions={{
                children: <Icon name="keg" />
              }}
            >
              {loading ? "" : kegsPerWeek?.toFixed(2)}
            </Infoicon>
          </div>
          <div className="product-page-body">
            {hasData ? (
              <>
                {selectedOutletId && (
                  <ProductOutlet
                    outletId={selectedOutletId}
                    onStockModalOpen={handleStockModalOpen}
                  />
                )}
                {!selectedOutletId && products && <ExpandablePanels>{items}</ExpandablePanels>}
              </>
            ) : loading ? (
              <ProductOutlet outletId="" />
            ) : (
              <Body>{contentInstallation.manager_app_pressure_chambers_empty}</Body>
            )}
          </div>
          <StockManagementModal
            locationId={stockModalLocationId || ""}
            isOpen={!!stockModalLocationId}
            onClose={handleStockModalClose}
            showOutletName={!selectedOutletId}
          />
        </>
      )}
    </div>
  );
};

export default Product;
