import * as d3 from "d3";
import { AxiosResponse } from "axios";
import {
  day8,
  getTimeOfTimeRange,
  isInsideOpeningHours,
  prepareOpeningHours
} from "../lib/Time/TimeFunctions";
import Time from "../lib/Time/Time";
import { IFilterState, PERFORMANCE_TYPE } from "../store/filter/types";
import { ITimeFrame, ITimeRange } from "../lib/Time/types";
import { BeerType, IExploreAggregatorRequest } from "./aggregator";
import { CUSTOM_DAY_TRESHOLD, CUSTOM_MONTH_TRESHOLD, SIMILAR_TIME_PERIOD } from "../constants";
import { ITargetParams } from "../store/targets/types";
import { accumulate } from "../helpers";

// used to remove data from other months on targets
function sliceTarget(data: number[][] | any): number[][] | any {
  if (!data) {
    return data;
  }

  const startTarget = new Time().set({ day: 1, hour: 8, minute: 0, second: 0 }).getTime();
  if (Array.isArray(data)) {
    return data.filter(d => +d?.[0] >= startTarget);
  }
  const newData = { ...data };
  Object.keys(data).forEach((d: any) => {
    newData[d] = data[d].filter((d2: any) => +d2?.[0] >= startTarget);
  });
  return newData;
}

// used to see if there is any target defined
function withoutVolume(data: number[][] | any): boolean {
  if (!data) return true;

  if (Array.isArray(data)) {
    return data.every(d => d?.[1] === null);
  }
  return Object.keys(data).every((d: any) => data[d].every((d2: any) => d2?.[1] === null));
}

function joinVolumeTypes(data: any, aggregate = false): number[][] {
  let volumeArray: number[][] = [];
  Object.keys(data).forEach(type => {
    data[type].forEach((v: number[], i: number) => {
      if (!volumeArray[i]) {
        volumeArray[i] = [v[0], +v[1]];
      } else {
        volumeArray[i][1] += +v[1] || 0;
      }
    });
  });
  if (aggregate) {
    volumeArray = [
      volumeArray.reduce(
        (agg, v) => (!agg[0] ? [v[0], v[1]] : [agg[0], (agg[1] || 0) + (v?.[1] || 0)]),
        []
      )
    ];
  }
  return volumeArray;
}

function joinByDay(array: any, type: string, useOffHour = false) {
  const newArray: any[][] = [];
  array.forEach((d: any[]) => {
    const date =
      type === "MONTH"
        ? +day8(new Time(+d[0])).set({ day: 1 })
        : type === "DAY"
        ? +day8(new Time(+d[0]))
        : new Time(+d[0]);

    const obj = newArray.find(d2 => d2[0] === date);
    if (obj) {
      if (useOffHour) {
        if (!d[2]) {
          obj[1][0] = (obj[1][0] || 0) + +d[1];
        } else {
          obj[1][1] = (obj[1][1] || 0) + +d[1];
        }
      } else {
        obj[1] = (obj[1] || 0) + +d[1];
      }
    } else if (useOffHour) {
      if (!d[2]) {
        newArray.push([date, [+d[1], 0]]);
      } else {
        newArray.push([date, [0, +d[1]]]);
      }
    } else {
      newArray.push([date, +d[1]]);
    }
  });
  return newArray;
}

function joinByTimeFrame(array: any, timeFrame: ITimeFrame, useOffHour = false) {
  let format: any;
  let formatTooltip: any;

  let tickGroup: any;
  const hourOnly =
    timeFrame.type === "DAY" ||
    timeFrame.type === "YESTERDAY" ||
    (timeFrame.type === "CUSTOM" && timeFrame.from.diff(timeFrame.to) <= 1);
  const dayAndHour =
    timeFrame.type === "CUSTOM" && timeFrame.from.diff(timeFrame.to) <= CUSTOM_DAY_TRESHOLD;
  const weekDays =
    timeFrame.type === "WEEK" ||
    (timeFrame.type === "CUSTOM" && timeFrame.from.diff(timeFrame.to) === 7);
  const fourWeeks = timeFrame.type === "4WEEKS";
  const daysInMonth =
    timeFrame.type === "CUSTOM" &&
    timeFrame.from.diff(timeFrame.to) >= 28 &&
    timeFrame.from.diff(timeFrame.to) <= CUSTOM_MONTH_TRESHOLD;
  const months =
    timeFrame.type === "CUSTOM" && timeFrame.from.diff(timeFrame.to) > CUSTOM_MONTH_TRESHOLD;

  if (hourOnly) {
    format = d3.timeFormat("%H");
    formatTooltip = d3.timeFormat("%H:00 - %H:59");
  } else if (dayAndHour) {
    format = d3.timeFormat("%d %b - %H");
    formatTooltip = d3.timeFormat("%H:00 - %H:59");
    tickGroup = d3.timeFormat("%d %b");
  } else if (weekDays) {
    format = d3.timeFormat("%A");
    formatTooltip = d3.timeFormat("%A, %d %b");
  } else if (months) {
    format = d3.timeFormat("%b %Y");
    formatTooltip = d3.timeFormat("%b %Y");
  } else if (fourWeeks) {
    format = d3.timeFormat("%d %b");
    formatTooltip = d3.timeFormat("%A, %d %b");
    tickGroup = d3.timeFormat("%d %b");
  } else if (daysInMonth) {
    format = d3.timeFormat("%d %b");
    formatTooltip = d3.timeFormat("%A, %d %b");
    tickGroup = d3.timeFormat("%B");
  } else {
    format = d3.timeFormat("%d %b");
    formatTooltip = d3.timeFormat("%A, %b %d");
  }

  let newArray: any[][];
  if (!hourOnly && !dayAndHour && !months) {
    newArray = joinByDay(array, "DAY", useOffHour);
  } else if (months) {
    newArray = joinByDay(array, "MONTH", useOffHour);
  } else {
    newArray = joinByDay(array, "HOUR", useOffHour);
  }

  return newArray.map((d: number[]) => {
    const date = hourOnly || dayAndHour ? new Time(+d[0]) : day8(new Time(+d[0]));
    let tickGroupFormat = tickGroup && tickGroup(date.toJSDate());

    if (fourWeeks) {
      const today = day8(new Time());
      const days = date.diff(today);
      const lastDay = today.clone().sub({ day: 7 * Math.floor(days / 7) });
      const firstDay = lastDay.clone().sub({ day: 6 });
      tickGroupFormat = `${tickGroup(firstDay.toJSDate())} - ${tickGroup(lastDay.toJSDate())}`;
    }
    return {
      label: format(date.toJSDate()),
      tooltipLabel: formatTooltip(date.toJSDate()),
      value: d[1],
      tickGroup: tickGroupFormat
    };
  });
}

function joinByWeekDay(array: number[][]) {
  const format = d3.timeFormat("%a");
  const weekMap: any = {};
  Array(7)
    .fill("")
    .forEach((_, i) => {
      const date = new Time().add({ day: 1 * i });
      weekMap[format(date.toJSDate())] = {
        values: [],
        weekDay: date.getWeekDay()
      };
    });
  array.forEach((d: number[]) => {
    const date = day8(new Time(+d[0]));
    weekMap[format(date.toJSDate())].values.push(+d[1] || 0);
  });

  return Object.keys(weekMap)
    .sort((a, b) => weekMap[a].weekDay - weekMap[b].weekDay)
    .map(key => ({
      label: key,
      value: d3.mean(weekMap[key].values) || 0
    }));
}

function joinByDayHour(array: number[][]) {
  const format = d3.timeFormat("%H");
  const DAY_HOUR_START = 8;
  const hourMap: any = {};
  Array(24)
    .fill("")
    .forEach((_, i) => {
      const date = new Time().add({ hour: 1 * i });
      hourMap[format(date.toJSDate())] = {
        values: [],
        hour: date.getHour()
      };
    });
  array.forEach((d: number[]) => {
    const date = new Time(+d[0]);
    hourMap[format(date.toJSDate())].values.push(+d[1] || 0);
  });

  const arraySorted = Object.keys(hourMap).sort((a, b) => hourMap[a].hour - hourMap[b].hour);

  return arraySorted
    .slice(DAY_HOUR_START)
    .concat(arraySorted.slice(0, DAY_HOUR_START))
    .map(key => ({
      label: key,
      value: d3.mean(hourMap[key].values) || 0
    }));
}

function calcVolumeAndSpeciality(data: { [key: string]: number[][] }) {
  const volume = +joinVolumeTypes(data, true)?.[0]?.[1] || 0;

  const calcSpeciality = !data[BeerType.SPECIALITY]?.[0]?.[1]
    ? 0
    : data[BeerType.SPECIALITY]?.[0]?.[1] / volume;
  const speciality = Math.round((calcSpeciality || 0) * 100);

  return {
    volume,
    speciality
  };
}

export function calculateAverage(data: any, timeRange?: ITimeRange) {
  if (data.length >= 2) {
    const dataArray = data.slice().reverse();
    let avgArray: any = [];

    if (timeRange === "DAY") {
      const currentArray = dataArray.slice(0, 24);

      const tempArray = dataArray.slice(24);
      tempArray.slice(0, 24).forEach((d: any, i: number) => {
        avgArray[i] = [
          tempArray[i][0],
          d3.mean([
            tempArray[i]?.[1] || 0,
            tempArray[i + 24]?.[1] || 0,
            tempArray[i + 48]?.[1] || 0
          ])
        ];
      });

      return [...currentArray, ...avgArray].reverse();
    }

    avgArray = dataArray.slice(1);
    const average = d3.mean(avgArray, (v: any) => +v[1] || 0);
    return [[data[data.length - 2][0], average], data[data.length - 1]];
  }

  return data;
}

function calculateSimilarDataDay(data: any, volumeArray: number[][]) {
  const calcChartData = calculateAverage(volumeArray, "DAY");
  const chartData = [
    ...accumulate(calcChartData.slice(0, 24)),
    ...accumulate(calcChartData.slice(24))
  ];

  const chunksVolume = Array(SIMILAR_TIME_PERIOD + 1)
    .fill("")
    .map((_, i) => [
      0,
      volumeArray.slice(i * 24, i * 24 + 24).reduce((agg: number, v: number[]) => agg + v[1], 0) ||
        0
    ]);
  const calcVolume = calculateAverage(chunksVolume);
  const volume = calcVolume?.[1]?.[1] || 0;
  const avgVolume = calcVolume?.[0]?.[1] || 0;

  const chunksSpeciality = Array(SIMILAR_TIME_PERIOD + 1)
    .fill("")
    .map((_, i) => [
      0,
      data[BeerType.SPECIALITY]
        .slice(i * 24, i * 24 + 24)
        .reduce((agg: number, v: number[]) => agg + v[1], 0) || 0
    ]);

  const specialityArray = chunksVolume.map((v: number[], i: number) => [
    0,
    !chunksSpeciality?.[i]?.[1] ? 0 : chunksSpeciality?.[i]?.[1] / v[1]
  ]);

  const calcSpeciality = calculateAverage(specialityArray);
  const speciality = Math.round((calcSpeciality?.[1]?.[1] || 0) * 100);
  const avgSpeciality = Math.round((calcSpeciality?.[0]?.[1] || 0) * 100);

  return { volume, avgVolume, speciality, avgSpeciality, chartData };
}

function calculateSimilarData(data: any, volumeArray: number[][]) {
  const calcVolume = calculateAverage(volumeArray);
  const volume = calcVolume?.[1]?.[1] || 0;
  const avgVolume = calcVolume?.[0]?.[1] || 0;

  const specialityArray = volumeArray.map((v, i) => [
    v[0],
    !data[BeerType.SPECIALITY]?.[i]?.[1] ? 0 : data[BeerType.SPECIALITY]?.[i]?.[1] / v[1]
  ]);
  const calcSpeciality = calculateAverage(specialityArray);
  const speciality = Math.round((calcSpeciality?.[1]?.[1] || 0) * 100);
  const avgSpeciality = Math.round((calcSpeciality?.[0]?.[1] || 0) * 100);

  return { volume, avgVolume, speciality, avgSpeciality };
}

export function calculateTimespanForSimilar(timeFrame: ITimeFrame) {
  const lessThanWeek =
    timeFrame.type === "DAY" ||
    timeFrame.type === "YESTERDAY" ||
    (timeFrame.type === "CUSTOM" && timeFrame.from.diff(timeFrame.to) <= 7);

  const diffTimespan = lessThanWeek
    ? getTimeOfTimeRange("WEEK")
    : timeFrame.type === "CUSTOM"
    ? getTimeOfTimeRange("YEAR")
    : getTimeOfTimeRange(timeFrame.type || "WEEK");

  return diffTimespan;
}

export function preprocessSalesHeader(req: any, filter: IFilterState) {
  const res: {
    data: {
      current: {
        volume: number;
        speciality: number;
      };
      similar: {
        volume: number;
        speciality: number;
      };
      target?: {
        volume: number;
        speciality: number;
      };
      outlet?: {
        volume: number;
        speciality: number;
      };
      similarOutlets?: {
        volume: number;
        speciality: number;
      };
      nearbyOutlets?: {
        volume: number;
        speciality: number;
      };
    };
  } = {
    data: {
      current: { volume: 0, speciality: 0 },
      similar: { volume: 0, speciality: 0 }
    }
  };

  return req.then((d: any) => {
    if (d) {
      const data = { ...d[0]?.data };
      delete data.realConsumption;
      delete data.targetConsumption;
      const dataTarget = { ...d[1]?.data };
      const outletConsumption = d[2]?.data?.outletConsumption;
      const similarOutletConsumption = d[3]?.data?.similarOutletConsumption;
      const nearbyOutletConsumption = d[4]?.data?.nearbyOutletConsumption;

      const dataVolume: any = {};
      let volumeArray: number[][] = [];
      Object.keys(data).forEach((agg: any) => {
        Object.keys(data[agg]).forEach((type: any) => {
          dataVolume[type] = (dataVolume[type] || [])
            .concat(data[agg][type])
            .sort((a: number[], b: number[]) => a[0] - b[0]);
        });
      });

      volumeArray = joinVolumeTypes(dataVolume);

      const { volume, avgVolume, speciality, avgSpeciality } = calculateSimilarData(
        dataVolume,
        volumeArray
      );

      let outlet;
      if (outletConsumption) {
        const { volume: outletVolume, speciality: outletSpeciality } = calcVolumeAndSpeciality(
          outletConsumption
        );

        outlet = {
          volume: volume - outletVolume,
          speciality: speciality - outletSpeciality
        };
      }

      res.data.current = { volume, speciality };
      res.data.similar = { volume: volume - avgVolume, speciality: speciality - avgSpeciality };

      if (Object.keys(dataTarget.targetConsumption || {}).length > 0) {
        let targetTotalVolume = 0;
        let realTotalVolume = 0;
        let noTarget = false;
        const specialityTarget: number[] = [];
        Object.keys(dataTarget.targetConsumption || {}).forEach(key => {
          const targetConsumption = sliceTarget(dataTarget.targetConsumption[key]);
          if (targetConsumption && !withoutVolume(targetConsumption)) {
            const { volume: targetVolume } = calcVolumeAndSpeciality(targetConsumption);
            targetTotalVolume += targetVolume;

            const realConsumption = sliceTarget(dataTarget.realConsumption[key]);
            if (realConsumption) {
              const { volume: realVolume, speciality: realSpeciality } = calcVolumeAndSpeciality(
                realConsumption
              );
              realTotalVolume += realVolume;

              specialityTarget.push(realSpeciality || 0);
            }
          } else {
            noTarget = true;
          }
        });

        if (!noTarget) {
          const realSpeciality = d3.mean(specialityTarget) || 0;

          res.data.target = {
            volume: realTotalVolume - targetTotalVolume,
            speciality: realSpeciality - (filter?.currentTarget?.craftAndSpecialityPercentage || 0)
          };
        }
      }

      if (!withoutVolume(similarOutletConsumption)) {
        const { volume: similarOVolume, speciality: similarOSpeciality } = calcVolumeAndSpeciality(
          similarOutletConsumption
        );
        res.data.similarOutlets = {
          volume: volume - similarOVolume,
          speciality: speciality - similarOSpeciality
        };
      }

      if (!withoutVolume(nearbyOutletConsumption)) {
        const { volume: nearbyOVolume, speciality: nearbyOSpeciality } = calcVolumeAndSpeciality(
          nearbyOutletConsumption
        );
        res.data.nearbyOutlets = {
          volume: volume - nearbyOVolume,
          speciality: speciality - nearbyOSpeciality
        };
      }

      res.data.outlet = outlet;
    }
    return res;
  });
}

export function preprocessTimeConsumption(req: any, filter: IFilterState) {
  const res: {
    data: {
      bars: Array<{
        label: string;
        value: number;
        tickGroup?: string;
      }>;
      line: Array<{
        label: string;
        value: number;
        tickGroup?: string;
      }>;
    };
  } = {
    data: {
      bars: [],
      line: []
    }
  };
  return req.then((d: any) => {
    if (d) {
      const openingHours = d[0]?.data?.openingHoursList || [];
      let consumption = d[1]?.data?.consumption;
      let lineArray: Array<{
        label: string;
        value: number;
      }> = [];

      const preparedOpeningHours = prepareOpeningHours(openingHours);
      consumption = consumption.map((c: any) => [
        ...c,
        isInsideOpeningHours(c[0], preparedOpeningHours)
      ]);
      const barArray = joinByTimeFrame(consumption, filter.timeFrame, true);

      if (filter.performanceType === PERFORMANCE_TYPE.AVG) {
        const similarAgg = { ...d[1]?.data };
        delete similarAgg.consumption;

        const similarArray: number[][] = [];
        const volumeArray: number[][] = [];
        Object.keys(similarAgg).forEach((agg: any) => {
          consumption.forEach((_: any, i: number) => {
            if (!similarArray[i]) {
              similarArray[i] = [+similarAgg[agg][i]?.[1] || 0];
            } else {
              similarArray[i].push(+similarAgg[agg][i]?.[1] || 0);
            }
          });
        });
        consumption.forEach((d2: any, i: number) => {
          volumeArray[i] = [d2[0], d3.mean(similarArray[i]) || 0];
        });

        lineArray = joinByTimeFrame(volumeArray, filter.timeFrame);
      } else if (filter.performanceType === PERFORMANCE_TYPE.TARGET) {
        const targetConsumption = sliceTarget(d[1]?.data?.targetConsumption);
        if (targetConsumption && !withoutVolume(targetConsumption)) {
          lineArray = joinByTimeFrame(targetConsumption, filter.timeFrame);
        }
      } else if (filter.performanceType === PERFORMANCE_TYPE.OUTLET) {
        const outletConsumption = d[2]?.data?.outletConsumption;
        lineArray = joinByTimeFrame(outletConsumption, filter.timeFrame);
      } else if (filter.performanceType === PERFORMANCE_TYPE.SIMILAR) {
        const similarOConsumption = d[2]?.data?.similarOutletsConsumption;
        if (!withoutVolume(similarOConsumption)) {
          lineArray = joinByTimeFrame(similarOConsumption, filter.timeFrame);
        }
      } else if (filter.performanceType === PERFORMANCE_TYPE.NEAR) {
        const nearbyOConsumption = d[2]?.data?.nearbyOutletsConsumption;
        if (!withoutVolume(nearbyOConsumption)) {
          lineArray = joinByTimeFrame(nearbyOConsumption, filter.timeFrame);
        }
      }

      res.data.bars = barArray;
      res.data.line = lineArray;
    }
    return res;
  });
}

export function preprocessSpecialityMix(req: any, filter: IFilterState) {
  const res: {
    data: {
      specialityMix: {
        value: number;
        valueCompare?: number;
      };
    };
  } = {
    data: {
      specialityMix: {
        value: 0,
        valueCompare: undefined
      }
    }
  };
  const { performanceType: type } = filter;
  return req.then((d: any) => {
    if (d) {
      const consumption = d[0]?.data?.consumption;

      const specialityMix: {
        value: number;
        valueCompare?: number;
      } = {
        value: 0,
        valueCompare: undefined
      };

      if (type === PERFORMANCE_TYPE.AVG) {
        const similarAgg = { ...d[0]?.data };
        const dataVolume: any = {};
        Object.keys(similarAgg).forEach((agg: any) => {
          Object.keys(similarAgg[agg]).forEach((aggType: any) => {
            dataVolume[aggType] = (dataVolume[aggType] || [])
              .concat(similarAgg[agg][aggType])
              .sort((a: number[], b: number[]) => a[0] - b[0]);
          });
        });

        const volumeArray = joinVolumeTypes(dataVolume);
        const { speciality, avgSpeciality } = calculateSimilarData(dataVolume, volumeArray);
        specialityMix.value = speciality;
        specialityMix.valueCompare = avgSpeciality;
      } else if (type === PERFORMANCE_TYPE.TARGET) {
        const { speciality } = calcVolumeAndSpeciality(consumption);
        specialityMix.value = speciality;
        specialityMix.valueCompare = filter?.currentTarget?.craftAndSpecialityPercentage || 0;
        // const targetConsumption = sliceTarget(d[0]?.data?.targetConsumption);
        // if (targetConsumption && !withoutVolume(targetConsumption)) {
        //   const { speciality: targetSpeciality } = calcVolumeAndSpeciality(targetConsumption);
        //   specialityMix.valueCompare = targetSpeciality;
        // }
      } else if (type === PERFORMANCE_TYPE.OUTLET) {
        const { speciality } = calcVolumeAndSpeciality(consumption);
        const outletConsumption = d[1]?.data?.outletConsumption;
        const { speciality: outletSpeciality } = calcVolumeAndSpeciality(outletConsumption);
        specialityMix.value = speciality;
        specialityMix.valueCompare = outletSpeciality;
      } else if (type === PERFORMANCE_TYPE.SIMILAR) {
        const { speciality } = calcVolumeAndSpeciality(consumption);
        const similarOConsumption = d[1]?.data?.similarOutletsConsumption;
        const { speciality: outletSpeciality } = calcVolumeAndSpeciality(similarOConsumption);
        specialityMix.value = speciality;
        specialityMix.valueCompare = outletSpeciality;
      } else if (type === PERFORMANCE_TYPE.NEAR) {
        const { speciality } = calcVolumeAndSpeciality(consumption);
        const nearbyOConsumption = d[1]?.data?.nearbyOutletsConsumption;
        const { speciality: outletSpeciality } = calcVolumeAndSpeciality(nearbyOConsumption);
        specialityMix.value = speciality;
        specialityMix.valueCompare = outletSpeciality;
      }

      res.data.specialityMix = specialityMix;
    }
    return res;
  });
}

export function preprocessBeerConsumption(req: any, filter: IFilterState) {
  const res: {
    data: {
      beverages: Array<{
        id: string;
        value: number;
        valueCompare?: number;
      }>;
    };
  } = {
    data: {
      beverages: []
    }
  };
  const { performanceType: type, beerIds } = filter;
  return req.then((d: any) => {
    if (d) {
      const beerConsumption = d[0]?.data?.beerConsumption;
      const data: { [key: string]: number[][] } = {};

      if (type === PERFORMANCE_TYPE.AVG) {
        const similarAgg = { ...d[0]?.data };
        Object.keys(similarAgg).forEach((agg: any) => {
          Object.keys(similarAgg[agg]).forEach((bevId: string) => {
            if (!beerConsumption[bevId]) {
              return;
            }

            if (!data[bevId]) {
              data[bevId] = similarAgg[agg][bevId];
            } else {
              data[bevId] = data[bevId].concat(similarAgg[agg][bevId]);
            }
          });
        });

        Object.keys(data).forEach(bevId => {
          if (data[bevId].length < 4) {
            data[bevId] = Array(4 - data[bevId].length)
              .fill([])
              .concat(data[bevId]);
          }
          data[bevId] = calculateAverage(data[bevId].sort((a: any, b: any) => a?.[0] - b?.[0]));
        });
      } else if (type === PERFORMANCE_TYPE.TARGET) {
        const targetConsumption = sliceTarget(d[0]?.data?.targetConsumption);
        const target = targetConsumption && withoutVolume(targetConsumption);
        Object.keys(beerConsumption).forEach(bevId => {
          data[bevId] = [
            !target ? undefined : targetConsumption[bevId]?.[0] || [],
            beerConsumption[bevId][0]
          ];
        });
      } else if (type === PERFORMANCE_TYPE.OUTLET) {
        const outletConsumption = d[1]?.data?.outletConsumption;
        Object.keys(beerConsumption).forEach(bevId => {
          data[bevId] = [outletConsumption[bevId]?.[0] || [], beerConsumption[bevId][0]];
        });
      } else if (type === PERFORMANCE_TYPE.SIMILAR) {
        const similarOConsumption = d[1]?.data?.similarOutletsConsumption;
        Object.keys(beerConsumption).forEach(bevId => {
          data[bevId] = [similarOConsumption[bevId]?.[0] || [], beerConsumption[bevId][0]];
        });
      } else if (type === PERFORMANCE_TYPE.NEAR) {
        const nearbyOConsumption = d[1]?.data?.nearbyOutletsConsumption;
        Object.keys(beerConsumption).forEach(bevId => {
          data[bevId] = [nearbyOConsumption[bevId]?.[0] || [], beerConsumption[bevId][0]];
        });
      }

      const beverages = Object.keys(data).map(key => ({
        id: key,
        value: +data[key]?.[1]?.[1] || 0,
        valueCompare:
          data[key]?.[0]?.[1] !== undefined
            ? (+data[key]?.[1]?.[1] || 0) - (+data[key]?.[0]?.[1] || 0)
            : undefined
      }));

      if (!filter.allBeerIdsSelected) {
        beerIds.forEach(id => {
          if (!beverages.find(b => b.id === id)) {
            beverages.push({ id, value: 0, valueCompare: 0 });
          }
        });
      }

      res.data.beverages = beverages;
    }

    return res;
  });
}

export function preprocessWeekDay(req: any, type: PERFORMANCE_TYPE) {
  const res: {
    data: {
      bars: Array<{
        label: string;
        value: number;
      }>;
      line: Array<{
        label: string;
        value: number;
      }>;
    };
  } = {
    data: {
      bars: [],
      line: []
    }
  };
  return req.then((d: any) => {
    if (d) {
      const consumption = d[0]?.data?.consumption;
      let lineArray: Array<{
        label: string;
        value: number;
      }> = [];
      const barArray = joinByWeekDay(consumption);

      if (type === PERFORMANCE_TYPE.AVG) {
        const similarAgg = { ...d[0]?.data };
        delete similarAgg.consumption;

        let volumeArray: number[][] = [];
        Object.keys(similarAgg).forEach((agg: any) => {
          volumeArray = volumeArray
            .concat(similarAgg[agg])
            .sort((a: number[], b: number[]) => a[0] - b[0]);
        });

        lineArray = joinByWeekDay(volumeArray);
      } else if (type === PERFORMANCE_TYPE.TARGET) {
        const targetConsumption = sliceTarget(d[0]?.data?.targetConsumption);
        if (targetConsumption && !withoutVolume(targetConsumption)) {
          lineArray = joinByWeekDay(targetConsumption);
        }
      } else if (type === PERFORMANCE_TYPE.OUTLET) {
        const outletConsumption = d[1]?.data?.outletConsumption;
        lineArray = joinByWeekDay(outletConsumption);
      } else if (type === PERFORMANCE_TYPE.SIMILAR) {
        const similarOConsumption = d[1]?.data?.similarOutletsConsumption;
        if (!withoutVolume(similarOConsumption)) {
          lineArray = joinByWeekDay(similarOConsumption);
        }
      } else if (type === PERFORMANCE_TYPE.NEAR) {
        const nearbyOConsumption = d[1]?.data?.nearbyOutletsConsumption;
        if (!withoutVolume(nearbyOConsumption)) {
          lineArray = joinByWeekDay(nearbyOConsumption);
        }
      }

      const sunday = barArray.shift();
      barArray.push(sunday as any);

      const sundayLine = lineArray.shift();
      lineArray.push(sundayLine as any);
      res.data.bars = barArray;
      res.data.line = lineArray;
    }
    return res;
  });
}

export function preprocessDayHour(req: any, type: PERFORMANCE_TYPE) {
  const res: {
    data: {
      bars: Array<{
        label: string;
        value: number;
      }>;
      line: Array<{
        label: string;
        value: number;
      }>;
    };
  } = {
    data: {
      bars: [],
      line: []
    }
  };
  return req.then((d: any) => {
    if (d) {
      const consumption = d[0]?.data?.consumption;
      let lineArray: Array<{
        label: string;
        value: number;
      }> = [];
      const barArray = joinByDayHour(consumption);

      if (type === PERFORMANCE_TYPE.AVG) {
        const similarAgg = { ...d[0]?.data };
        delete similarAgg.consumption;

        let volumeArray: number[][] = [];
        Object.keys(similarAgg).forEach((agg: any) => {
          volumeArray = volumeArray
            .concat(similarAgg[agg])
            .sort((a: number[], b: number[]) => a[0] - b[0]);
        });

        lineArray = joinByDayHour(volumeArray);
      } else if (type === PERFORMANCE_TYPE.TARGET) {
        const targetConsumption = sliceTarget(d[0]?.data?.targetConsumption);
        if (targetConsumption && !withoutVolume(targetConsumption)) {
          lineArray = joinByDayHour(targetConsumption);
        }
      } else if (type === PERFORMANCE_TYPE.OUTLET) {
        const outletConsumption = d[1]?.data?.outletConsumption;
        lineArray = joinByDayHour(outletConsumption);
      } else if (type === PERFORMANCE_TYPE.SIMILAR) {
        const similarOConsumption = d[1]?.data?.similarOutletsConsumption;
        if (!withoutVolume(similarOConsumption)) {
          lineArray = joinByDayHour(similarOConsumption);
        }
      } else if (type === PERFORMANCE_TYPE.NEAR) {
        const nearbyOConsumption = d[1]?.data?.nearbyOutletsConsumption;
        if (!withoutVolume(nearbyOConsumption)) {
          lineArray = joinByDayHour(nearbyOConsumption);
        }
      }

      res.data.bars = barArray;
      res.data.line = lineArray;
    }
    return res;
  });
}

export function preprocessOverview(req: any, filter: IFilterState, timeRange?: ITimeRange) {
  const res: {
    data: {
      current: { volume: number; speciality: number };
      similar: { volume: number; speciality: number };
      target?: { volume: number; speciality: number };
      similarOutlets?: { volume: number; speciality: number };
      nearbyOutlets?: { volume: number; speciality: number };
      chartData?: number[][];
    };
  } = {
    data: {
      current: { volume: 0, speciality: 0 },
      similar: { volume: 0, speciality: 0 },
      target: undefined,
      similarOutlets: undefined,
      nearbyOutlets: undefined,
      chartData: undefined
    }
  };

  return req.then((d: any) => {
    if (d) {
      const data = { ...d[0].data };
      const targetConsumption = d[0]?.data?.targetConsumption || {};
      const realConsumption = d[0]?.data?.realConsumption || {};
      const nearbyOutletConsumption = d[1]?.data?.nearbyConsumption || {};
      const similarOutletConsumption = d[2]?.data?.similarOutletConsumption || {};
      delete data.realConsumption;
      delete data.targetConsumption;

      const dataVolume: any = {};
      let volumeArray: number[][] = [];
      Object.keys(data).forEach((agg: any) => {
        Object.keys(data[agg]).forEach((type: any) => {
          dataVolume[type] = (dataVolume[type] || [])
            .concat(data[agg][type])
            .sort((a: number[], b: number[]) => a[0] - b[0]);
        });
      });

      volumeArray = joinVolumeTypes(dataVolume);

      // @ts-ignore
      const { volume, avgVolume, speciality, avgSpeciality, chartData } =
        timeRange === "DAY"
          ? calculateSimilarDataDay(dataVolume, volumeArray)
          : calculateSimilarData(dataVolume, volumeArray);

      res.data.current = { volume, speciality };
      res.data.similar = { volume: volume - avgVolume, speciality: speciality - avgSpeciality };

      res.data.chartData = chartData;

      if (Object.keys(targetConsumption).length > 0) {
        let targetTotalVolume = 0;
        let realTotalVolume = 0;
        const noTarget: boolean[] = [];
        const specialityTarget: number[] = [];
        Object.keys(targetConsumption).forEach(key => {
          const targetConsumptionSliced = sliceTarget(targetConsumption[key]);
          if (targetConsumptionSliced && !withoutVolume(targetConsumptionSliced)) {
            noTarget.push(false);
            const { volume: targetVolume } = calcVolumeAndSpeciality(targetConsumptionSliced);
            targetTotalVolume += targetVolume;

            const realConsumptionSliced = sliceTarget(realConsumption[key]);
            if (realConsumptionSliced) {
              const { volume: realVolume, speciality: realSpeciality } = calcVolumeAndSpeciality(
                realConsumptionSliced
              );
              realTotalVolume += realVolume;

              specialityTarget.push(realSpeciality || 0);
            }
          } else {
            noTarget.push(true);
          }
        });

        if (noTarget.some(t => !t)) {
          const realSpeciality = d3.mean(specialityTarget) || 0;
          res.data.target = {
            volume: realTotalVolume - targetTotalVolume,
            speciality: realSpeciality - (filter?.currentTarget?.craftAndSpecialityPercentage || 0)
          };
        }
      }

      if (!withoutVolume(similarOutletConsumption)) {
        const { volume: similarOVolume, speciality: similarOSpeciality } = calcVolumeAndSpeciality(
          similarOutletConsumption
        );
        res.data.similarOutlets = {
          volume: volume - similarOVolume,
          speciality: speciality - similarOSpeciality
        };
      }

      if (!withoutVolume(nearbyOutletConsumption)) {
        const { volume: nearbyOVolume, speciality: nearbyOSpeciality } = calcVolumeAndSpeciality(
          nearbyOutletConsumption
        );
        res.data.nearbyOutlets = {
          volume: volume - nearbyOVolume,
          speciality: speciality - nearbyOSpeciality
        };
      }
    }
    return res;
  });
}

export function preprocessOutletReview(req: any) {
  const res: {
    data: {
      [key: string]: {
        volume: { value: number; pct: number };
        speciality: { value: number; pct: number };
      };
    };
  } = {
    data: {}
  };

  return req.then((d: any) => {
    if (d && d.data) {
      const data = d.data.consumptionPerOutlet;
      res.data = {};
      if (!data[BeerType.CORE_BEER]) {
        // avoid if no data
        Object.keys(data).forEach((outlet: string) => {
          const data2 = data[outlet];
          const volumeArray: number[][] = joinVolumeTypes(data2);

          const { volume, avgVolume, speciality, avgSpeciality } = calculateSimilarData(
            data2,
            volumeArray
          );

          res.data[outlet] = {
            volume: { value: volume, pct: volume - avgVolume },
            speciality: { value: speciality, pct: speciality - avgSpeciality }
          };
        });
      }
    }
    return res;
  });
}

export function preprocessMonthlyTarget(req: any) {
  const res: {
    data: Array<{
      date: Time;
      remainingDays?: number;
      volume?: {
        goal: number;
        current: number;
        expected?: number;
      };
      speciality?: {
        goal: number;
        current: number;
        expected?: number;
      };
      isRecurring?: boolean;
    }>;
  } = {
    data: [
      {
        date: new Time()
      }
    ]
  };
  if (!req) {
    return res;
  }
  return req.then((d: any) => {
    if (d) {
      const targets = d[0]?.data || [];
      const targetConsumption = d[1]?.data?.targetConsumption;

      const { volume: targetVolume } = calcVolumeAndSpeciality(targetConsumption);

      res.data = targets
        .filter(
          (t: ITargetParams) => t.totalVolume !== null && t.craftAndSpecialityPercentage !== null
        )
        .map((t: ITargetParams) => {
          const currentMonth = t.month === d3.timeFormat("%Y%m")(new Time().toJSDate());
          const consumption = currentMonth ? d[1]?.data?.realConsumption : d[1]?.data?.[t.month];
          const { volume, speciality } = calcVolumeAndSpeciality(consumption);

          const date = new Time();
          date.set({ year: +t.month.slice(0, 4), month: +t.month.slice(4) - 1, day: 1 });
          const endMonth = new Time();
          endMonth.set({ day: 1, hour: 8, minute: 0 }).add({ month: 1 });
          const remainingDays: any = currentMonth && new Time().diff(endMonth);
          const expectedVolume = volume + (currentMonth ? targetVolume : 0);

          return {
            date,
            remainingDays,
            volume: {
              goal: t.totalVolume,
              current: volume,
              expected: expectedVolume !== undefined ? expectedVolume - t.totalVolume : null
            },
            speciality: {
              goal: t.craftAndSpecialityPercentage,
              current: speciality,
              expected: speciality - t.craftAndSpecialityPercentage
            },
            isRecurring: t.recurring
          };
        });
    }

    // add current month with no targets
    if (
      res.data.length > 0 &&
      !res.data.some(
        t =>
          t.date.getMonth() === new Time().getMonth() && t.date.getYear() === new Time().getYear()
      )
    ) {
      res.data.unshift({
        date: new Time().set({ day: 1 })
      });
    }
    return res;
  });
}

function iterateUntilArray(data: any, callback: (data: any) => void) {
  let newD = data ? { ...data } : data;
  if (Array.isArray(data)) {
    newD = callback(data);
  } else {
    let obj = Object.keys(data).length
      ? data
      : { [BeerType.CORE_BEER]: [], [BeerType.SPECIALITY]: [] };

    if (Object.keys(data).length === 1) {
      if (Object.keys(data).includes(BeerType.CORE_BEER)) {
        obj = {
          [BeerType.CORE_BEER]: data[BeerType.CORE_BEER],
          [BeerType.SPECIALITY]: data[BeerType.CORE_BEER].map((d: any) => [d[0], null])
        };
      }
      if (Object.keys(data).includes(BeerType.SPECIALITY)) {
        obj = {
          [BeerType.CORE_BEER]: data[BeerType.SPECIALITY].map((d: any) => [d[0], null]),
          [BeerType.SPECIALITY]: data[BeerType.SPECIALITY]
        };
      }
    }
    Object.keys(obj).forEach((k: string) => {
      newD[k] = iterateUntilArray(obj[k], callback);
    });
  }
  return newD;
}

export function convertToLiters(d: any, name: string) {
  const newD = d ? { ...d } : d;
  try {
    if (d && d.data && d.data[name]) {
      // eslint-disable-next-line no-param-reassign
      newD.data[name] = iterateUntilArray(d.data[name], data =>
        data.map((v: number[]) => [+v[0], +v[1] / 1000])
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("error converting liters", e);
  }
  return newD;
}

export function fillFaultyInfo(
  d: any,
  start: number,
  end: number,
  timespan: number,
  name: string,
  isExplore = false
) {
  const newD = d ? { ...d } : d;
  if (d?.data?.[name]) {
    let hasHourChange = false;
    if (!isExplore) {
      hasHourChange =
        timespan === getTimeOfTimeRange("DAY") && new Time(+d.data[name][0]?.[0]).getHour() > 8;

      if (hasHourChange || timespan >= getTimeOfTimeRange("WEEK")) {
        // eslint-disable-next-line no-param-reassign
        end = new Time(end).add({ hour: 3 }).getTime();
      }
    }

    newD.data[name] = iterateUntilArray(d.data[name], data => {
      const timespans = data.map((k: any) => +k[0]);
      let currentT = start + timespan;
      while (currentT <= end) {
        if (!timespans.includes(currentT)) {
          data.push([currentT, null]);
        }
        currentT += timespan;
      }

      return data
        .map((k: number[][]) => [+k[0], k[1]])
        .filter((f: any) => (!isExplore ? f[0] <= end : true))
        .sort((a: number[], b: number[]) => a[0] - b[0]);
    });

    if (hasHourChange) {
      newD.data[name] = newD.data[name].map((f: any) => [
        [new Time(f[0]).sub({ hour: 3 }).getTime()],
        f[1]
      ]);
    }
  }

  return newD;
}

export function prepareRequest(
  promise: Promise<AxiosResponse<{ [key: string]: number[][] }>>,
  aggs: IExploreAggregatorRequest[]
) {
  const isExplore = aggs.some(a => a.splitBy);
  return promise
    .then(d => {
      let data = d;
      aggs.forEach(a => {
        data = convertToLiters(data, a.name);
      });
      return data;
    })
    .then(d => {
      let data = d;
      aggs.forEach(a => {
        data = fillFaultyInfo(
          data,
          a.startTimestamp,
          a.endTimestamp,
          a.timespan,
          a.name,
          isExplore
        );
      });
      return data;
    });
}
