import React from "react";
import { useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { IAppState } from "../../../store";
import { getBeveragesByIds } from "../../../store/beers/reducer";
import InisghtCard from "./InsightCard";
import "./insight-cards.scss";

interface IProps {
  expanded: boolean;
  greatPerformingBeverageIds: string[];
}

const GreatPerformanceInsightCard: React.FC<IProps> = ({
  expanded,
  greatPerformingBeverageIds
}) => {
  const { managerAppProduct: content } = useContent();
  const greatPerformingBeverages = useSelector((state: IAppState) =>
    getBeveragesByIds(state, greatPerformingBeverageIds)
  );
  const isPlural = greatPerformingBeverages.length > 1;
  const beverageNames = greatPerformingBeverages.map(beverage => beverage.name);
  const lastBeverageName = beverageNames[beverageNames.length - 1];

  return greatPerformingBeverages.length > 0 ? (
    <InisghtCard
      beverages={greatPerformingBeverages}
      expanded={expanded}
      icon="ok"
      title={(isPlural
        ? content.manager_app_insights_great_performance_plural
        : content.manager_app_insights_great_performance
      )
        .replace("%brand%", lastBeverageName)
        .replace("%brands%", beverageNames.join(", "))
        .replace("%last_brand%", lastBeverageName)}
      text={(isPlural
        ? content.manager_app_insights_great_performance_text_plural
        : content.manager_app_insights_great_performance_text
      )
        .replace("%brand%", lastBeverageName)
        .replace("%brands%", beverageNames.join(", "))
        .replace("%last_brand%", lastBeverageName)}
    />
  ) : null;
};

export default GreatPerformanceInsightCard;
