import produce from "immer";
import { Reducer } from "redux";
import {
  GET_FAULTY_SENSORS_ERROR,
  GET_FAULTY_SENSORS_SUCCESS,
  IApiPressureChamber,
  IPressureChamber,
  IInstallationState,
  InstallationActionTypes,
  REQUEST_PRESSURE_CHAMBERS,
  REQUEST_PRESSURE_CHAMBERS_ERROR,
  REQUEST_PRESSURE_CHAMBERS_SUCCESS,
  SET_PRESSURE_CHAMBER_BEVERAGE
} from "./types";
import { calculateNotificationBadges } from "../../helpers";
import { BeverageTranslation } from "../beers/types";
import Time from "../../lib/Time/Time";

export const initialState: IInstallationState = {
  outlets: {},
  error: false,
  loadingPressureChambers: false,
  faultySensors: {}
};

const reducer: Reducer<IInstallationState, InstallationActionTypes> = (
  state = initialState,
  action: InstallationActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_PRESSURE_CHAMBERS:
        draft.error = false;
        draft.loadingPressureChambers = true;

        break;
      case REQUEST_PRESSURE_CHAMBERS_SUCCESS:
        Object.entries(action.payload).forEach(([outletId, installation]) => {
          const pressureChambers = (installation?.pressureChambers || [])
            .map((pressureChamber: IApiPressureChamber) => {
              const volumeLeft =
                pressureChamber.keg && pressureChamber.keg.volumeLeft !== null
                  ? pressureChamber.keg.volumeLeft / 1000
                  : -1;

              let tapPosition = pressureChamber.taps
                ? pressureChamber.taps.map(tp => tp.position)
                : [];
              if (!Array.isArray(tapPosition)) {
                tapPosition = [tapPosition];
              }
              let offline = !pressureChamber.alive;
              if (pressureChamber.faulty && !pressureChamber.alive) {
                // turn pressure chamber online if it is the only one offline due to being faulty
                offline =
                  installation?.pressureChambers.filter((p: IApiPressureChamber) => !p.alive)
                    .length !== 1;
              }

              const mappedPressureChamber: IPressureChamber = {
                id: pressureChamber.id,
                thingId: pressureChamber.thingId,
                name: pressureChamber.name ? pressureChamber.name : "No Name",
                parentName: installation.name,
                volumeLeft,
                beverageId: pressureChamber.keg?.beverage?.id ?? "",
                beverage: new BeverageTranslation(),
                noKeg: false,
                position: pressureChamber.position,
                tapPosition: tapPosition.sort(),
                tags: [],
                lastMounted: new Time(pressureChamber.lastMounted).getTime(),
                lastUsed: pressureChamber.lastUsed
                  ? new Time(pressureChamber.lastUsed).getTime()
                  : 0,
                lastCleaning: pressureChamber.lastCleaning
                  ? new Time(pressureChamber.lastCleaning).getTime()
                  : null,
                temperature: pressureChamber.temperature,
                serialNumber: pressureChamber.serialNumber,
                offline,
                sensorConnected: pressureChamber.sensorConnected,
                lastTimestamp: pressureChamber.lastHeartbeat,
                dangerTags: 0,
                warningTags: 0,
                successTags: 0,
                isFaulty: pressureChamber.faulty ?? false
              };

              mappedPressureChamber.tags = calculateNotificationBadges(mappedPressureChamber);
              mappedPressureChamber.successTags = mappedPressureChamber.tags.filter(
                t => t.level === "success"
              ).length;
              mappedPressureChamber.warningTags = mappedPressureChamber.tags.filter(
                t => t.level === "warning"
              ).length;
              mappedPressureChamber.dangerTags = mappedPressureChamber.tags.filter(
                t => t.level === "danger"
              ).length;
              return mappedPressureChamber;
            })
            .sort((pcA: IPressureChamber, pcB: IPressureChamber) => {
              return pcA.position < pcB.position ? -1 : 1;
            });

          draft.outlets[outletId] = {
            name: installation?.name || installation.outletName,
            outletId: installation.outletId,
            outletName: installation.outletName,
            offlinePressureChambers: 0,
            lastTimestamp: null,
            pressureChambers
          };
        });

        draft.loadingPressureChambers = false;
        draft.error = false;
        break;
      case REQUEST_PRESSURE_CHAMBERS_ERROR:
        draft.outlets = {};
        draft.loadingPressureChambers = false;
        draft.error = true;
        break;
      case SET_PRESSURE_CHAMBER_BEVERAGE:
        const updatedOutlet = draft.outlets[action.payload.outletId];
        const pressureChamberIndex = updatedOutlet?.pressureChambers.findIndex(
          pressureChamber => pressureChamber.thingId === action.payload.thingId
        );

        const updatedPressureChamber: any = {
          ...updatedOutlet?.pressureChambers[pressureChamberIndex],
          beverageId: action.payload.beverage.id,
          beverage: action.payload.beverage
        };
        updatedOutlet?.pressureChambers.splice(pressureChamberIndex, 1, updatedPressureChamber);
        break;
      case GET_FAULTY_SENSORS_SUCCESS:
        draft.faultySensors = action.payload;
        break;
      case GET_FAULTY_SENSORS_ERROR:
        draft.faultySensors = initialState.faultySensors;
        break;
      default:
        break;
    }
  });

export default reducer;
