import React from "react";
import { SectionTitle } from "@ddm-design-system/typography";
import "./empty.scss";

const Empty: React.FC = () => (
  <div className="empty-page">
    <div className="empty-page-container">
      <SectionTitle>Looks like you don’t have access to any outlet.</SectionTitle>
    </div>
  </div>
);

export default Empty;
