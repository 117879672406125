import React from "react";
import { DescriptionMedium, LabelMedium } from "@ddm-design-system/typography";
import classnames from "classnames";
import * as d3 from "d3";
import { withAggRequest, IWithAggRequestProps } from "../common/hoc/withAggRequest";
import useIsMobile from "../../hooks/useIsMobile";
import PerformanceCard from "../common/performance/PerformanceCard";
import { PERFORMANCE_TYPE } from "../../store/filter/types";
import StyleConstants from "../../constants";
import { PerformanceChart } from "../common/charts/performance-chart/Performance-chart";
import useContent from "../../hooks/useContent";
import Time from "../../lib/Time/Time";
import { day8 } from "../../lib/Time/TimeFunctions";
import styles from "./recent-sales.module.scss";

const RecentSalesCard: React.FC<IWithAggRequestProps & { dark?: boolean }> = ({
  data = {},
  loading = false,
  dark = false,
  error,
  className
}) => {
  const { current, similar, target, similarOutlets, nearbyOutlets, chartData } = data;
  const isTablet = useIsMobile(StyleConstants.mobileBreakpoint1);
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);
  const { managerAppOverview: content, units } = useContent();

  const legendEl = (
    <div className={styles.legendWrapper}>
      <span className={styles.livePerformanceToday} />
      <LabelMedium>{units.timeStrings.unit_today}</LabelMedium>
      <span className={styles.livePerformanceAverage} />
      <LabelMedium>
        {(content.manager_app_your_average || "").replace(
          "%WEEKDAY%",
          d3.timeFormat("%A")(day8(new Time()).toJSDate())
        )}
      </LabelMedium>{" "}
    </div>
  );

  return (
    <div
      className={classnames(
        styles.recentSalesCard,
        isTablet && styles.tablet,
        isMobile && styles.mobile,
        dark && styles.dark,
        className
      )}
    >
      <div
        className={classnames(
          styles.performanceCardWrapper,
          chartData && styles.performanceCardWrapperMargin
        )}
      >
        <PerformanceCard
          data={current}
          type={PERFORMANCE_TYPE.CURRENT}
          loading={loading}
          active
          dark={dark}
          error={error}
        />
        <PerformanceCard
          data={similar}
          type={PERFORMANCE_TYPE.AVG}
          loading={loading}
          dark={dark}
          error={error}
        />
        <PerformanceCard
          data={target}
          type={PERFORMANCE_TYPE.TARGET}
          loading={loading}
          dark={dark}
          error={error}
          detailedError={false}
        />
        <PerformanceCard
          data={similarOutlets}
          type={PERFORMANCE_TYPE.SIMILAR}
          loading={loading}
          dark={dark}
          error={error}
          detailedError={false}
        />
        <PerformanceCard
          data={nearbyOutlets}
          type={PERFORMANCE_TYPE.NEAR}
          loading={loading}
          dark={dark}
          error={error}
          detailedError={false}
        />
      </div>
      {chartData && (
        <PerformanceChart
          className={styles.performanceChart}
          buildGrid
          buildVerticalAxis
          today={chartData.slice(24)}
          yesterday={chartData.slice(0, 24)}
          strokeWidth={3}
          chartHeight={200}
          title={
            <div className={styles.livePerformanceTitleWrapper}>
              <DescriptionMedium className={styles.livePerformanceTitle}>
                {content.live_performance_live_volume_tracker_title}
              </DescriptionMedium>
              {!isMobile && legendEl}
            </div>
          }
          footer={isMobile ? legendEl : undefined}
        />
      )}
    </div>
  );
};

export default withAggRequest(RecentSalesCard);
