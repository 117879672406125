import React from "react";
import { Body } from "@ddm-design-system/typography";
import * as d3 from "d3";
import { SimpleInputSpinner } from "@ddm-design-system/input-spinner";
import { Infoicon } from "@ddm-design-system/infoicon";
import { Divider } from "@ddm-design-system/divider";
import { getBeverageLogoOptions } from "../../helpers";
import { BeverageTranslation } from "../../store/beers/types";
import Time from "../../lib/Time/Time";
import useIsMobile from "../../hooks/useIsMobile";
import StyleConstants from "../../constants";
import useContent from "../../hooks/useContent";
import "./product.scss";

interface IProps {
  beverage: BeverageTranslation;
  lastUpdatedStock: number;
  stock: number;
  onChange: (value: number) => void;
}

const StockListItem: React.FC<IProps> = ({ beverage, stock, lastUpdatedStock, onChange }) => {
  const { managerAppProduct: content } = useContent();
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);
  const formattedDate = d3.timeFormat("%x")(new Time(lastUpdatedStock).toJSDate());

  return (
    <>
      <div className="stock-list-item">
        <Infoicon
          className="stock-list-item-img"
          avatarOptions={getBeverageLogoOptions(beverage)}
        />
        <div className="stock-list-item-desc">
          <Body className="stock-list-item-desc-title">{beverage.name}</Body>
          <Body className="stock-list-item-desc-date">
            {isMobile
              ? formattedDate
              : (content.manager_app_product_updated_at || "").replace("%DATE%", formattedDate)}
          </Body>
        </div>
        <SimpleInputSpinner
          className="stock-list-spinner"
          min={-99}
          max={99}
          value={stock}
          onChange={onChange}
        />
      </div>
      <Divider />
    </>
  );
};

export default StockListItem;
