import React, { useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactMarkdown from "react-markdown";
import { LinkButton } from "@ddm-design-system/button";
import { Icon } from "@ddm-design-system/icon";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { Body } from "@ddm-design-system/typography";
import useContent from "../../../../hooks/useContent";
import { hideFaultySensorModal } from "../../../../store/modals/actions";
import { getFaultySensorModalData } from "../../../../store/modals/reducer";
import { getChosenOutletId } from "../../../../store/filter/selectors";
import { getCurrentLanguage } from "../../../../store/content/selectors";
import { getFeedbackFormUrl } from "../../../../helpers";
import "./faulty-sensor-modal.scss";
import { AnalyticsContext } from "../../../../services/analytics";

const FaultySensorModal: React.FC = () => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const { managerAppModals: content } = useContent();
  const selectedOutletId: string = useSelector(getChosenOutletId);
  const { isVisible, pressureChambers } = useSelector(getFaultySensorModalData);
  const lang = useSelector(getCurrentLanguage).split("-")[0];

  const modalText = useMemo(
    () =>
      !selectedOutletId
        ? content.manager_app_faulty_sensor_modal_text_all
        : pressureChambers?.length === 1
        ? (content.manager_app_faulty_sensor_modal_text_singular || "").replace(
            "%pressurechamber%",
            `${pressureChambers?.[0]}`
          )
        : (content.manager_app_faulty_sensor_modal_text_plural || "")
            .replace(
              "%pressurechambers%",
              `${pressureChambers?.slice(0, pressureChambers.length - 1).join(", ")}`
            )
            .replace(
              "%pressurechamber_last%",
              `${pressureChambers?.[pressureChambers.length - 1]}`
            ),
    [content, pressureChambers, selectedOutletId]
  );

  const handleClose = () => {
    dispatch(hideFaultySensorModal());
  };

  const handleOpenForm = () => {
    analytics.logEvent("FAULTY_FEEDBACK");
    window.open(getFeedbackFormUrl(lang), "_blank");
  };

  return (
    <Modal
      className="faulty-sensor-modal"
      isOpen={isVisible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
    >
      <ModalHeader
        icon={<Icon className="faulty-sensor-modal-title-icon" name="sensor" />}
        title={content.manager_app_faulty_sensor_modal_title}
      />
      <ModalBody>
        <Body>
          <ReactMarkdown source={modalText} />
        </Body>
      </ModalBody>
      <ModalFooter className="faulty-sensor-modal-footer">
        <LinkButton onClick={handleOpenForm}>
          {content.manager_app_faulty_sensor_modal_footer_button}
        </LinkButton>
      </ModalFooter>
    </Modal>
  );
};

export default FaultySensorModal;
