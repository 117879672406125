import React, { useState, useEffect, useCallback, useMemo, forwardRef, useContext } from "react";
import { Radio } from "@ddm-design-system/radio";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Divider } from "@ddm-design-system/divider";
import { ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { WithStylesWrapperProps } from "@ddm-design-system/base";
import { useDispatch, useSelector } from "react-redux";
import { Body } from "@ddm-design-system/typography";
import {
  IOutlet,
  IManualLocationDataInput,
  ILocationOpeningHour,
  ILocationData
} from "../../store/outlet/types";
import { IAppState } from "../../store";
import { IPlaceSuggestion } from "../../services/places";
import useContent from "../../hooks/useContent";
import useDebounce from "../../hooks/useDebounce";
import {
  setOutletGoogleLocationData,
  setOutletManualLocationData,
  getOutletLocationData
} from "../../store/outlet/actions";
import { getOutletLocationDataById } from "../../store/outlet/selectors";
import PlacesInput from "./PlacesInput";
import OutletOpeningHours from "./OutletOpeningHours";
import { AnalyticsContext } from "../../services/analytics";
import "./outlet-info-card.scss";

interface IProps {
  outlet: IOutlet;
  locationData: ILocationData;
  loading: boolean;
}

type Option = "GOOGLE" | "MANUAL";

const WithLocationData = forwardRef<unknown, { outlet: IOutlet }>(({ outlet }, ref) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ILocationData>();
  const locationData = useSelector((state: IAppState) =>
    getOutletLocationDataById(state, outlet.id)
  );
  const [loading, setLoading] = useState(!data);
  useEffect(() => {
    if (!data && locationData) {
      setData(locationData);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  useEffect(() => {
    dispatch(getOutletLocationData(outlet.id));
  }, [dispatch, outlet]);

  return (
    <OutletInfoCard
      wrappedRef={ref}
      loading={loading}
      outlet={outlet}
      locationData={(data && locationData) ?? { address: "", openingHoursList: [] }}
    />
  );
});

const OutletInfoCard: React.FC<IProps & WithStylesWrapperProps> = ({
  outlet,
  locationData,
  loading,
  wrappedRef
}) => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const { managerAppSettings: content } = useContent();
  const [locationDataTemp, setLocationDataTemp] = useState(locationData);
  const [option, setOption] = useState<Option>(locationData.googleBusinessId ? "GOOGLE" : "MANUAL");
  const [place, setPlace] = useState<IPlaceSuggestion>();
  const [manualPlace, setManualPlace] = useState<IManualLocationDataInput>({
    address: locationData.address ?? "",
    locationOpeningHours: locationData?.openingHoursList
  });
  const [defaultPlace, setDefaultPlace] = useState(locationData.googleBusinessId);
  const placeToSave = useDebounce(place, 1000);
  const manualPlaceToSave = useDebounce(manualPlace, 1000);
  const incomplete = useMemo(
    () =>
      (option === "GOOGLE" && !(defaultPlace || place)) ||
      (option === "MANUAL" && !manualPlace.address),
    [option, defaultPlace, place, manualPlace]
  );

  useEffect(() => {
    if (locationDataTemp?.openingHoursList.length === 0) {
      setLocationDataTemp(locationData);
    }
  }, [locationData, locationDataTemp]);

  useEffect(() => {
    if (manualPlace.address === "" && locationDataTemp.address) {
      setManualPlace({
        address: locationDataTemp.address ?? "",
        locationOpeningHours: locationDataTemp?.openingHoursList
      });

      setOption(locationDataTemp.googleBusinessId ? "GOOGLE" : "MANUAL");
    }
  }, [locationDataTemp, manualPlace]);

  useEffect(() => {
    setDefaultPlace(locationDataTemp.googleBusinessId);
  }, [locationDataTemp]);

  useEffect(() => {
    if (option !== "GOOGLE" || !placeToSave) {
      return;
    }
    analytics.logEvent("SET_LOCATION_DATA", "GOOGLE");
    dispatch(setOutletGoogleLocationData(outlet.id, outlet.name, placeToSave.place_id));
  }, [analytics, option, placeToSave, dispatch, outlet]);

  useEffect(() => {
    if (option !== "MANUAL" || !manualPlaceToSave.address) {
      return;
    }
    analytics.logEvent("SET_LOCATION_DATA", "MANUAL");
    dispatch(setOutletManualLocationData(outlet.id, outlet.name, manualPlaceToSave));
  }, [analytics, option, manualPlaceToSave, dispatch, outlet]);

  const updateHours = useCallback(
    (locationOpeningHours: ILocationOpeningHour[]) => {
      if (
        JSON.stringify(locationOpeningHours) !== JSON.stringify(manualPlace.locationOpeningHours)
      ) {
        setManualPlace({ ...manualPlace, locationOpeningHours });
      }
    },
    [manualPlace]
  );
  return (
    <ExpandablePanel
      wrappedRef={wrappedRef}
      initialExpanded={false}
      className="outlet-info-card"
      title={outlet.name}
      renderHeaderDetails={
        !loading
          ? () => (
              <Body className={`outlet-info-status ${incomplete && "incomplete"}`}>
                {
                  content[
                    `manager_app_settings_outlet_info_${option.toLowerCase()}${
                      incomplete ? "_incomplete" : ""
                    }`
                  ]
                }
              </Body>
            )
          : undefined
      }
    >
      {!loading && (
        <div className="outlet-info-options">
          <div className="outlet-info-option outlet-info-option-google">
            <Radio
              onChange={() => ({})}
              className="outlet-info-radio"
              value="GOOGLE"
              label={content.manager_app_settings_outlet_info_connect_google}
              onValueSelect={() => setOption("GOOGLE")}
              selectedValue={option}
            />
            <div
              className={`outlet-info-content ${
                option === "GOOGLE" ? "outlet-info-content-active" : ""
              }`}
            >
              <PlacesInput
                error={
                  option === "GOOGLE" && !(place?.structured_formatting.main_text || defaultPlace)
                    ? content.settings_error_required
                    : false
                }
                value={
                  place?.structured_formatting.main_text ??
                  (defaultPlace ? locationData.googleBusinessName || outlet.name : undefined)
                }
                label={content.manager_app_settings_outlet_info_google_label}
                placeholder={content.manager_app_settings_outlet_info_google_my}
                selectedPlace={place ?? null}
                onPlaceSelected={newPlace => {
                  setPlace(newPlace ?? undefined);
                  setDefaultPlace(undefined);
                }}
                onChangeText={text => {
                  if (text && text !== outlet.name) {
                    setDefaultPlace(undefined);
                  }
                }}
              />
              {option === "GOOGLE" && (place?.structured_formatting.main_text || defaultPlace) && (
                <OutletOpeningHours location={locationData} readOnly />
              )}
            </div>
          </div>
          <Divider orientation="vertical" className="outlet-info-divider" />
          <div className="outlet-info-option outlet-info-option-manual">
            <Radio
              onChange={() => ({})}
              className="outlet-info-radio"
              value="MANUAL"
              label={content.manager_app_settings_outlet_info_manual_address}
              onValueSelect={() => setOption("MANUAL")}
              selectedValue={option}
            />
            <div
              className={`outlet-info-content ${
                option === "MANUAL" ? "outlet-info-content-active" : ""
              }`}
            >
              <ErrorTextInput
                error={incomplete && option === "MANUAL" ? content.settings_error_required : false}
                value={manualPlace.address}
                label={content.manager_app_settings_outlet_info_google_manual}
                onChange={event => setManualPlace({ ...manualPlace, address: event.target.value })}
              />
              <OutletOpeningHours location={locationDataTemp} onChangeOpeningHours={updateHours} />
            </div>
          </div>
        </div>
      )}
    </ExpandablePanel>
  );
};

export default WithLocationData;
