import { call, put, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import {
  GET_FAULTY_SENSORS,
  IGetFaultySensors,
  IRequestPressureChambers,
  REQUEST_PRESSURE_CHAMBERS
} from "../store/installation/types";
import {
  getFaultySensorsError,
  getFaultySensorsSuccess,
  requestPressureChambersError,
  requestPressureChambersSuccess
} from "../store/installation/actions";

export function* requestPressureChambersSaga(
  jCoreService: IJCoreService,
  action: IRequestPressureChambers
) {
  try {
    const result: any = yield call(
      // @ts-ignore
      [jCoreService, jCoreService.requestAllPressureChambers],
      action.payload
    );

    const data = {};

    result.data.forEach((outlet: any) => {
      // @ts-ignore
      data[outlet.id] = {
        ...(outlet.installations[0] || {}),
        outletId: outlet.id,
        outletName: outlet.name
      };
    });

    yield put(requestPressureChambersSuccess(data));
  } catch {
    yield put(requestPressureChambersError());
  }
}

export function* getFaultySensors(jCoreService: IJCoreService, action: IGetFaultySensors) {
  try {
    const result: any = yield call(
      // @ts-ignore
      [jCoreService, jCoreService.getFaultySensors],
      action.payload.locationIds,
      action.payload.startTimestamp,
      action.payload.endTimestamp
    );

    const data = result?.data.faultySensors ?? {};
    yield put(getFaultySensorsSuccess(data));
  } catch {
    yield put(getFaultySensorsError());
  }
}

export function* installationWatcher(context: IAppContext) {
  yield takeLatest(REQUEST_PRESSURE_CHAMBERS, requestPressureChambersSaga, context.jCoreService);
  yield takeLatest(GET_FAULTY_SENSORS, getFaultySensors, context.jCoreService);
}

export default installationWatcher;
