import React, { useCallback, useContext } from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";
import { Divider } from "@ddm-design-system/divider";
import { Icon } from "@ddm-design-system/icon";
import { InspectorTooltip } from "@ddm-design-system/tooltip";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";
import { LinkButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import "./pressurechamber-badges.scss";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../store/modals/actions";
import { AnalyticsContext } from "../../services/analytics";

interface IProps {
  pressureChamber: IPressureChamber;
}

export const PressureChamberBadges: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppInstallation: productContent, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);

  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Installation");
    dispatch(showFaultySensorModalAction([pressureChamber.position]));
  }, [dispatch, pressureChamber, analytics]);

  return (
    <div className="pressurechamber-badges">
      {pressureChamber.tags?.map(tag => {
        const iconClassNames = classnames(
          "pressurechamber-badge",
          tag.type === "connection" && "pressurechamber-badge--conection",
          pressureChamber.offline &&
            tag.type !== "connection" &&
            !(tag.type === "sensor" && tag.level === "danger")
            ? "disabled"
            : tag.level
        );

        return (
          <InspectorTooltip
            key={tag.type}
            title={
              <span className="tooltip-title">
                <Icon
                  className={classnames(iconClassNames, "tooltip-title-icon")}
                  name={tag.icon}
                  size={16}
                />
                <DescriptionMedium>
                  {productContent[`manager_app_${tag.type}_title`]}
                </DescriptionMedium>
              </span>
            }
            renderHeader={() => <Icon className={iconClassNames} name={tag.icon} size={24} />}
            zIndex={90}
          >
            <>
              <Divider className="tooltip-divider" />
              <Description>
                <ReactMarkdown
                  className="pressurechamber-badge-description"
                  source={(productContent[`main_status_details_${tag.desc}`] || "")
                    .replace("%days%", tag.replaceValue)
                    .replace("%days2%", tag.replaceValue2)}
                />
              </Description>
              {tag.type === "sensor" && pressureChamber.isFaulty && (
                <div className="tooltip-faulty">
                  <Divider className="tooltip-divider" />
                  <div className="tooltip-sensor-warning">
                    <LinkButton onClick={showFaultySensorModal}>{common.know_more}</LinkButton>
                  </div>
                </div>
              )}
            </>
          </InspectorTooltip>
        );
      })}
    </div>
  );
};

export default PressureChamberBadges;
