import React, { useEffect, useMemo, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Description } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import useContent from "../../hooks/useContent";
import {
  getFaultySensors as getFaultySelector,
  makeGetFaultySensorsPosition
} from "../../store/installation/selectors";
import { getChosenOutletId, getTimeFrame } from "../../store/filter/selectors";
import { getFaultySensors } from "../../store/installation/actions";
import styles from "./sales.module.scss";
import { IAppState } from "../../store";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../store/modals/actions";
import { setStartEndTimeRange } from "../../lib/Time/TimeFunctions";
import { AnalyticsContext } from "../../services/analytics";

export const FaultySensorBanner = () => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const { managerAppSales: content } = useContent();
  const faultySensors = useSelector(getFaultySelector);
  const timeFrame = useSelector(getTimeFrame);
  const outletId = useSelector(getChosenOutletId);

  useEffect(() => {
    const { start, end } = setStartEndTimeRange(
      timeFrame.type ? timeFrame.type : "WEEK",
      timeFrame.from,
      timeFrame.to
    );
    dispatch(getFaultySensors(outletId.length ? [outletId] : [], +start, +end));
  }, [timeFrame, outletId, dispatch]);

  const selectFaultySensorsPosition = useMemo(makeGetFaultySensorsPosition, []);
  const faultySensorsPosition = useSelector((state: IAppState) =>
    selectFaultySensorsPosition(state, Object.values(faultySensors).flat())
  );

  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Sales Banner");
    dispatch(showFaultySensorModalAction(faultySensorsPosition));
  }, [dispatch, faultySensorsPosition, analytics]);

  return faultySensors && Object.keys(faultySensors).length ? (
    <span className={styles.salesFaultySensor} onClick={showFaultySensorModal}>
      <Description>{content.manager_app_sales_faulty_error_message}</Description>
      <Icon
        className={styles.faultyIcon}
        name="warning"
        fill="var(--color-alert-alert100"
        size={20}
      />
    </span>
  ) : (
    <></>
  );
};
