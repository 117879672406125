import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Body, Description } from "@ddm-design-system/typography";
import { AutocompleteType, IPlaceSuggestion, PlacesContext } from "../../services/places";
import useDebounce from "../../hooks/useDebounce";
import Select from "../common/select/Select";
import "./places-input.scss";

interface IProps extends React.ComponentProps<typeof ErrorTextInput> {
  selectedPlace: IPlaceSuggestion | null;
  resultType?: AutocompleteType;
  onPlaceSelected: (place: IPlaceSuggestion | null) => void;
  placesNear?: boolean;
  onChangeText?: (text: string) => void;
}

interface IItem {
  id: string;
  text: string;
  secondaryText: string;
}
const PlaceItem: React.FC<{ item: IItem }> = ({ item }) => (
  <div className="places-input-item">
    <Body className="name">{item.text}</Body>
    <Description className="address">{item.secondaryText}</Description>
  </div>
);

const StyledSelect = Select.extendStyles(theme => ({
  item: {
    boxSizing: "border-box",
    height: 52,
    borderBottom: `1px solid ${theme.colors.black}`,
    "@selectors": {
      "&:last-child": {
        borderBottom: "none"
      }
    }
  }
}));

const PlacesInput: React.FC<IProps> = ({
  resultType = "establishment",
  onPlaceSelected,
  selectedPlace,
  value,
  label,
  placeholder,
  ...props
}) => {
  const Places = useContext(PlacesContext);
  const [text, setText] = useState(value?.toString() ?? "");
  const debouncedText = useDebounce(text, 500);
  const [suggestions, setSuggestions] = useState<IPlaceSuggestion[]>([]);
  useEffect(() => {
    setText(value?.toString() ?? "");
  }, [value]);

  const getAutocompletePredictions = useCallback(
    async (query: string, type: AutocompleteType) => {
      const result = await Places.getAutocompletePredictions(query, type);
      setSuggestions(result);
    },
    [Places]
  );

  useEffect(() => {
    if (debouncedText?.length === 0) {
      setSuggestions([]);
    } else {
      getAutocompletePredictions(debouncedText, resultType);
    }
  }, [debouncedText, resultType, getAutocompletePredictions]);

  const options = useMemo(
    () =>
      suggestions
        .reduce(
          (unique, item) =>
            unique.find(i => i.place_id === item.place_id) ? unique : [...unique, item],
          new Array<IPlaceSuggestion>()
        )
        .map(s => ({
          id: s.place_id,
          text: s.structured_formatting.main_text,
          secondaryText: s.structured_formatting.secondary_text
        })),
    [suggestions]
  );

  return (
    <StyledSelect
      {...props}
      items={options}
      onItemSelected={o => {
        onPlaceSelected(suggestions.find(s => s.place_id === o?.id) ?? null);
      }}
      selectedItem={value ? { id: value.toString(), text: value.toString() } : undefined}
      onTextChange={newText => {
        setText(newText ?? "");
        props.onChangeText?.(newText ?? "");
      }}
      onFilter={() => true}
      inputProps={{ label }}
      renderItem={item => <PlaceItem item={item as IItem} />}
    />
  );
};

export default PlacesInput;
