import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Moment } from "moment";
import * as d3 from "d3";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import Modal, { ModalBody, ModalHeader, ModalFooter } from "@ddm-design-system/modal";
import DatesPicker from "../common/dates_picker/DatesPicker";
import { ITimeFrame, ITimeRange } from "../../lib/Time/types";
import { day8, setStartEndTimeRange } from "../../lib/Time/TimeFunctions";
import { IBeer } from "../../store/beers/types";
import { setCompareOutlet, setTimeFrame } from "../../store/filter/actions";
import Time from "../../lib/Time/Time";
import { IAppState } from "../../store";
import useIsMobile from "../../hooks/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet";
import StyleConstants from "../../constants";
import useScroll from "../../hooks/useScroll";
import useContent from "../../hooks/useContent";
import { IOutlet } from "../../store/outlet/types";
import SalesFilterMobile from "./SalesFilterMobile";
import SalesFilterDesktop from "./SalesFilterDesktop";
import { ICheckbox, PERFORMANCE_TYPE } from "../../store/filter/types";
import {
  getChosenOutlet,
  getCurrentTarget,
  getFilterBeers,
  getPerformanceType,
  getTimeFrame,
  getOutletCompare
} from "../../store/filter/selectors";
import { getFilteredBeverages } from "../../store/beers/reducer";
import { getAllOutlets } from "../../store/outlet/selectors";
import "./sales_filter.scss";

interface IProps {
  className?: string;
}

export interface ITimeFrameCheckbox extends ICheckbox {
  timeframe: ITimeFrame;
  preventClose?: boolean;
}

export interface ISelectedBeer {
  id: string;
  text: string;
  selected: boolean;
}

const timeFrameChoices: ITimeFrameCheckbox[] = [
  {
    id: "day",
    text: "unit_today",
    timeframe: {
      from: setStartEndTimeRange("DAY").start,
      to: setStartEndTimeRange("DAY").end,
      type: "DAY"
    }
  },
  {
    id: "yesterday",
    text: "unit_yesterday",
    timeframe: {
      from: setStartEndTimeRange("YESTERDAY").start,
      to: setStartEndTimeRange("YESTERDAY").end,
      type: "YESTERDAY"
    }
  },
  {
    id: "week",
    text: "unit_last_7_days",
    timeframe: {
      from: setStartEndTimeRange("WEEK").start,
      to: setStartEndTimeRange("WEEK").end,
      type: "WEEK"
    }
  },
  {
    id: "month",
    text: "unit_last_4_weeks",
    timeframe: {
      from: setStartEndTimeRange("4WEEKS").start,
      to: setStartEndTimeRange("4WEEKS").end,
      type: "4WEEKS"
    }
  },
  {
    id: "custom",
    text: "unit_custom",
    timeframe: {
      from: new Time(),
      to: new Time(),
      type: "CUSTOM"
    },
    preventClose: true
  }
];

const SalesFilter: React.FC<IProps> = ({ className }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);
  const isTablet = useIsTablet();
  const { managerAppSales: contentSales, managerAppCommon: contentCommon, units } = useContent();
  const { timeStrings = {} } = units;
  const hasTargetActive = useSelector(getCurrentTarget);

  const [tab, setTab] = useState(0);

  // scrolling variables ======
  const scrollObject = useScroll();

  // timeframe filter =======
  const [visibleDatesPicker, setVisibleDatesPicker] = useState(false);
  const [customTimeFrame, setCustomTimeFrame] = useState<{
    from: Time;
    to?: Time | null;
    type?: ITimeRange;
  }>({
    from: new Time()
  });
  const translatedTimeFrameChoices = useMemo(
    () => (timeStrings ? timeFrameChoices.map(t => ({ ...t, text: timeStrings[t.text] })) : []),
    [timeStrings]
  );
  const [timeFrameCheckboxes, setTimeFrameCheckboxes] = useState(translatedTimeFrameChoices);

  useEffect(() => {
    setTimeFrameCheckboxes(translatedTimeFrameChoices);
  }, [translatedTimeFrameChoices]);

  const chosenTimeFrame = useSelector(getTimeFrame);
  const selectedTimeFrame = useMemo(() => {
    if (chosenTimeFrame) {
      return timeFrameCheckboxes.find(t => t.timeframe.type === chosenTimeFrame.type);
    }
    return timeFrameCheckboxes[0];
  }, [chosenTimeFrame, timeFrameCheckboxes]);

  function onCustomDatesChange(from: Moment | null, to: Moment | null) {
    if (!from) {
      return;
    }

    const start8 = day8(new Time(from.toISOString()).set({ hour: 0 })).add({
      day: 1
    });
    const end8 = to
      ? day8(new Time(to.toISOString()).set({ hour: 0 }))
          .set({ hour: 5 }) // workaround for dst changes
          .add({ day: 2 })
          .set({
            hour: 7,
            minute: 59,
            second: 59,
            millisecond: 0
          })
      : null;

    setCustomTimeFrame({
      from: start8,
      to: end8 || null,
      type: "CUSTOM"
    });
  }

  // beer filter =======
  const filterBeers = useSelector(getFilterBeers);
  const filteredBeverages = useSelector(getFilteredBeverages);
  const chosenBeerList = useMemo(
    () =>
      filteredBeverages.map((beer: IBeer) => ({
        id: beer.id,
        text: beer.name,
        selected: filterBeers.includes(beer.id)
      })),
    [filterBeers, filteredBeverages]
  );
  const [beerList, setBeerList] = useState<ISelectedBeer[]>(chosenBeerList);
  useEffect(() => setBeerList(chosenBeerList), [chosenBeerList]);
  const [selectedBeer, setSelectedBeer] = useState<any>(beerList.filter(s => s.selected));
  const allBeerSelected = useMemo(() => beerList.every(beer => beer.selected), [beerList]);

  useEffect(() => {
    if (allBeerSelected) {
      setSelectedBeer([]);
      return;
    }
    setSelectedBeer(beerList.filter(s => s.selected));
  }, [allBeerSelected, beerList]);

  const [isAllBeerButtonSelected, setAllBeerButtonSelected] = useState(allBeerSelected);

  // outlet filter ======
  const chosenPerformanceType = useSelector(getPerformanceType);
  const chosenCompareOutlet = useSelector(getOutletCompare);
  const primaryOutlet = useSelector<IAppState, IOutlet[] | any>(getChosenOutlet);
  const outlets = useSelector<IAppState, IOutlet[] | any>(getAllOutlets);
  const [outletList, setOutletList] = useState(outlets);

  useEffect(() => {
    if (!primaryOutlet && tab === 2) {
      setTab(0);
    }
  }, [tab, primaryOutlet]);

  useEffect(() => {
    const average = {
      id: PERFORMANCE_TYPE.AVG,
      text: contentSales.manager_app_similar_time
    };

    const target = {
      id: PERFORMANCE_TYPE.TARGET,
      text: contentSales.manager_app_target,
      disabled:
        !hasTargetActive ||
        !primaryOutlet ||
        !(selectedBeer.length === 0 || selectedBeer.length === beerList.length)
    };

    const similar = {
      id: PERFORMANCE_TYPE.SIMILAR,
      text: contentSales.manager_app_similar_outlets
    };

    const nearby = {
      id: PERFORMANCE_TYPE.NEAR,
      text: contentSales.manager_app_nearby_outlets
    };

    let itemListArray = [average, target, similar, nearby];
    itemListArray = itemListArray.concat(
      outlets
        .filter((outlet: IOutlet) => !primaryOutlet || outlet.id !== primaryOutlet.id)
        .map((outlet: IOutlet) => ({
          id: outlet.id,
          text: outlet.name,
          disabled:
            !primaryOutlet ||
            !(selectedBeer.length === 0 || selectedBeer.length === beerList.length)
        }))
    );

    setOutletList(itemListArray);
  }, [hasTargetActive, outlets, contentSales, primaryOutlet, beerList, selectedBeer]);

  useEffect(() => {
    // prevent equal comparison
    if (primaryOutlet && chosenCompareOutlet && primaryOutlet.id === chosenCompareOutlet.id) {
      dispatch(setCompareOutlet(null));
    }
  }, [primaryOutlet, chosenCompareOutlet, dispatch]);

  const timeDropdown = React.useRef(null);

  useEffect(() => {
    if (!allBeerSelected && beerList.filter(beer => beer.selected).length === 0) {
      setBeerList([
        ...beerList.map(beerItem => ({
          ...beerItem,
          selected: true
        }))
      ]);
      setAllBeerButtonSelected(true);
    }
  }, [allBeerSelected, beerList]);

  const timeFrameDescription = useMemo(
    () =>
      isMobile || isTablet
        ? ((selectedTimeFrame && selectedTimeFrame.id === "custom") || !selectedTimeFrame) &&
          !!customTimeFrame
          ? customTimeFrame.to
            ? `${d3.timeFormat("%d %b")(customTimeFrame.from.toJSDate())} - ${d3.timeFormat(
                "%d %b"
              )(customTimeFrame.to.toJSDate())}`
            : `${d3.timeFormat("%d %b")(customTimeFrame.from.toJSDate())}`
          : selectedTimeFrame && selectedTimeFrame.text
        : "",
    [isMobile, isTablet, selectedTimeFrame, customTimeFrame]
  );

  const selectedBeerDescription = useMemo(
    () =>
      isMobile || isTablet
        ? allBeerSelected && isAllBeerButtonSelected
          ? contentCommon.common_all
          : `${beerList.filter(beer => beer.selected).length} / ${beerList.length}`
        : "",
    [
      isMobile,
      isTablet,
      allBeerSelected,
      isAllBeerButtonSelected,
      contentCommon.common_all,
      beerList
    ]
  );

  const handleDateCancel = () => {
    setVisibleDatesPicker(false);
    const dd: any = timeDropdown && timeDropdown.current;
    dd?.hide();
  };

  const handleDateConfirm = () => {
    const custom: ITimeFrame = customTimeFrame?.to
      ? { ...customTimeFrame, to: customTimeFrame.to }
      : {
          ...customTimeFrame,
          to: day8(customTimeFrame.from)
            .clone()
            .set({
              hour: 7,
              minute: 59,
              second: 59,
              millisecond: 0
            })
            .add({ day: 1 })
        };

    if (custom.to.getTime() <= custom.from.getTime()) {
      custom.to = custom.to.clone().add({ day: 1 });
    }

    dispatch(setTimeFrame(custom));
    setVisibleDatesPicker(false);
    const dd: any = timeDropdown && timeDropdown.current;
    dd?.hide();
  };

  return timeStrings ? (
    <div
      className={classnames(
        "sales-filter-container",
        scrollObject.scroll > (isTablet ? 55 : 63) && "fixed",
        `scrolling-${scrollObject.scrollDirection}`,
        { mobile: isMobile || isTablet }
      )}
    >
      <div className={classnames("sales-filter", className)}>
        {isMobile || isTablet ? (
          <SalesFilterMobile
            tab={tab}
            timeFrameCheckboxes={timeFrameCheckboxes}
            setTab={setTab}
            chosenPerformanceType={chosenPerformanceType}
            chosenCompareOutlet={chosenCompareOutlet}
            selectedBeerDescription={selectedBeerDescription}
            setVisibleDatesPicker={setVisibleDatesPicker}
            beerList={beerList}
            setBeerList={setBeerList}
            selectedTimeFrame={selectedTimeFrame}
            isAllBeerButtonSelected={isAllBeerButtonSelected}
            setAllBeerButtonSelected={setAllBeerButtonSelected}
            outletList={outletList}
            outlets={outlets}
            timeFrameDescription={timeFrameDescription}
          />
        ) : (
          <SalesFilterDesktop
            timeDropdown={timeDropdown}
            chosenPerformanceType={chosenPerformanceType}
            chosenCompareOutlet={chosenCompareOutlet}
            outletList={outletList}
            outlets={outlets}
            selectedTimeFrame={selectedTimeFrame}
            beerList={beerList}
            selectedBeer={selectedBeer}
            setSelectedBeer={setSelectedBeer}
            timeFrameCheckboxes={timeFrameCheckboxes}
            setVisibleDatesPicker={setVisibleDatesPicker}
          />
        )}
        <Modal className="timeframe-modal" isOpen={visibleDatesPicker}>
          <ModalHeader
            title={contentSales.manager_app_time_range}
            onClose={() => setVisibleDatesPicker(false)}
            showCloseButton={false}
          />
          <ModalBody>
            {visibleDatesPicker && (
              <DatesPicker
                startDate={customTimeFrame.from ? customTimeFrame.from : null}
                endDate={customTimeFrame.to ? day8(customTimeFrame.to) : null}
                onDatesChange={({ startDate, endDate }) => onCustomDatesChange(startDate, endDate)}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <SecondaryButton onClick={handleDateCancel}>
              {contentCommon.common_cancel}
            </SecondaryButton>
            <PrimaryButton onClick={handleDateConfirm}>
              {contentCommon.common_confirm}
            </PrimaryButton>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SalesFilter;
