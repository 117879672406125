import React from "react";
import classnames from "classnames";
import { Body, BodyHighlight } from "@ddm-design-system/typography";
import "./product-card.scss";

interface IProductBeverageDataItem {
  isVertical?: boolean;
  label: string;
}

const ProductBeverageDataItem: React.FC<IProductBeverageDataItem> = ({
  children,
  isVertical = false,
  label
}) => (
  <div
    className={classnames(
      "product-beverage-data-item",
      isVertical && "product-beverage-data-item--vertical"
    )}
  >
    <Body className="product-beverage-data-label">{label}</Body>
    <BodyHighlight>{children}</BodyHighlight>
  </div>
);

export default ProductBeverageDataItem;
