import React, { useState } from "react";
import { ButtonText } from "@ddm-design-system/typography";
import "./language_picker.scss";
import { Flipped, Flipper } from "react-flip-toolkit";
import "flag-icon-css/css/flag-icon.css";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { IAppState } from "../../../store";
import { setCurrentLanguage } from "../../../store/content/actions";
import useContent from "../../../hooks/useContent";

interface IProps {
  direction: string;
  extended?: boolean;
  onLanguagePicked?: () => void;
}

export const LanguagePicker: React.FC<IProps> = ({ direction, extended, onLanguagePicked }) => {
  const { languageInfo: languages } = useContent();
  const currentLanguage = useSelector((state: IAppState) => getCurrentLanguage(state));
  const dispatch = useDispatch();
  const [languagePicker, setLanguagePicker] = useState(false);

  function setCurrentLanguageHelper(lang: any) {
    setLanguagePicker(false);
    dispatch(setCurrentLanguage(lang));
    if (onLanguagePicked) {
      onLanguagePicked();
    }
  }

  let currentLanguageCountry = null;
  if (currentLanguage) {
    const s = languages && languages.filter((f: any) => f.code === currentLanguage)[0];
    if (s) {
      currentLanguageCountry = s.country;
    }
  }

  return extended ? (
    <div className="flag-container extended">
      {languages
        .sort((a, b) => (a.code === "en-GB" ? -1 : 1))
        .map((lang: any) => (
          <div
            onClick={() => setCurrentLanguageHelper(lang)}
            key={lang.code}
            className={classnames("flag-element-container", {
              "flag-selected": lang.code === currentLanguage
            })}
          >
            <div
              className={classnames(
                "flag",
                "flag-icon",
                "flag-icon-squared",
                `flag-icon-${lang.country.toLowerCase()}`
              )}
            />
            <ButtonText className="language-country">{lang.name}</ButtonText>
          </div>
        ))}
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
      <div className="flag-element-container dummy" />
    </div>
  ) : (
    <div className="flag-container">
      <Flipper
        className={classnames("flag-choices", {
          hidden: !languagePicker,
          right: direction === "right"
        })}
        flipKey={languagePicker}
        spring={{ stiffness: 309, damping: 40 }}
      >
        {languagePicker
          ? languages &&
            languages
              .filter((f: any) => f.code !== currentLanguage)
              .map((lang: any) => (
                <Flipped key={lang.code} flipId={`lang-${lang.code}`}>
                  <div
                    onClick={() => setCurrentLanguageHelper(lang)}
                    className={classnames(
                      "flag",
                      "flag-icon",
                      "flag-icon-squared",
                      `flag-icon-${lang.country.toLowerCase()}`
                    )}
                  />
                </Flipped>
              ))
          : languages &&
            languages.map((lang: any) => (
              <Flipped key={lang.code} flipId={`lang-${lang.code}`}>
                <div
                  className={classnames(
                    "hidden-flag",
                    "flag",
                    "flag-icon",
                    "flag-icon-squared",
                    `flag-icon-${lang.country.toLowerCase()}`
                  )}
                />
              </Flipped>
            ))}
      </Flipper>
      {currentLanguageCountry && (
        <div
          onClick={() => setLanguagePicker(!languagePicker)}
          className={classnames(
            "flag",
            "flag-icon",
            "flag-icon-squared",
            `flag-icon-${currentLanguageCountry.toLowerCase()}`
          )}
        />
      )}
    </div>
  );
};

export default LanguagePicker;
