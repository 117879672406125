import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ModalTooltip } from "@ddm-design-system/modal";
import * as d3 from "d3";
import { Description } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import InfoModalItem from "../common/info_modal_item/InfoModalItem";
import { IAppState } from "../../store";
import { PERFORMANCE_TYPE } from "../../store/filter/types";
import Time from "../../lib/Time/Time";
import { IOutlet } from "../../store/outlet/types";
import { getFilterOutlets, getPerformanceType } from "../../store/filter/selectors";
import { AnalyticsContext } from "../../services/analytics";

export const VolumeOverTimeInfo = () => {
  const { managerAppSales: content, managerAppCommon: common } = useContent();
  const analytics = useContext(AnalyticsContext);
  const performanceType = useSelector<IAppState>(getPerformanceType);
  const outlets = useSelector<IAppState, IOutlet[]>(getFilterOutlets);

  const weekday = d3.timeFormat("%A")(new Time().toJSDate());

  return (
    <ModalTooltip
      title={content.manager_app_volume_over_time}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Volume over time")}
    >
      <InfoModalItem title={common.your_outlet} iconName="rectangle">
        {content.volume_over_time_description}
      </InfoModalItem>

      {performanceType === PERFORMANCE_TYPE.AVG && (
        <InfoModalItem
          title={common.vs_similar_time}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {content.volume_over_time_vs_similar_time_desc}
        </InfoModalItem>
      )}

      {performanceType === PERFORMANCE_TYPE.TARGET && (
        <InfoModalItem
          title={common.vs_target}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {(content.volume_over_time_vs_target_desc || "").replace("%WEEKDAY%", weekday)}
        </InfoModalItem>
      )}

      {performanceType === PERFORMANCE_TYPE.OUTLET && (
        <InfoModalItem
          title={outlets[0]?.name || ""}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {(content.volume_over_time_vs_other_outlet_desc || "").replace(
            "%OUTLET%",
            outlets[0]?.name || ""
          )}
        </InfoModalItem>
      )}
    </ModalTooltip>
  );
};

export const SpecialityMixInfo = () => {
  const { managerAppSales: content, managerAppCommon: common } = useContent();
  const analytics = useContext(AnalyticsContext);
  const performanceType = useSelector<IAppState>(getPerformanceType);
  const outlets = useSelector<IAppState, IOutlet[]>(getFilterOutlets);

  return (
    <ModalTooltip
      title={content.manager_app_speciality_mix}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Speciality Mix")}
    >
      <InfoModalItem title={common.your_outlet} iconName="rectangle">
        {content.speciality_mix_your_outlet_info_desc}
      </InfoModalItem>

      {performanceType === PERFORMANCE_TYPE.AVG && (
        <InfoModalItem
          title={common.vs_similar_time}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {content.speciality_mix_vs_similar_time_desc}
        </InfoModalItem>
      )}

      {performanceType === PERFORMANCE_TYPE.TARGET && (
        <InfoModalItem
          title={common.vs_target}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {content.speciality_mix_vs_target_desc}
        </InfoModalItem>
      )}

      {performanceType === PERFORMANCE_TYPE.OUTLET && (
        <InfoModalItem
          title={outlets[0]?.name || ""}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {(content.speciality_mix_vs_other_outlet_desc || "").replace(
            "%OUTLET%",
            outlets[0]?.name || ""
          )}
        </InfoModalItem>
      )}
    </ModalTooltip>
  );
};

export const VolumePerBeerInfo = () => {
  const analytics = useContext(AnalyticsContext);
  const { managerAppSales: content } = useContent();

  return (
    <ModalTooltip
      title={content.manager_app_volume_per_beer}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Volume per beer")}
    >
      <Description>{content.volume_per_beer_info_desc}</Description>
    </ModalTooltip>
  );
};

export const AveragePerformancePerWeekdayInfo = () => {
  const { managerAppSales: content, managerAppCommon: common } = useContent();
  const analytics = useContext(AnalyticsContext);
  const performanceType = useSelector<IAppState>(getPerformanceType);
  const outlets = useSelector<IAppState, IOutlet[]>(getFilterOutlets);

  return (
    <ModalTooltip
      title={content.manager_app_average_per_week_day}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Average week day")}
    >
      {performanceType === PERFORMANCE_TYPE.AVG && (
        <Description>{content.average_performance_per_week_day_info_desc}</Description>
      )}

      {performanceType !== PERFORMANCE_TYPE.AVG && (
        <InfoModalItem title={common.your_outlet} iconName="rectangle">
          {content.average_performance_per_week_day_your_outlet_desc}
        </InfoModalItem>
      )}

      {performanceType === PERFORMANCE_TYPE.TARGET && (
        <InfoModalItem
          title={common.vs_target}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {content.average_performance_per_week_day_vs_target_desc}
        </InfoModalItem>
      )}

      {performanceType === PERFORMANCE_TYPE.OUTLET && (
        <InfoModalItem
          title={outlets[0]?.name || ""}
          iconName="vs"
          fill="var(--color-warning-warning100)"
        >
          {(content.average_performance_per_week_day_vs_other_outlet_desc || "").replace(
            "%OUTLET%",
            outlets[0]?.name || ""
          )}
        </InfoModalItem>
      )}
    </ModalTooltip>
  );
};

export const AveragePerformancePerHourInfo = () => {
  const { managerAppSales: content } = useContent();
  const analytics = useContext(AnalyticsContext);

  return (
    <ModalTooltip
      title={content.manager_app_average_per_hour_day}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Average hour")}
    >
      <Description>{content.average_performance_per_hour_info_desc}</Description>
    </ModalTooltip>
  );
};
