import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deburr } from "lodash";
import { RouteComponentProps, withRouter } from "react-router";
import * as typeformEmbed from "@typeform/embed";
import { Icon } from "@ddm-design-system/icon";
import { Body, PageTitle, SectionTitle, Subtitle } from "@ddm-design-system/typography";
import { IPopoverRef, Popover } from "@ddm-design-system/popover";
import { SearchInput } from "@ddm-design-system/textinput";
import { Tab, TabGroup } from "@ddm-design-system/tab";
import { Divider } from "@ddm-design-system/divider";
import { useInfiniteScrollList } from "@ddm-design-system/hooks";
import { setFilterOutlets } from "../../../store/filter/actions";
import { IOutlet } from "../../../store/outlet/types";
import useContent from "../../../hooks/useContent";
import {
  setOutletFavourite,
  setOutletPickerTab,
  setOutletViewed,
  setQuestionnaireSubmitted
} from "../../../store/outlet/actions";
import {
  DEFAULT_LANG,
  DEFAULT_TYPEFORM_LINK,
  ENVIRONMENT,
  ENVIRONMENT_TYPE,
  Key,
  typeformKey
} from "../../../constants";
import useIsMobile from "../../../hooks/useIsMobile";
import { getRank } from "../../../store/users/types";
import {
  getAllOutlets,
  getCurrentOutlet,
  getCurrentOutletPickerTab
} from "../../../store/outlet/selectors";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { getChosenOutlet } from "../../../store/filter/selectors";
import { getUser } from "../../../store/profile/reducer";
import Routes from "../../../routes";
import OutletPickerItem from "./OutletPickerItem";
import "./outlet-picker.scss";

const handlePressUpArrow = () => {
  const activeEl = document.activeElement;
  const prevEl = document.activeElement?.previousElementSibling as HTMLDivElement;

  if (activeEl?.className.includes("outlet-item-container")) {
    if (prevEl && !prevEl.className.includes("outlet-item-container")) {
      (prevEl.previousElementSibling as HTMLDivElement).focus();
    } else if (prevEl) {
      prevEl.focus();
    }
  }
};

const handlePressDownArrow = () => {
  const activeEl = document.activeElement;
  const nextEl = document.activeElement?.nextElementSibling as HTMLDivElement;

  if (!activeEl?.className.includes("outlet-item-container")) {
    (document.getElementsByClassName("outlet-item-container")[0] as HTMLDivElement).focus();
  } else if (nextEl && !nextEl.className.includes("outlet-item-container")) {
    (nextEl.nextElementSibling as HTMLDivElement).focus();
  } else if (nextEl) {
    nextEl.focus();
  }
};

export const OutletPicker: React.FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { managerAppCommon: content } = useContent();
  const isMobile = useIsMobile();
  const outlets = useSelector(getAllOutlets);
  const currentOutlet = useSelector(getCurrentOutlet);
  const selectedOutlet = useSelector(getChosenOutlet);
  const currentOutletPickerTab = useSelector(getCurrentOutletPickerTab);
  const popoverRef = useRef<IPopoverRef>();
  const popoverBodyRef = useRef<HTMLDivElement>(null);
  const currentLanguage = useSelector(getCurrentLanguage);
  const me = useSelector(getUser);
  const [searchText, setSearchText] = useState("");
  const [questionnaireShowing, setQuestionnaireShowing] = useState("");
  const [initialScrollLimit, setInitialScrollLimit] = useState(10);
  const [popoverOpening, setPopoverOpening] = useState(false);
  const [visible, setVisible] = useState(false);
  const [shouldScrollToOutlet, setShouldScrollToOutlet] = useState(false);
  const outletRef = useRef<HTMLDivElement>(null);

  const Title = isMobile ? Subtitle : PageTitle;

  const setOutletAux = useCallback(
    (outlet: IOutlet[]) => {
      // filters the outlet
      dispatch(setFilterOutlets(outlet));

      // opens questionnaire if the outlet meets the requirements
      // only opens in production
      if (
        ENVIRONMENT === ENVIRONMENT_TYPE.PRODUCTION &&
        outlet &&
        outlet[0] &&
        !outlet[0].questionnaireSubmitted &&
        !questionnaireShowing &&
        outlets.length <= 1 &&
        me &&
        getRank(me, outlet[0].id) === "OWNER"
      ) {
        const popup1 = typeformEmbed.makePopup(
          `${DEFAULT_TYPEFORM_LINK}${typeformKey[currentLanguage || DEFAULT_LANG]}?location_id=${
            outlet[0].id
          }&location_name=${outlet[0].name}`,
          {
            mode: "popup",
            hideHeaders: true,
            hideFooter: true,
            onSubmit: () => {
              dispatch(setQuestionnaireSubmitted(outlet[0].id));
              popup1.close();
              setQuestionnaireShowing("");
            }
          }
        );
        popup1.open();
        setQuestionnaireShowing(outlet[0].id);
      }

      // sets outlet as viewed (removes new)
      if (outlet?.[0]?.newAssociation) {
        dispatch(setOutletViewed(outlet[0].id));
      }
    },
    [currentLanguage, dispatch, me, outlets.length, questionnaireShowing]
  );

  const closePopover = useCallback(() => {
    popoverRef?.current?.hide();
  }, []);

  const handleChooseOutlet = useCallback(
    (outlet?: IOutlet) => {
      setOutletAux(!outlet ? [] : [outlet]);
      closePopover();

      history.push(location.pathname, { outletId: outlet ? outlet.id : "all" });
    },
    [closePopover, history, location.pathname, setOutletAux]
  );

  const handleToggleFavourite = useCallback(
    (outletId: string, favourite: boolean) => {
      const outletIndex = outlets.findIndex(outlet => outlet.id === outletId);

      // sets the initial scroll limit because toggling favourite will cause a refresh on the list
      setInitialScrollLimit(
        Math.max(initialScrollLimit, outletIndex === -1 || outletIndex < 10 ? 10 : outletIndex + 10)
      );

      dispatch(setOutletFavourite(outletId, favourite));
    },
    [outlets, dispatch, initialScrollLimit]
  );

  const handleSelectAllTab = useCallback(
    tabIndex => {
      dispatch(setOutletPickerTab(tabIndex));
      setShouldScrollToOutlet(true);
    },
    [dispatch]
  );

  const handleKeyDown = useCallback(e => {
    if (e.keyCode === Key.ARROW_UP) {
      e.stopPropagation();
      e.preventDefault();

      handlePressUpArrow();
    } else if (e.keyCode === Key.ARROW_DOWN) {
      e.stopPropagation();
      e.preventDefault();

      handlePressDownArrow();
    }
  }, []);

  // apply the search filter
  const filteredOutlets = useMemo(
    () =>
      outlets.filter((o: IOutlet) =>
        deburr(o.name).toLocaleLowerCase().includes(deburr(searchText).toLocaleLowerCase())
      ),
    [outlets, searchText]
  );

  const favouriteOutlets = useMemo(() => {
    return filteredOutlets.filter((o: IOutlet) => o.favourite);
  }, [filteredOutlets]);

  const showAllOutletsItem = useMemo(
    () =>
      outlets.length > 1 &&
      deburr(content.all_outlets || "")
        .toLocaleLowerCase()
        .includes(deburr(searchText).toLocaleLowerCase()),
    [content.all_outlets, outlets.length, searchText]
  );

  // if there is a selected outlet, setup scrolling
  useEffect(() => {
    if (visible && selectedOutlet) {
      const outletIndex = outlets.findIndex(outlet => outlet.id === selectedOutlet.id);
      setInitialScrollLimit(outletIndex === -1 || outletIndex < 10 ? 10 : outletIndex + 10);
      setShouldScrollToOutlet(true);

      // also focus on the selected outlet, so the keyboard works from there
      setTimeout(() => {
        (document.getElementsByClassName("outlet-item-container")[
          outletIndex + 1
        ] as HTMLDivElement)?.focus();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOutlet, visible]);

  // handle scrolling to selected outlet
  useEffect(() => {
    if (visible) {
      if (outletRef.current) {
        setTimeout(() => {
          outletRef.current?.scrollIntoView({ behavior: "instant" as any, block: "start" });
          setShouldScrollToOutlet(false);
        });
      }
      setTimeout(() => {
        setPopoverOpening(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletRef.current, visible]);

  // if questionnaire was opened, it keeps looping to check if it had been closed manually
  useEffect(() => {
    let interval: any;
    if (questionnaireShowing) {
      interval = setInterval(() => {
        if (!document.querySelector("[data-qa]")) {
          dispatch(setQuestionnaireSubmitted(questionnaireShowing, true));
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [dispatch, questionnaireShowing]);

  useEffect(() => {
    if (!outlets.length) return;

    // automatically chooses the only outlet available
    if (outlets.length === 1) {
      setOutletAux([outlets[0]]);
    } else if (currentOutlet) {
      // automatically chooses the outlet if passed by url
      setOutletAux([currentOutlet]);
    }
  }, [outlets, currentOutlet]); // eslint-disable-line react-hooks/exhaustive-deps

  const outletsToShow = useMemo(
    () =>
      filteredOutlets.map((outlet: IOutlet) => (
        <OutletPickerItem
          ref={shouldScrollToOutlet && outlet.id === selectedOutlet?.id ? outletRef : null}
          key={outlet.id}
          outlet={outlet}
          selected={selectedOutlet?.id === outlet.id}
          onClick={() => handleChooseOutlet(outlet)}
          onFavourite={handleToggleFavourite}
        />
      )),
    [
      handleChooseOutlet,
      handleToggleFavourite,
      filteredOutlets,
      selectedOutlet,
      shouldScrollToOutlet
    ]
  );

  const listRef = useRef(null);
  const infiniteScrollOptions = useMemo(
    () => ({
      dependencies: [currentOutletPickerTab],
      intersectionOptions: {
        root: listRef.current,
        rootMargin: "0px 100px 0px 0px"
      },
      initialLimit: initialScrollLimit,
      limit: 50
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOutletPickerTab, visible, initialScrollLimit]
  );
  const infiniteOutlets = useInfiniteScrollList(outletsToShow, infiniteScrollOptions);

  // if there is no outlet id in the history's state, manually set it
  useEffect(() => {
    // @ts-ignore
    if (!location.state?.outletId && location.pathname !== Routes.home) {
      const outletId = selectedOutlet ? selectedOutlet.id : "all";
      history.replace(location.pathname, { outletId });
    }
  }, [history, location.pathname, location.state, selectedOutlet]);

  useEffect(() => {
    const unlisten = history.listen((newLocation, action) => {
      // listen to history navigation via the browser
      if (action === "POP") {
        // @ts-ignore
        const outletId = newLocation.state?.outletId;

        if (
          outletId &&
          outlets.length > 0 &&
          (selectedOutlet ? selectedOutlet.id !== outletId : outletId !== "all")
        ) {
          const outlet = outlets.find(o => o.id === outletId);
          setOutletAux(!outlet ? [] : [outlet]);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history, outlets, selectedOutlet, setOutletAux]);

  return (
    <div>
      <Popover
        wrappedRef={popoverRef}
        overlayParent
        disabled={outlets.length <= 1}
        showOverlay
        dismissOnClick
        renderHeader={() => (
          <div className="outlet-picker">
            <Title className="outlet-picker-name" data-hj-surpress>
              {selectedOutlet?.name || content.all_outlets}
            </Title>
            {outlets.length > 1 && <Icon name="chevron-down" />}
          </div>
        )}
        onHide={() => {
          setSearchText("");
          setVisible(false);
        }}
        onShow={() => {
          setPopoverOpening(true);
          setVisible(true);

          // focus on popover element to setup sequential keyboard navigation (w/ tab and arrow keys)
          // timeout necessary to guarantee popover is rendered before focusing
          setTimeout(() => {
            popoverBodyRef?.current?.focus();
          });
        }}
      >
        <div
          className="outlet-picker-popover"
          style={{ opacity: popoverOpening ? 0 : 1 }}
          ref={popoverBodyRef}
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <div className="outlet-picker-popover-header">
            <SectionTitle>{content.common_choose_outlet}</SectionTitle>
            <Icon name="close" className="close-icon" onClick={closePopover} />
          </div>
          <div className="outlet-picker-popover-search">
            <SearchInput
              placeholder={content.search_outlet_name}
              tabIndex={0}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>

          <div className="outlet-picker-popover-tabs" ref={listRef}>
            <TabGroup
              className="outlet-picker-popover-tabs-group"
              initialTab={currentOutletPickerTab}
              onTabClick={handleSelectAllTab}
            >
              <Tab
                label={(content.common_all_outlets_number || "").replace(
                  "%number%",
                  `${outlets.length}`
                )}
              >
                {showAllOutletsItem && (
                  <>
                    <OutletPickerItem
                      selected={!selectedOutlet}
                      onClick={() => handleChooseOutlet()}
                    />
                    <Divider className="outlet-all-divider" />
                  </>
                )}
                {filteredOutlets.length === 0 && (
                  <Body className="empty-outlet-list">{content.no_outlets_found}</Body>
                )}
                {infiniteOutlets}
              </Tab>
              <Tab
                label={(content.common_favourite_outlets_number || "").replace(
                  "%number%",
                  `${favouriteOutlets.length}`
                )}
              >
                {favouriteOutlets.length === 0 && (
                  <Body className="empty-outlet-list">{content.no_favourites_found}</Body>
                )}
                {favouriteOutlets.map((outlet: IOutlet) => (
                  <OutletPickerItem
                    ref={
                      shouldScrollToOutlet && outlet.id === selectedOutlet?.id ? outletRef : null
                    }
                    key={outlet.id}
                    outlet={outlet}
                    selected={selectedOutlet?.id === outlet.id}
                    onClick={() => handleChooseOutlet(outlet)}
                    onFavourite={handleToggleFavourite}
                  />
                ))}
              </Tab>
            </TabGroup>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default withRouter(OutletPicker);
