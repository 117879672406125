import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import { TertiaryButton } from "@ddm-design-system/button";
import { BodyHighlight, Description, Body, ButtonText } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { ILocationOpeningHour, ILocationData } from "../../store/outlet/types";
import HourDropdown from "./HourDropdown";
import useContent from "../../hooks/useContent";
import { hoursToMinutes } from "../../lib/Time/TimeFunctions";
import useIsMobile from "../../hooks/useIsMobile";
import "./outlet-opening-hours.scss";

interface IProps {
  location: ILocationData;
  onChangeOpeningHours?: (hours: ILocationOpeningHour[]) => void;
  readOnly?: boolean;
}

const DEFAULT_START_HOUR = hoursToMinutes(8);
const DEFAULT_END_HOUR = hoursToMinutes(23);

const DAYS = ["S", "M", "T", "W", "T", "F", "S"];

const DEFAULT_HOURS: ILocationOpeningHour[] = DAYS.map((d, i) => ({
  weekday: i + 1,
  startTime: DEFAULT_START_HOUR,
  endTime: DEFAULT_END_HOUR
}));

interface IHourSet {
  startTime: number;
  endTime: number;
  weekdays: number[];
}

function groupByHours(list: ILocationOpeningHour[]) {
  const map = new Map<string, ILocationOpeningHour[]>();
  list.forEach(item => {
    const key = `${item.startTime}:${item.endTime}`;
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const hoursToSets = (hours: ILocationOpeningHour[]) => {
  const sets: IHourSet[] = [];
  groupByHours(hours).forEach(value =>
    sets.push(
      value.reduce(
        (set, day) => ({
          weekdays: [...set.weekdays, day.weekday],
          startTime: day.startTime,
          endTime: day.endTime
        }),
        { weekdays: new Array<number>(), startTime: 0, endTime: 0 }
      )
    )
  );
  return sets;
};

const setsToHours = (sets: IHourSet[]) => {
  const hours: ILocationOpeningHour[] = [];
  DAYS.forEach((day, index) => {
    const dayIndex = index + 1;
    sets
      .filter(set => set.weekdays.includes(dayIndex))
      .forEach(info =>
        hours.push({
          weekday: dayIndex,
          startTime: info.startTime,
          endTime: info.endTime
        })
      );
  });
  return hours;
};

const OutletOpeningHours: React.FC<IProps> = ({ location, onChangeOpeningHours, readOnly }) => {
  const isMobile = useIsMobile();
  const { managerAppSettings: content } = useContent();
  const openingHours = useMemo<ILocationOpeningHour[]>(
    () => (location.openingHoursList?.length ? location.openingHoursList : DEFAULT_HOURS),
    [location]
  );
  const [hourSets, setHourSets] = useState(hoursToSets(openingHours));
  useEffect(() => setHourSets(hoursToSets(openingHours)), [openingHours]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChangeOpeningHours?.(setsToHours(hourSets)), [hourSets]);

  const toggleDay = (setIndex: number, day: number) => {
    if (readOnly) return;

    const newSets = [...hourSets];
    const set = newSets[setIndex];
    const dayIndex = set.weekdays.indexOf(day);
    const newWeekdays = [...set.weekdays];
    if (dayIndex > -1) {
      newWeekdays.splice(dayIndex, 1);
    } else {
      newWeekdays.push(day);
    }
    set.weekdays = newWeekdays.sort();
    setHourSets(newSets);
  };

  const setStartTime = (setIndex: number, startTime: number) => {
    if (readOnly) return;

    const newSets = [...hourSets];
    newSets[setIndex].startTime = startTime;
    setHourSets(newSets);
  };

  const setEndTime = (setIndex: number, endTime: number) => {
    if (readOnly) return;

    const newSets = [...hourSets];
    newSets[setIndex].endTime = endTime;
    setHourSets(newSets);
  };

  return (
    <div className={classnames("outlet-opening-hours", { "read-only": readOnly })}>
      <BodyHighlight>{content.manager_app_settings_outlet_info_open_hours}</BodyHighlight>
      {hourSets.map((set, setIndex) => (
        <div className="outlet-opening-hours-set" key={setIndex}>
          <div className="outlet-opening-hour-days">
            {DAYS.map((day, dayIndex) => {
              const trueIndex = dayIndex === 0 ? 7 : dayIndex; // make sunday the 7th day
              return (
                <Description
                  key={dayIndex}
                  onClick={() => toggleDay(setIndex, trueIndex)}
                  className={`outlet-weekday ${set.weekdays.includes(trueIndex) && "active"}`}
                >
                  {day}
                </Description>
              );
            })}
          </div>
          <div className="outlet-opening-hour-select">
            <Body>{content.manager_app_settings_outlet_info_from}</Body>
            <HourDropdown
              className="outlet-hour"
              selectedHour={set.startTime}
              onHourSelected={hour => setStartTime(setIndex, hour)}
            />
            <Body>{content.manager_app_settings_outlet_info_to}</Body>
            <HourDropdown
              className="outlet-hour"
              selectedHour={set.endTime}
              onHourSelected={hour => setEndTime(setIndex, hour)}
            />
            {!readOnly && setIndex > 0 && (
              <>
                <Icon
                  className="delete-set-button"
                  name="less"
                  onClick={() => {
                    const newSets = [...hourSets];
                    newSets.splice(setIndex, 1);
                    setHourSets(newSets);
                  }}
                />
                {isMobile && (
                  <ButtonText className="delete-set-text">
                    {content.manager_app_settings_outlet_info_delete}
                  </ButtonText>
                )}
              </>
            )}
          </div>
        </div>
      ))}
      {!readOnly && (
        <TertiaryButton
          className="add-set-button"
          icon="more"
          onClick={() => {
            setHourSets([
              ...hourSets,
              { startTime: DEFAULT_START_HOUR, endTime: DEFAULT_END_HOUR, weekdays: [] }
            ]);
          }}
        >
          {content.manager_app_settings_outlet_info_add}
        </TertiaryButton>
      )}
    </div>
  );
};

export default OutletOpeningHours;
