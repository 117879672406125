import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { TertiaryButton } from "@ddm-design-system/button";
import { ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { SectionTitle } from "@ddm-design-system/typography";
import { IAppState } from "../../store";
import { IProductInfo } from "../../store/product/types";
import { BeverageTranslation } from "../../store/beers/types";
import {
  getProductsInfo,
  getTapCount,
  getAvgKegsPerWeek
  // hasInsightsData
} from "../../store/product/selectors";
import { getOutletById } from "../../store/outlet/selectors";
import useContent from "../../hooks/useContent";
import ProductBeverageCard from "./ProductBeverageCard";
import ProductBeverageDetailsModal from "./ProductBeverageDetailsModal";
import { InsightCards } from "./insight-cards";

interface IProductOutletProps {
  outletId: string;
  group?: boolean;
  onStockModalOpen?: (locationId: string) => void;
}

const fakeBeverage = {
  tapPositions: [1],
  avgKegsPerWeek: 0,
  kegsInStock: 0,
  beverage: new BeverageTranslation()
};

const ProductOutlet = React.forwardRef<unknown, IProductOutletProps>(
  ({ outletId, group, onStockModalOpen = () => {} }, ref) => {
    const { managerAppProduct: content } = useContent();
    const beverageInfo = useSelector((state: IAppState) => getProductsInfo(state, outletId));
    const outletInfo = useSelector((state: IAppState) => getOutletById(state, outletId));
    const tapsCount = useSelector((state: IAppState) => getTapCount(state, outletId));
    const kegsPerWeekCount = useSelector((state: IAppState) => getAvgKegsPerWeek(state, outletId));
    const [selectedBeverageInfo, setSelectedBeverageInfo] = useState<
      | {
          beverage: IProductInfo & { beverage: BeverageTranslation };
          avatarOptions: { [key: string]: string };
        }
      | undefined
    >();

    const outletBeveragesCards = useMemo(
      () => (
        <div
          className={classnames(
            "product-outlet-container-wrapper",
            group && "product-outlet-container-wrapper-group"
          )}
        >
          {outletId === ""
            ? Array(6)
                .fill(fakeBeverage)
                .map((beverage, i) => (
                  <ProductBeverageCard key={i} beverage={beverage} onClick={() => {}} />
                ))
            : beverageInfo?.map(beverage => (
                <ProductBeverageCard
                  key={`${outletId}-${beverage.beverage.id}`}
                  beverage={beverage}
                  onClick={setSelectedBeverageInfo}
                  stockAccess={outletInfo?.stockAccess}
                />
              ))}
        </div>
      ),
      [outletInfo, beverageInfo, group, outletId]
    );

    // const hasInsights = useSelector(hasInsightsData);

    const handleDetailsModalClose = useCallback(() => setSelectedBeverageInfo(undefined), []);

    const handleOnManageStock = useCallback(
      event => {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        if (onStockModalOpen) {
          onStockModalOpen(outletId);
        }
      },
      [onStockModalOpen, outletId]
    );

    return (
      <>
        {group ? (
          <ExpandablePanel
            className="product-outlet-panel"
            wrappedRef={ref}
            title={outletInfo?.name || ""}
            renderHeaderDetails={() => (
              <div className="product-outlet-header">
                <div className="product-outlet-header-details">
                  {outletInfo?.stockAccess && (
                    <TertiaryButton
                      icon="box"
                      onClick={handleOnManageStock}
                      className="product-outlet-header-detail-stock"
                    >
                      {content.manager_app_product_manage_stock}
                    </TertiaryButton>
                  )}
                  <TertiaryButton
                    icon="tap-double"
                    iconColor="var(--color-grey-grey100)"
                    className="product-outlet-header-detail-item"
                  >
                    {tapsCount}
                  </TertiaryButton>
                  <TertiaryButton
                    icon="keg"
                    iconColor="var(--color-grey-grey100)"
                    className="product-outlet-header-detail-item"
                  >
                    {kegsPerWeekCount?.toFixed(1)}
                  </TertiaryButton>
                </div>
              </div>
            )}
            renderSubHeader={expanded => <InsightCards expanded={expanded} outletId={outletId} />}
          >
            <div className="product-outlet">{outletBeveragesCards}</div>
          </ExpandablePanel>
        ) : (
          <div className="product-outlet">
            {/* Sales reps not happy with AddTapInsightCard, so leave it out for now */}
            {/* <SectionTitle className="product-insights-title">
              {content.manager_app_insights_title}
            </SectionTitle>
            {hasInsights && <InsightCards expanded outletId={outletId} />} */}
            <div className="product-page-body-header">
              <SectionTitle>{content.manager_app_product_brands_on_tap}</SectionTitle>
              {outletInfo?.stockAccess && (
                <TertiaryButton icon="box" onClick={handleOnManageStock}>
                  {content.manager_app_product_manage_stock}
                </TertiaryButton>
              )}
            </div>
            {outletBeveragesCards}
          </div>
        )}
        <ProductBeverageDetailsModal
          avatarOptions={selectedBeverageInfo?.avatarOptions}
          beverage={selectedBeverageInfo?.beverage}
          isOpen={!!selectedBeverageInfo}
          handleClose={handleDetailsModalClose}
        />
      </>
    );
  }
);

export default ProductOutlet;
