import React, { useCallback, useContext, useEffect, useState } from "react";
import browserUpdate from "browser-update";
import { Icon } from "@ddm-design-system/icon";
import { NotificationContext } from "@ddm-design-system/notification";
import { SectionTitle } from "@ddm-design-system/typography";
import styles from "./browser_update.module.scss";
import useContent from "../../../hooks/useContent";

const BrowserUpdate: React.FC = () => {
  const [shown, setShown] = useState(false);
  const [, setNotificationId] = useState("");
  const content = useContent().managerAppCommon;
  const notificationContext = useContext(NotificationContext);

  const handleHide = useCallback(
    () =>
      setNotificationId(currId => {
        notificationContext.removeNotification(currId);
        return "";
      }),
    [notificationContext]
  );

  const handleShow = useCallback(() => {
    const id = notificationContext.addNotification({
      className: styles.notification,
      hasTimeout: false,
      text: content.manager_app_browser_update_text,
      title: (
        <div className={styles.header}>
          <SectionTitle>{content.manager_app_browser_update_title}</SectionTitle>
          <Icon
            name="close"
            fill="var(--color-white)"
            className={styles.icon}
            onClick={handleHide}
          />
        </div>
      )
    });
    setNotificationId(id || "");
  }, [
    notificationContext,
    content.manager_app_browser_update_title,
    content.manager_app_browser_update_text,
    handleHide
  ]);

  useEffect(() => {
    if (content && !shown) {
      browserUpdate({
        required: {
          e: 13,
          f: 52,
          c: 55,
          s: 10,
          i: 0,
          o: 0,
          vivaldi: 0,
          samsung: 0
        },
        insecure: true,
        onshow: handleShow,
        nomessage: true
      });
      setShown(true);
    }
  }, [content, handleShow, shown]);
  return <></>;
};

export default BrowserUpdate;
