import { Reducer } from "redux";
import produce from "immer";
import * as d3 from "d3";
import { RehydrateAction } from "redux-persist/es/types";
import moment from "moment";
import numeral from "numeral";
import { DEFAULT_LANG } from "../../constants";
import { mapContentfulEntries } from ".";
import {
  ContentActionTypes,
  GET_CONTENTFUL,
  GET_CONTENTFUL_ERROR,
  GET_CONTENTFUL_SUCCESS,
  IContentState,
  ILanguageInfo,
  SET_CURRENT_LANGUAGE,
  SET_SYNC_TOKEN
} from "./types";
import "numeral/locales";

import "moment/locale/de";
import "moment/locale/da";
import "moment/locale/en-gb";
import "moment/locale/it";
import "moment/locale/fr";

const initialState: IContentState = {
  contentfulLoaded: false,
  loadingContentful: false,
  contentfulError: false,
  currentLanguage: "en-GB",
  entries: {}
};

const reducer: Reducer<IContentState, ContentActionTypes | RehydrateAction> = (
  state = initialState,
  action: ContentActionTypes | RehydrateAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_CONTENTFUL:
        draft.loadingContentful = true;
        draft.contentfulError = false;
        break;
      case GET_CONTENTFUL_SUCCESS:
        draft.loadingContentful = false;
        draft.contentfulError = false;
        if (Object.keys(action.payload.entries).length > 0) {
          try {
            draft.entries = mapContentfulEntries(action.payload.entries, action.payload.assets);
          } catch (e) {
            console.log("error contentful mapping", e);
            draft.contentfulError = true;
          }
        }
        break;
      case SET_SYNC_TOKEN:
        if (action.payload.contentfulToken) {
          draft.contentfulToken = action.payload.contentfulToken;
        }
        if (action.payload.dbTimestamp) {
          draft.dbTimestamp = action.payload.dbTimestamp;
        }
        draft.loadingContentful = false;
        draft.contentfulLoaded = true;
        break;
      case SET_CURRENT_LANGUAGE:
        draft.currentLanguage = action.payload.code;
        const locale = state.entries[action.payload.code || DEFAULT_LANG].languageInfo.filter(
          (l: ILanguageInfo) => l.code === action.payload.code
        )[0].d3TimeFormat;
        moment.locale(action.payload.code.toLocaleLowerCase());
        d3.timeFormatDefaultLocale(locale);
        const lang = numeral.locales[action.payload.code.toLocaleLowerCase()]
          ? action.payload.code.toLocaleLowerCase()
          : action.payload.code.toLocaleLowerCase()?.split("-")[0];
        numeral.locale(lang);
        break;
      case GET_CONTENTFUL_ERROR:
        draft.loadingContentful = false;
        draft.contentfulError = true;
        break;
      case "persist/REHYDRATE":
        if (action.key === "language" && action.payload) {
          const currentState: any = action.payload;
          if (
            !currentState.entries ||
            !currentState.entries[currentState.currentLanguage || DEFAULT_LANG]
          ) {
            return;
          }
          const localePersist = currentState.entries[
            currentState.currentLanguage || DEFAULT_LANG
          ].languageInfo.filter((l: ILanguageInfo) => l.code === currentState.currentLanguage)[0]
            .d3TimeFormat;
          moment.locale(currentState.currentLanguage.toLocaleLowerCase());
          if (localePersist) {
            d3.timeFormatDefaultLocale(localePersist);
          }
        }
        break;
      default:
        break;
    }
  });

export default reducer;
