import React, { useEffect, useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { PrimaryButton } from "@ddm-design-system/button";
import { Body } from "@ddm-design-system/typography";
import styles from "./cookies_form.module.scss";
import Hotjar from "../../../services/hotjar";
import { AnalyticsContext } from "../../../services/analytics";
import useContent from "../../../hooks/useContent";
import { getUser } from "../../../store/profile/reducer";
import { getContentfulLoaded, getCurrentLanguage } from "../../../store/content/selectors";
import Time from "../../../lib/Time/Time";

const CookiesForm: React.FC = () => {
  const AnalyticsService = useContext(AnalyticsContext);
  const { managerAppCommon: contentCommon } = useContent();
  const [hotjarSetup, setHotjarSetup] = useState(false);
  const me = useSelector(getUser);
  const createDateTime = me?.createDateTime;
  const contentfulLoaded = useSelector(getContentfulLoaded);
  const loaded = contentfulLoaded && me !== null;
  const language = useSelector(getCurrentLanguage);

  const triggerSurvey = useCallback((lang = "en") => {
    const code = `nps_${lang.substr(0, 2)}`;
    Hotjar.trigger(code);
  }, []);

  useEffect(() => {
    const old = !createDateTime || new Time(createDateTime).diff(new Time(), "month") >= 1;
    if (loaded && hotjarSetup && old) {
      triggerSurvey(language);
    }
  }, [language, loaded, createDateTime, hotjarSetup, triggerSurvey]);

  const saveConsent = (consent: boolean) =>
    localStorage.setItem("cookieConsent", consent ? "true" : "false");

  const setupTracking = useCallback(() => {
    Hotjar.setup();
    AnalyticsService.setup(me);
    setHotjarSetup(true);
  }, [AnalyticsService, me]);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "true" && me) {
      setupTracking();
    }
  }, [me, setupTracking]);

  const optOutTokens = (contentCommon.manager_app_cookies_opt_out || "").split("%SPLIT%");
  const consent = localStorage.getItem("cookieConsent");
  const isVisible = Object.keys(contentCommon || {}).length && consent === null;

  return isVisible ? (
    <div className={styles.cookies}>
      <Body>
        <ReactMarkdown
          source={contentCommon.manager_app_cookies_text}
          renderers={{
            link: props => (
              <a
                className={styles.link}
                href={props.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.children}
              </a>
            )
          }}
        />
        {optOutTokens.length >= 3 && (
          <p>
            {optOutTokens[0]}
            <span
              className={styles.link}
              onClick={() => {
                saveConsent(false);
              }}
            >
              {optOutTokens[1]}
            </span>
            {optOutTokens[2]}
          </p>
        )}
      </Body>
      <div className={styles.buttonsContainer}>
        <PrimaryButton
          className={styles.buttonYes}
          onClick={() => {
            saveConsent(true);
            setupTracking();
          }}
        >
          {contentCommon.manager_app_cookies_yes}
        </PrimaryButton>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CookiesForm;
