import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, FiltersMultipleDropdown } from "@ddm-design-system/dropdown";
import "./sales_filter.scss";
import {
  setBeerIds,
  setCompareOutlet,
  setPerformanceType,
  setTimeFrame
} from "../../store/filter/actions";
import useContent from "../../hooks/useContent";
import { IOutlet } from "../../store/outlet/types";
import { ISelectedBeer, ITimeFrameCheckbox } from "./SalesFilter";
import { PERFORMANCE_TYPE } from "../../store/filter/types";

interface ISalesDesktopProps {
  timeDropdown: React.MutableRefObject<null>;
  chosenPerformanceType: PERFORMANCE_TYPE;
  chosenCompareOutlet: any;
  outletList: any;
  outlets: any;
  selectedTimeFrame?: ITimeFrameCheckbox;
  beerList: ISelectedBeer[];
  selectedBeer: any;
  setSelectedBeer: React.Dispatch<any>;
  timeFrameCheckboxes: ITimeFrameCheckbox[];
  setVisibleDatesPicker: React.Dispatch<React.SetStateAction<boolean>>;
}

const SalesFilterDesktop: React.FC<ISalesDesktopProps> = ({
  timeDropdown,
  chosenPerformanceType,
  chosenCompareOutlet,
  outletList,
  outlets,
  selectedTimeFrame,
  beerList,
  selectedBeer,
  setSelectedBeer,
  timeFrameCheckboxes,
  setVisibleDatesPicker
}) => {
  const dispatch = useDispatch();
  const { managerAppSales: contentSales, managerAppCommon: contentCommon } = useContent();
  const sortedBeerList = useMemo(() => beerList.sort((a, b) => (a.text > b.text ? 1 : -1)), [
    beerList
  ]);

  return (
    <>
      <div className="sales-filter-dropdown-container">
        <div className="sales-filter-dropdown">
          <Dropdown
            wrappedRef={timeDropdown}
            label={contentSales.manager_app_time_range}
            items={timeFrameCheckboxes}
            onItemSelected={(item: any) => {
              if (item.timeframe.type !== "CUSTOM") {
                dispatch(setTimeFrame(item.timeframe));
              } else {
                setVisibleDatesPicker(true);
                // @ts-ignore
                timeDropdown?.current?.hide();
              }
            }}
            selectedItem={selectedTimeFrame || timeFrameCheckboxes[0]}
          />
        </div>
        <div className="sales-filter-dropdown">
          <FiltersMultipleDropdown
            label={contentSales.manager_app_beer}
            placeholder={contentCommon.common_all}
            items={sortedBeerList}
            onItemsSelected={setSelectedBeer}
            selectedItems={selectedBeer}
            selectAllText={contentCommon.common_all}
            onClose={() => {
              dispatch(
                setBeerIds(
                  selectedBeer.map((s: any) => s.id),
                  sortedBeerList
                )
              );
            }}
          />
        </div>
      </div>
      <div className="sales-filter-dropdown filter-desktop">
        <Dropdown
          label={contentSales.manager_app_sales_compare_vs}
          items={outletList}
          onItemSelected={(o: any) => {
            if (
              o.id === PERFORMANCE_TYPE.AVG ||
              o.id === PERFORMANCE_TYPE.TARGET ||
              o.id === PERFORMANCE_TYPE.SIMILAR ||
              o.id === PERFORMANCE_TYPE.NEAR
            ) {
              dispatch(setPerformanceType(o.id));
              // dispatch(setCompareOutlet(null));
            }
            const result = outlets.find((outlet: IOutlet) => outlet.id === o.id);
            if (result) {
              dispatch(setPerformanceType(PERFORMANCE_TYPE.OUTLET));
              dispatch(setCompareOutlet(result));
            }
          }}
          selectedItem={
            chosenPerformanceType === PERFORMANCE_TYPE.OUTLET && chosenCompareOutlet
              ? {
                  id: chosenCompareOutlet.id,
                  text: chosenCompareOutlet.name
                }
              : outletList.find((o: any) => o.id === chosenPerformanceType)
          }
        />
      </div>
    </>
  );
};

export default SalesFilterDesktop;
