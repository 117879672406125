import React, { useCallback, useEffect, useState } from "react";
import { Authentication, AuthenticationContext, AdalConfig } from "adal-ts";
import { IJCoreService, IUserId } from "../../services/jcore";
import { adalConfig } from "../../adalConfig";

interface IAuthContext {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  error: null | {
    type: string;
    body: string;
  };

  isFirstLogin?: boolean;
  adal?: AuthenticationContext;
  login: (
    username: string,
    password: string,
    recaptchaToken: string,
    rememberMe?: boolean
  ) => Promise<IUserId>;
  validateToken: (token: string) => void;
  getAzureTokenFunction: () => string;
  sendRecoveryEmail: (email: string) => void;
  validateRecoveryToken: (token: string) => void;
  validateAzureInvitationToken: (token: string) => void;
  resetPassword: (token: string, password: string) => void;
  unsubscribe: (token: string) => void;
  setAzureToken: (token: string) => void;
  getAzureToken: () => any;
  logout: () => void;
}

const mockAuth: IAuthContext = {
  isAuthenticating: false,
  isAuthenticated: false,
  error: null,
  async login(username: string, password: string) {
    this.isAuthenticating = true;
    await new Promise(resolve => setTimeout(resolve, 300));
    this.isAuthenticating = false;
    this.isAuthenticated = true;
    return "";
  },
  validateToken() {
    // return new Promise(resolve => setTimeout(resolve, 300));
  },
  sendRecoveryEmail() {},
  validateRecoveryToken() {
    // return new Promise(resolve => setTimeout(resolve, 300));
  },
  resetPassword() {
    return "";
  },
  logout() {
    this.isAuthenticated = false;
  },
  unsubscribe() {},
  getAzureToken() {
    return "";
  },
  setAzureToken() {
    return "";
  },
  getAzureTokenFunction() {
    return "";
  },
  validateAzureInvitationToken(token: string) {
    return "";
  }
};

export const useAuthContext: (jcore: IJCoreService, handleLogout?: () => void) => IAuthContext = (
  jcore: IJCoreService,
  handleLogout?: () => void
) => {
  const config = new AdalConfig(
    adalConfig.clientId,
    adalConfig.tenant,
    window.location.origin,
    window.location.origin,
    "token",
    `resource=${encodeURIComponent("00000002-0000-0000-c000-000000000000")}`
  );
  const adal = Authentication.getContext(config);
  const getAzureTokenFunction = () => adal.getToken();
  const [isAuthenticated, setAuthenticated] = useState(jcore.isLoggedIn());
  const [isAuthenticating, setAuthenticating] = useState(false);
  const [error, setError] = useState<null | {
    type: string;
    body: string;
  }>(null);
  const [isFirstLoginVal, setFirstLogin] = useState(jcore.isFirstLogin());

  /* Check if adal.getToken returns anything, if it does, do azureauth request */
  const azureToken = getAzureTokenFunction();

  const refAzureToken = useCallback(() => {
    if (azureToken !== null && azureToken !== "") {
      const ddmInvitationToken = localStorage.getItem("ddm-azureinvitationtoken");
      if (!ddmInvitationToken) {
        jcore
          .azureAuth(azureToken)
          .then((d: any) => {
            const token = d.replace("Bearer ", "") as string;
            jcore.setUserToken(token);
            setAuthenticated(true);
          })
          .catch(() => {
            const user = adal.getUser();
            setError({
              type: "NO_ACCESS_AZURE",
              body: user && user.unique_name
            });
          })
          .finally(() => {
            localStorage.removeItem("adal.accesstoken");
            localStorage.removeItem("ddm-azureinvitationtoken");
          });
      }
    }
  }, [adal, azureToken, jcore]);

  useEffect(() => {
    refAzureToken();
  }, [refAzureToken]);

  const login = async (
    username: string,
    password: string,
    recaptchaToken: string,
    rememberMe?: boolean
  ) => {
    let userId = "";
    setAuthenticating(true);
    jcore.deleteFirstLoginToken();
    try {
      const { isFirstLogin, id } = await jcore.login({
        username: username.trim(),
        password,
        recaptchaToken,
        rememberMe
      });
      setFirstLogin(isFirstLogin);
      userId = id;
      setAuthenticated(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error login", e);
      throw e;
    } finally {
      setAuthenticating(false);
    }
    return userId;
  };

  const validateToken = async (token: string) => {
    let userId = "";
    setAuthenticating(true);
    try {
      const { isFirstLogin, id } = await jcore.validateToken(token);
      setFirstLogin(isFirstLogin);
      userId = id;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setAuthenticating(false);
    }
    setAuthenticated(true);
    return userId;
  };

  const validateRecoveryToken = (token: string) => {
    try {
      // const result = await jcore.validateRecoveryToken(token);
      // return result.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      return false;
    }
  };
  const validateAzureInvitationToken = async (token: string) => {
    try {
      const result = await jcore.linkAzureAccount(token, getAzureTokenFunction());

      localStorage.removeItem("ddm-azureinvitationtoken");
      refAzureToken();
      return result;
    } catch (e) {
      setError({
        type: "ERROR_LINKING_ACCOUNT",
        body: "asd"
      });
      throw e;
    }
  };

  const sendRecoveryEmail = (email: string) => {
    try {
      jcore.sendRecoveryEmail(email);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const resetPassword = (token: string, password: string) => {
    try {
      // await jcore.resetPassword(token, password);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const logout = () => {
    jcore.logout();
    setAuthenticated(false);
    if (handleLogout) {
      handleLogout();
    }
  };

  const unsubscribe = (token: string) => {
    try {
      jcore.unsubscribe(token);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const getAzureToken = () => jcore.getAzureToken();
  const setAzureToken = (token: string) => {
    jcore.setAzureToken(token);
  };

  return {
    isAuthenticated,
    isAuthenticating,
    adal,
    isFirstLogin: isFirstLoginVal,
    validateToken,
    getAzureToken,
    setAzureToken,
    getAzureTokenFunction,
    validateAzureInvitationToken,
    validateRecoveryToken,
    sendRecoveryEmail,
    resetPassword,
    unsubscribe,
    login,
    logout,
    error
  };
};

export const AuthContext = React.createContext<IAuthContext>(mockAuth);
export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
export default AuthContext;
