import {
  SHOW_HOMESCREEN_MODAL,
  HIDE_HOMESCREEN_MODAL,
  SHOW_FAULTY_SENSOR_MODAL,
  HIDE_FAULTY_SENSOR_MODAL,
  IShowHomescreenModal,
  IHideHomescreenModal,
  IShowFaultySensorModal,
  IHideFaultySensorModal
} from "./types";

export const showHomescreenModal = (): IShowHomescreenModal => ({
  type: SHOW_HOMESCREEN_MODAL
});

export const hideHomescreenModal = (): IHideHomescreenModal => ({ type: HIDE_HOMESCREEN_MODAL });

export const showFaultySensorModal = (pressureChambers: number[]): IShowFaultySensorModal => ({
  type: SHOW_FAULTY_SENSOR_MODAL,
  payload: pressureChambers
});

export const hideFaultySensorModal = (): IHideFaultySensorModal => ({
  type: HIDE_FAULTY_SENSOR_MODAL
});
