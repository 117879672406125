import React, { useMemo } from "react";
import { Keg as DDMKeg } from "@ddm-design-system/keg";
import { IPressureChamber } from "../../../store/installation/types";
import { generateInitials } from "../../../helpers";
import useContent from "../../../hooks/useContent";

export interface IProps {
  pressureChamber: IPressureChamber;
}

export const Keg: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppCommon: content } = useContent();

  const hasError =
    pressureChamber.offline ||
    pressureChamber.noKeg ||
    !pressureChamber.sensorConnected ||
    pressureChamber.isFaulty;

  const logoFallback = useMemo(
    () => ({
      color: pressureChamber.beverage.mainColor ?? "",
      initials: generateInitials(pressureChamber.beverage.brand, pressureChamber.beverage.subbrand)
    }),
    [pressureChamber]
  );

  return (
    <DDMKeg
      emptyLabel={content.common_empty}
      hasError={hasError}
      logoFallback={logoFallback}
      logoUrl={pressureChamber.beverage.logoUrl ?? undefined}
      volumeLeft={pressureChamber.volumeLeft}
    />
  );
};
