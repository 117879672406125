import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useLayoutEffect
} from "react";
import { Flipper, Flipped } from "react-flip-toolkit";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, Redirect } from "react-router";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import ReactMarkdown from "react-markdown";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { PrimaryButton } from "@ddm-design-system/button";
import { Checkbox } from "@ddm-design-system/checkbox";
import { Icon } from "@ddm-design-system/icon";
import { NotificationContext } from "@ddm-design-system/notification";
import { TextInput, ErrorTextInput } from "@ddm-design-system/textinput";
import { SectionTitle, Body, DescriptionMedium, PageTitle } from "@ddm-design-system/typography";
import { getContentfulError, getCurrentLanguage } from "../../store/content/selectors";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";
import { AnalyticsContext } from "../../services/analytics";
import StyleConstants, { RECAPTCHA_KEY } from "../../constants";
import Routes from "../../routes";
import RecoverPassword from "./RecoverPassword";
import ResetPassword from "./ResetPassword";
import useIsMobile from "../../hooks/useIsMobile";
import LanguageAndPrivacy from "./LanguageAndPrivacy";
import "./login.scss";

let intervalRef: any = null;
export const Login: React.FC<RouteComponentProps> = ({ location, history }) => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const { managerAppLogin: content, mediaContainer: media, emailDomains } = useContent();
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint2);

  const contentfulError = useSelector(getContentfulError);

  const [recoveryToken, setRecoveryToken] = useState<string | null>("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(localStorage.getItem("grecaptcha") || "");

  useLayoutEffect(() => {
    const captcha: any = document.getElementsByClassName("grecaptcha-badge")[0];
    if (captcha) {
      captcha.style.visibility = "visible";
    }
  }, []);

  const validateInvitationToken = useCallback(
    (urlToken = "") => {
      setError(false);
      try {
        // const userId = await auth.validateToken(urlToken);
        // analytics.logEvent("VALIDATE TOKEN", urlToken);
        // analytics.setAuthenticatedUserContext(userId);
      } catch {
        notificationContext.addNotification({
          iconProps: { name: "ok" },
          text: content.manager_app_invalid_token
        });
      }
    },
    [content.manager_app_invalid_token, notificationContext]
  );

  const validateRecoveryToken = useCallback(
    (rToken = "") => {
      setError(false);
      try {
        // auth.validateRecoveryToken(rToken);
        // analytics.logEvent("VALIDATE RECOVERY TOKEN", rToken);
        // setRecoveryToken(rToken);
      } catch (e) {
        notificationContext.addNotification({
          iconProps: { name: "error" },
          text: content.manager_app_invalid_token
        });
      }
    },
    [content.manager_app_invalid_token, notificationContext]
  );

  const login = useCallback(
    async (user = "", pass = "") => {
      setError(false);
      try {
        const userId = await auth.login(
          user || username,
          pass || password,
          recaptchaToken,
          rememberMe
        );
        analytics.logEvent("LOGIN", username);
        analytics.setAuthenticatedUserContext(userId);
        const { search } = location;
        const params = new URLSearchParams(search);
        const route = params.get("route");

        // hide recaptcha
        const captcha: any = document.getElementsByClassName("grecaptcha-badge")[0];
        if (captcha) {
          captcha.style.visibility = "hidden";
        }

        if (route) {
          history.push(route);
        }
      } catch (e) {
        analytics.logEvent("LOGIN_ERROR", username, JSON.stringify(e));
        setError(true);
      }
    },
    [analytics, auth, history, location, password, username, recaptchaToken, rememberMe]
  );

  const [forgotPassword, setForgotPassword] = useState(false);
  const [redirect, setRedirect] = useState(auth.isAuthenticated);
  const [currentPicture, setCurrentPicture] = useState(0);

  useEffect(() => {
    setRedirect(auth.isAuthenticated);
    if (auth.error && auth.error.body) {
      switch (auth.error.type) {
        case "NO_ACCESS_AZURE":
          notificationContext.addNotification({
            iconProps: { name: "error" },
            text: (
              <>
                <span>
                  {(content.manager_app_adal_error || "").replace("%ACCOUNT%", auth.error.body)}
                </span>
                &nbsp;
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    if (auth.adal) {
                      localStorage.removeItem("ddm-azureinvitationtoken");
                      auth.adal.logout();
                    }
                  }}
                >
                  {content.manager_app_adal_logout}
                </span>
              </>
            )
          });
          break;
        case "ERROR_LINKING_ACCOUNT":
          notificationContext.addNotification({
            iconProps: { name: "error" },
            text: content.manager_app_error_linking
          });
          localStorage.removeItem("ddm-azureinvitationtoken");
          break;
        default:
          notificationContext.addNotification({
            iconProps: { name: "error" },
            text: "Unknown Error"
          });
      }
    }
  }, [auth, content, notificationContext]);

  // @ts-ignore
  const { from } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const from = location.state || { from: { pathname: "/" } };
    return from === Routes.login ? Routes.home : from;
  }, [location.state]);

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const azureToken = params.get("azureinvitationtoken");
    if (azureToken) {
      const localStorageInvitationToken = localStorage.getItem("ddm-azureinvitationtoken");
      if (localStorageInvitationToken) {
        if (auth.adal && emailDomains) {
          const userAdal = auth.adal.getUser() && auth.adal.getUser().unique_name;
          if (!userAdal) {
            return;
          }
          const tokens = userAdal ? userAdal.split("@") : null;
          if (userAdal && tokens && emailDomains) {
            if (tokens[1] && emailDomains.split("\n").includes(tokens[1])) {
              auth.validateAzureInvitationToken(azureToken);
            } else {
              notificationContext.addNotification({
                iconProps: { name: "error" },
                text: (
                  <>
                    <span>
                      {(content.manager_app_email_domain_error || "").replace("%EMAIL%", tokens[1])}
                    </span>
                    &nbsp;
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        if (auth.adal) {
                          localStorage.removeItem("ddm-azureinvitationtoken");
                          auth.adal.logout();
                        }
                      }}
                    >
                      {content.manager_app_adal_logout}
                    </span>
                  </>
                )
              });
              localStorage.removeItem("ddm-azureinvitationtoken");
            }
          } else {
            localStorage.removeItem("ddm-azureinvitationtoken");
          }
        }
      } else {
        localStorage.setItem("ddm-azureinvitationtoken", azureToken);
        if (auth.adal) {
          auth.adal.login();
        }
      }
    } else {
      localStorage.removeItem("ddm-azureinvitationtoken");
    }
    const user = params.get("username");
    const pass = params.get("password");
    if (user) {
      setUsername(user);
    }
    if (user && pass && user.length > 0 && pass.length > 0) {
      login(user, pass);
    }

    // invitation token to create new account
    const urlToken = params.get("invitationtoken");
    if (urlToken) {
      validateInvitationToken(urlToken);
    }
    // sets password reset form
    const recoveryTokenVar = params.get("passwordresettoken");
    if (recoveryTokenVar) {
      validateRecoveryToken(recoveryTokenVar || "");
    }
  }, [
    auth,
    content,
    dispatch,
    emailDomains,
    location,
    login,
    notificationContext,
    validateInvitationToken,
    validateRecoveryToken
  ]);

  useEffect(() => {
    if (!media) {
      return;
    }

    clearInterval(intervalRef);
    setCurrentPicture(Math.floor(Math.random() * media.length));

    if (media && media.length > 0) {
      intervalRef = setInterval(() => {
        if (media) {
          setCurrentPicture(picture => (picture + 1) % media.length);
        }
      }, 15000);
    }
    return () => {
      clearInterval(intervalRef);
    };
  }, [media]);

  const renderContentfulError = () => (
    <SectionTitle className="contentful-error">
      There was an error loading content... <br />
      Please try again later.
    </SectionTitle>
  );
  const renderLogo = () => (
    <div className="logo-container">
      <Icon
        name="dm-full-logo"
        height={32}
        fill={isMobile ? "var(--color-black)" : "var(--color-white)"}
      />
    </div>
  );

  const renderBackgroundImage = () => (
    <div className="login-background">
      <div className={classnames("login-background-image frame", { error: contentfulError })}>
        <SwitchTransition mode="in-out">
          <CSSTransition key={currentPicture} classNames="image-anim" timeout={2000}>
            {media ? <BackgroundImage media={media} currentImage={currentPicture} /> : <></>}
          </CSSTransition>
        </SwitchTransition>
        {renderLogo()}
      </div>
    </div>
  );

  const middle = () => {
    if (recoveryToken) {
      return (
        <ResetPassword
          token={recoveryToken}
          setComplete={() => {
            setRecoveryToken(null);
            history.push(Routes.login);
          }}
        />
      );
    }
    if (forgotPassword) {
      return (
        <RecoverPassword
          username={username}
          setForgotPassword={setForgotPassword}
          setUsername={setUsername}
        />
      );
    }

    return (
      <>
        <Flipped flipId="title">
          <PageTitle>{content.manager_app_login_title}</PageTitle>
        </Flipped>
        <Body className="fade-in margin-top--sm">{content.manager_app_login_subtitle}</Body>
        <form
          className="login-form-container margin-top--md"
          onSubmit={e => {
            e.preventDefault();
            if (!(username === "" || auth.isAuthenticating)) {
              login();
            }
          }}
        >
          <Flipped flipId="email-field">
            <TextInput
              label={content.manager_app_email}
              name="username"
              autoComplete="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Flipped>
          <ErrorTextInput
            autoComplete="current-password"
            className="fade-in password-field"
            error={error ? content.manager_app_wrong_password : undefined}
            label={content.manager_app_password}
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <GoogleReCaptcha
            onVerify={(reToken: string) => {
              setRecaptchaToken(reToken);
              localStorage.setItem("grecaptcha", reToken);
            }}
          />
          <div className="login-form-options fade-in">
            <div className="login-option login-remember-me">
              <Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)}>
                {content.manager_app_remember_me}
              </Checkbox>
            </div>
            <div className="login-option forgot-password">
              <div onClick={() => setForgotPassword(true)} style={{ cursor: "pointer" }}>
                <DescriptionMedium>{content.manager_app_forgot_password}</DescriptionMedium>
              </div>
            </div>
          </div>
          <div className="button-container">
            <Flipped flipId="confirm-button">
              <PrimaryButton type="submit" disabled={username === "" || auth.isAuthenticating}>
                {content.manager_app_login_button}
              </PrimaryButton>
            </Flipped>
          </div>
        </form>
      </>
    );
  };

  const renderLogin = () => (
    <div className="login-ready">
      {!isMobile && renderBackgroundImage()}
      <Flipper className="login-form" flipKey={forgotPassword || recoveryToken}>
        {contentfulError ? (
          renderContentfulError()
        ) : (
          <div className="login-lower">
            {isMobile && renderLogo()}
            {!isMobile && <LanguageAndPrivacy />}
            <div className="login-form-middle">{middle()}</div>
            {!recoveryToken && !forgotPassword && (
              <div className="login-bottom fade-and-slide">
                <Body
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (auth.adal) {
                      auth.adal.login();
                    }
                  }}
                >
                  <ReactMarkdown source={content.manager_app_carlsberg_account} />
                </Body>
                {isMobile && <LanguageAndPrivacy />}
              </div>
            )}
          </div>
        )}
      </Flipper>
    </div>
  );

  return redirect ? <Redirect to={from} /> : <div className="login-page">{renderLogin()}</div>;
};

interface IBGProps {
  media: string[];
  currentImage: number;
}
const BackgroundImage: React.FC<IBGProps> = ({ media, currentImage }) => (
  <img
    className="bg-image"
    src={`${media[currentImage]}?fm=jpg&fl=progressive`}
    alt="background img"
  />
);

export default Login;
