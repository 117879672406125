import React, { useMemo, SyntheticEvent } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { BeverageTranslation } from "../../store/beers/types";
import { getIsLoading } from "../../store/product/selectors";
import "./product.scss";

const MAX_LOGOS_LENGTH = 7;

interface IProps {
  title: string;
  brands: InstanceType<typeof BeverageTranslation>[];
}

const ProductSummaryGroup: React.FC<IProps> = ({ title, brands }) => {
  const showEllipsis = brands.length > MAX_LOGOS_LENGTH;

  // for UI stacking order purposes, so that visually brands on the left stack over ones on the right
  const reverseBrands = useMemo(
    () => (showEllipsis ? brands.slice(0, MAX_LOGOS_LENGTH).reverse() : brands.reverse()),
    [brands, showEllipsis]
  );

  // dynamically set background color for broken logo imgs
  const handleImgError = (error: SyntheticEvent, brand: BeverageTranslation) => {
    error.currentTarget.setAttribute(
      "style",
      `background-color: ${brand.mainColor ?? "var(--color-grey-grey50)"}`
    );
  };
  const loading = useSelector(getIsLoading);

  return (
    <div className={classnames("product-summary-group", { loading })}>
      <div className="product-summary-group-title-wrapper">
        <DescriptionMedium className="product-summary-group-title">{title}</DescriptionMedium>
        <DescriptionMedium>{brands.length}</DescriptionMedium>
      </div>
      <div className="product-summary-group-logos">
        {showEllipsis && (
          <Icon
            className="product-summary-group-logo product-summary-group-logo--ellipsis"
            name="ellipsis"
          />
        )}
        {(loading ? Array(4).fill("") : reverseBrands).map((brand, i) =>
          brand?.logoUrl ? (
            <img
              className="product-summary-group-logo"
              key={brand.id}
              src={loading ? "" : brand.logoUrl}
              alt=""
              title={brand.name}
              onError={error => handleImgError(error, brand)}
            />
          ) : (
            <div
              className="product-summary-group-logo product-summary-group-logo--fallback"
              key={brand?.id || i}
              style={{ backgroundColor: brand?.mainColor }}
              title={brand?.name}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ProductSummaryGroup;
