export const SHOW_HOMESCREEN_MODAL = "modals/SHOW_HOMESCREEN_MODAL";
export const HIDE_HOMESCREEN_MODAL = "modals/HIDE_HOMESCREEN_MODAL";
export const SHOW_FAULTY_SENSOR_MODAL = "modals/SHOW_FAULTY_SENSOR_MODAL";
export const HIDE_FAULTY_SENSOR_MODAL = "modals/HIDE_FAULTY_SENSOR_MODAL";

export interface IHomescreenModalState {
  homescreenModal: { isVisible: boolean };
  faultySensorModal: { isVisible: boolean; pressureChambers?: number[] };
}

export interface IShowHomescreenModal {
  type: typeof SHOW_HOMESCREEN_MODAL;
}

export interface IHideHomescreenModal {
  type: typeof HIDE_HOMESCREEN_MODAL;
}

export interface IShowFaultySensorModal {
  type: typeof SHOW_FAULTY_SENSOR_MODAL;
  payload: number[];
}

export interface IHideFaultySensorModal {
  type: typeof HIDE_FAULTY_SENSOR_MODAL;
}

export type modalsActionTypes =
  | IShowHomescreenModal
  | IHideHomescreenModal
  | IShowFaultySensorModal
  | IHideFaultySensorModal;
