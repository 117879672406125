import React, { useRef } from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TabGroup, Tab } from "@ddm-design-system/tab";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { SettingsProfile } from "../settings-profile/SettingsProfile";
import { SettingsUser } from "../settings-users/SettingsUser";
import { SettingsOutlets } from "../settings-outlets/SettingsOutlets";
import { SettingsDevices } from "../settings-devices/SettingsDevices";
import "./settings.scss";

export const Settings: React.FC<RouteComponentProps> = ({ location }) => {
  const { managerAppSettings: settingsContent } = useContent();
  const history = useHistory();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const scrollToRef = (ref: any) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }; // General scroll to element function
  if (location.pathname && ref1.current && ref2.current && ref3.current) {
    switch (location.pathname) {
      case "/settings/profile":
        scrollToRef(ref2);
        break;
      case "/settings/users":
        scrollToRef(ref3);
        break;
      default:
        scrollToRef(ref1);
    }
  }

  const tabs = [
    {
      title: settingsContent.manager_app_settings_account_tab,
      route: Routes.profile
    },
    {
      title: settingsContent.manager_app_settings_user_management_tab,
      route: Routes.users
    },
    {
      title: settingsContent.manager_app_settings_outlet_info_tab,
      route: Routes.outlets
    },
    {
      title: settingsContent.manager_app_settings_staff_app_tab,
      route: Routes.devices
    }
  ];
  const selectedTab = tabs.findIndex(t => t.route === location.pathname);

  return settingsContent ? (
    <div className="settings-page">
      <TabGroup
        selectedTab={selectedTab}
        onTabClick={index => history.push(tabs[index as any]?.route)}
      >
        {tabs.map(tab => (
          <Tab key={tab.title} label={tab.title} />
        ))}
      </TabGroup>
      <TransitionGroup>
        <CSSTransition classNames="fade" timeout={300}>
          <Switch>
            <Route component={SettingsProfile} path={Routes.profile} />
            <Route component={SettingsUser} path={Routes.users} />
            <Route component={SettingsOutlets} path={Routes.outlets} />
            <Route component={SettingsDevices} path={Routes.devices} />
            <Route render={() => <Redirect to={Routes.profile} />} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  ) : (
    <span>No Content</span>
  );
};
