import React from "react";
import { useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { IAppState } from "../../../store";
import { getBeveragesByIds } from "../../../store/beers/reducer";
import InisghtCard from "./InsightCard";
import "./insight-cards.scss";

interface IProps {
  expanded: boolean;
  underPerformingBeverageIds: string[];
}

const UnderperformingInsightCard: React.FC<IProps> = ({ expanded, underPerformingBeverageIds }) => {
  const { managerAppProduct: content } = useContent();
  const underperformingBeverages = useSelector((state: IAppState) =>
    getBeveragesByIds(state, underPerformingBeverageIds)
  );
  const isPlural = underperformingBeverages.length > 1;

  return underperformingBeverages.length > 0 ? (
    <InisghtCard
      beverages={underperformingBeverages}
      expanded={expanded}
      icon="warning"
      status="warning"
      text={
        isPlural
          ? content.manager_app_insights_underperforming_text_plural
          : content.manager_app_insights_underperforming_text
      }
      title={(isPlural
        ? content.manager_app_insights_underperforming_plural
        : content.manager_app_insights_underperforming
      ).replace("%brand_nr%", `${underperformingBeverages.length}`)}
    />
  ) : null;
};

export default UnderperformingInsightCard;
