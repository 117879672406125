import React, { useCallback, useMemo, useState, useEffect, useContext } from "react";
import * as d3 from "d3";
import { SectionTitle } from "@ddm-design-system/typography";
import { Toggle } from "@ddm-design-system/toggle";
import { BarChart } from "@ddm-design-system/bar-chart";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { withAggRequest, IWithAggRequestProps } from "../../common/hoc/withAggRequest";
import useContent from "../../../hooks/useContent";
import { getOffHourHighlighting } from "../../../store/aggregator/selectors";
import useIsMobile from "../../../hooks/useIsMobile";
import StyleConstants from "../../../constants";
import { IAppState } from "../../../store";
import { getChosenOutletId } from "../../../store/filter/selectors";
import { getOutletLocationData } from "../../../store/outlet/selectors";
import { setOffHourHighlighting } from "../../../store/aggregator/actions";
import { getOutletLocationData as getOutletLocationDataAction } from "../../../store/outlet/actions";
import { SalesLegend } from "../../common/legend/Legend";
import { ChartTooltip } from "../charts/ChartTooltip";
import { VolumeOverTimeInfo } from "../SalesInfo";
import MissingOpeningHoursModal from "../MissingOpeningHoursModal";
import "./CardChart.scss";
import { AnalyticsContext } from "../../../services/analytics";

const TimeChartCard: React.FC<IWithAggRequestProps> = ({ data, loading, error }) => {
  const { managerAppSales: content } = useContent();
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  const offHourHighlighting = useSelector(getOffHourHighlighting);
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);
  const selectedOutletId = useSelector(getChosenOutletId);
  const outletLocationData = useSelector((state: IAppState) =>
    getOutletLocationData(state, selectedOutletId)
  );
  const [showMissingOpeningHoursModal, setShowMissingOpeningHoursModal] = useState(false);

  // get outlet location data if it is missing
  useEffect(() => {
    if (selectedOutletId && !outletLocationData) {
      dispatch(getOutletLocationDataAction(selectedOutletId));
    }
  }, [dispatch, outletLocationData, selectedOutletId]);

  // set off hour toggle off if there are no opening hours info for given outlet
  useEffect(() => {
    if (
      outletLocationData &&
      outletLocationData.openingHoursList.length === 0 &&
      offHourHighlighting
    ) {
      dispatch(setOffHourHighlighting(false));
    }
  }, [dispatch, offHourHighlighting, outletLocationData]);

  const handleToggleOffHour = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;

      if (isChecked && outletLocationData && outletLocationData.openingHoursList.length === 0) {
        setShowMissingOpeningHoursModal(true);
      } else {
        analytics.logEvent("TOGGLE_OFF_HOUR", isChecked ? "on" : "off");
        dispatch(setOffHourHighlighting(isChecked));
      }
    },
    [analytics, dispatch, outletLocationData]
  );

  const handleCloseToggleOffHour = () => {
    setShowMissingOpeningHoursModal(false);
  };

  // TODO: receive data with value: [100, 30] -> on hour / off hour
  const dataBarsPrepared = useMemo(() => {
    if (!offHourHighlighting) {
      return (data?.bars || []).map((d: any) => ({
        ...d,
        value: Array.isArray(d.value) ? d3.sum(d.value) : d.value
      }));
    }

    return data?.bars || [];
  }, [data, offHourHighlighting]);

  return (
    content && (
      <div className="card-chart">
        <div className="card-header">
          <div className="card-title">
            <SectionTitle>{content.manager_app_volume_over_time}</SectionTitle>
            <VolumeOverTimeInfo />
          </div>
          <SalesLegend onToggleOffHour={handleToggleOffHour} />
        </div>
        <div className={classnames("card-content no-card", { loading, error })}>
          {/* {loading && <Body className="loading-text">{loading && "Loading..."}</Body>} */}
          <BarChart
            domain={data?.bars?.map((b: any) => b.label) || []}
            data={dataBarsPrepared}
            lineData={data?.line || []}
            height={300}
            tooltipElement={ChartTooltip}
            margin={{ top: 5, left: 40, right: 0, bottom: 20 }}
            tooltipYOffset={-100}
          />
        </div>
        {isMobile && !!selectedOutletId && (
          <div className="card-legend-toggle">
            <Toggle checked={offHourHighlighting} onChange={handleToggleOffHour}>
              {content.manager_app_sales_highlight_off_hour}
            </Toggle>
          </div>
        )}
        <MissingOpeningHoursModal
          isOpen={showMissingOpeningHoursModal}
          outletId={selectedOutletId}
          onClose={handleCloseToggleOffHour}
        />
      </div>
    )
  );
};

export default withAggRequest(TimeChartCard);
