export const SET_OUTLET = "outlet/SET_OUTLET";
export const SET_OUTLET_PICKER_TAB = "outlet/SET_OUTLET_PICKER_TAB";
export const SET_OUTLETS = "outlet/SET_OUTLETS";
export const REQUEST_OUTLETS = "outlet/REQUEST_OUTLETS";
export const REQUEST_OUTLETS_SUCCESS = "outlet/REQUEST_OUTLET_SUCCESS";
export const REQUEST_OUTLETS_ERROR = "outlet/REQUEST_OUTLET_ERROR";
export const SET_OUTLET_VIEWED = "outlet/SET_OUTLET_VIEWED";
export const SET_QUESTIONNAIRE_SUBMITTED = "outlet/SET_QUESTIONNAIRE_SUBMITTED";
export const GET_OUTLET_LOCATION_DATA = "outlet/GET_OUTLET_LOCATION_DATA";
export const GET_OUTLET_LOCATION_DATA_SUCCESS = "outlet/GET_OUTLET_LOCATION_DATA_SUCCESS";
export const SET_OUTLET_GOOGLE_LOCATION_DATA = "outlet/SET_OUTLET_GOOGLE_LOCATION_DATA";
export const SET_OUTLET_MANUAL_LOCATION_DATA = "outlet/SET_OUTLET_MANUAL_LOCATION_DATA";
export const SET_OUTLET_FAVOURITE = "outlet/SET_OUTLET_FAVOURITE";
export const SET_OUTLET_FAVOURITE_SUCCESS = "outlet/SET_OUTLET_FAVOURITE_SUCCESS";
export const SET_OUTLET_FAVOURITE_ERROR = "outlet/SET_OUTLET_FAVOURITE_ERROR";

export interface IOutlet {
  timezone: string;
  id: string;
  children: string[];
  master: string;
  name: string;
  newAssociation?: boolean;
  userAssociationTime?: string;
  questionnaireSubmitted?: boolean;
  favourite?: boolean;
  stockAccess?: boolean;
}

export interface IOutletState {
  currentOutlet: IOutlet | null;
  currentOutletPickerTab: number;
  outlets: IOutlet[];
  outletsError: boolean;
  outletsLocationData: { [key: string]: ILocationData };
}

export interface ILocationData {
  googleBusinessName?: string;
  googleBusinessId?: string;
  address?: string;
  openingHoursList: ILocationOpeningHour[];
}

export interface ILocationOpeningHour {
  startTime: number;
  endTime: number;
  weekday: number;
}

export interface IGoogleLocationDataInput {
  googleBusinessName?: string;
  googleBusinessId: string;
  address: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  locationOpeningHours: ILocationOpeningHour[];
}

export interface IManualLocationDataInput {
  address: string;
  locationOpeningHours: ILocationOpeningHour[];
}

export type LocationDataInput = IGoogleLocationDataInput | IManualLocationDataInput;

export interface ISetOutlet {
  type: typeof SET_OUTLET;
  payload: IOutlet | null;
}

export interface ISetOutletPickerTab {
  type: typeof SET_OUTLET_PICKER_TAB;
  payload: number;
}

export interface ISetOutlets {
  type: typeof SET_OUTLETS;
  payload: IOutlet[];
}

export interface IRequestOutlets {
  type: typeof REQUEST_OUTLETS;
}

export interface IRequestOutletsError {
  type: typeof REQUEST_OUTLETS_ERROR;
}

export interface ISetOutletViewed {
  type: typeof SET_OUTLET_VIEWED;
  payload: string;
}

export interface ISetQuestionnaireSubmitted {
  type: typeof SET_QUESTIONNAIRE_SUBMITTED;
  payload: string;
  skip?: boolean;
}

export interface IGetOutletLocationData {
  type: typeof GET_OUTLET_LOCATION_DATA;
  payload: {
    outletId: string;
    force?: boolean;
  };
}

export interface IGetOutletLocationDataSuccess {
  type: typeof GET_OUTLET_LOCATION_DATA_SUCCESS;
  payload: {
    outletId: IOutlet["id"];
    data: ILocationData;
  };
}

export interface ISetOutletGoogleLocationData {
  type: typeof SET_OUTLET_GOOGLE_LOCATION_DATA;
  payload: {
    outletId: IOutlet["id"];
    outletName: string;
    placeId: string;
  };
}

export interface ISetOutletManualLocationData {
  type: typeof SET_OUTLET_MANUAL_LOCATION_DATA;
  payload: {
    outletId: IOutlet["id"];
    outletName: string;
    data: IManualLocationDataInput;
  };
}

export interface ISetOutletFavourite {
  type: typeof SET_OUTLET_FAVOURITE;
  payload: {
    outletId: IOutlet["id"];
    favourite: boolean;
  };
}

export interface ISetOutletFavouriteSuccess {
  type: typeof SET_OUTLET_FAVOURITE_SUCCESS;
  payload: {
    outletId: IOutlet["id"];
    favourite: boolean;
  };
}

export interface ISetOutletFavouriteError {
  type: typeof SET_OUTLET_FAVOURITE_ERROR;
  payload: {
    outletId: IOutlet["id"];
    favourite: boolean;
  };
}

export type OutletActionTypes =
  | ISetOutlet
  | ISetOutletPickerTab
  | ISetOutlets
  | IRequestOutlets
  | IRequestOutletsError
  | ISetOutletViewed
  | ISetQuestionnaireSubmitted
  | IGetOutletLocationData
  | IGetOutletLocationDataSuccess
  | ISetOutletGoogleLocationData
  | ISetOutletManualLocationData
  | ISetOutletFavourite
  | ISetOutletFavouriteSuccess
  | ISetOutletFavouriteError;
