import { createSelector } from "reselect";
import { IAppState } from "../index";
import { IOutlet, IOutletTags, IPressureChamber, IFaultySensor } from "./types";
import { getChosenOutletId } from "../filter/selectors";
import { getBeverages } from "../beers/reducer";
import { IBeverageTranslation } from "../beers/types";

export const getOutlets = (state: IAppState) => state.installation.outlets;
export const getIsLoadingPressureChambers = (state: IAppState) =>
  state.installation.loadingPressureChambers;

export const getAllPressureChambers = (state: IAppState) =>
  Object.values(state.installation.outlets).reduce(
    (acc: IPressureChamber[], outlet) => [...acc, ...outlet.pressureChambers],
    []
  );

export const getError = (state: IAppState) => state.installation.error;

export const getTags = (outlet: any) => {
  if (!outlet) {
    return {
      dangerTags: "--",
      offlineTags: "--",
      okTags: "--",
      warningTags: "--"
    };
  }

  const { pressureChambers } = outlet;

  return {
    dangerTags: pressureChambers.filter((p: IPressureChamber) => !p.offline && p.dangerTags > 0)
      .length,
    offlineTags: pressureChambers.filter((p: IPressureChamber) => p.offline).length,
    okTags: pressureChambers.filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags <= 0 && p.warningTags <= 0
    ).length,
    warningTags: pressureChambers.filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags <= 0 && p.warningTags > 0
    ).length
  };
};

export const getAllPressureChambersTags = createSelector(getOutlets, outlets => {
  return Object.values(outlets).reduce(
    (acc: IOutletTags, outlet: any) => {
      const tags = getTags(outlet);

      return {
        dangerTags: acc.dangerTags + tags.dangerTags,
        offlineTags: acc.offlineTags + tags.offlineTags,
        okTags: acc.okTags + tags.okTags,
        warningTags: acc.warningTags + tags.warningTags
      };
    },
    { dangerTags: 0, offlineTags: 0, okTags: 0, warningTags: 0 }
  );
});

export const getOutletPressureChambersTags = createSelector(
  getOutlets,
  getChosenOutletId,
  (outlets, selectedOutletId) => {
    const selectedOutlet = outlets?.[selectedOutletId];

    return getTags(selectedOutlet);
  }
);

export const getAllFaultyPressureChambers = createSelector(getOutlets, outlets =>
  Object.values(outlets).reduce(
    (acc: IPressureChamber[], outlet) => [
      ...acc,
      ...outlet.pressureChambers.filter(p => p.isFaulty)
    ],
    []
  )
);

export const getOutletFaultyPressureChambers = createSelector(
  getOutlets,
  getChosenOutletId,
  (outlets, selectedOutletId) => {
    const selectedOutlet = outlets?.[selectedOutletId];
    if (!selectedOutlet) {
      return [];
    }
    return selectedOutlet.pressureChambers.filter(p => p.isFaulty);
  }
);

export const getOutletsWithTranslations = createSelector(
  getOutlets,
  getBeverages,
  (outlets: { [id: string]: IOutlet }, beverages) => {
    const outletsWithTranslations: { [id: string]: IOutlet } = {};

    Object.entries(outlets).forEach(([outletId, installation]) => {
      const pressureChambers = installation.pressureChambers.map(
        (pressureChamber: IPressureChamber) => {
          const outletBeverage = beverages.find(
            (beverage: IBeverageTranslation) => beverage.id === pressureChamber.beverageId
          );

          return {
            ...pressureChamber,
            beverage: outletBeverage || pressureChamber.beverage,
            noKeg: outletBeverage?.brand === "empty"
          };
        }
      );

      outletsWithTranslations[outletId] = {
        ...installation,
        pressureChambers
      };
    });

    return outletsWithTranslations;
  }
);

export const getFaultySensors = (state: IAppState) => state.installation.faultySensors;

export const getFaultySensorsByBeverage = (state: IAppState) => {
  const beverages: {
    [key: string]: IFaultySensor[];
  } = {};

  Object.values(state.installation.faultySensors).forEach(sensors =>
    sensors.forEach(sensor => {
      beverages[sensor.beverageId] = [...(beverages[sensor.beverageId] ?? []), sensor];
    })
  );

  return beverages;
};

export const getPressureChambersWithFaultySensor = createSelector(
  getOutlets,
  outlets => (locationId: string, sensor: IFaultySensor) => {
    return outlets[locationId].pressureChambers.find(p => p.id === sensor.pressureChamberId);
  }
);

export const makeGetFaultySensorsPosition = () =>
  createSelector(
    getAllPressureChambers,
    (_: any, sensors: IFaultySensor[]) => sensors,
    (pressureChambers, sensors) =>
      sensors
        .map(sensor => pressureChambers.find(p => p.id === sensor.pressureChamberId)?.position)
        .filter(p => p !== undefined) as number[]
  );

export const getFaultySensorPosition = () =>
  createSelector(
    (state: IAppState) => state.installation.outlets,
    (_: any, locationId: string) => locationId,
    (_: any, sensor: IFaultySensor) => sensor,
    (outlets, locationId, sensor) =>
      outlets[locationId]?.pressureChambers.find(p => p.id === sensor.pressureChamberId)
  );
