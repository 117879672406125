import React, { SyntheticEvent, useMemo } from "react";
import classnames from "classnames";
import { Card, CardBody } from "@ddm-design-system/card";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { BodyHighlight, Description } from "@ddm-design-system/typography";
import { BeverageTranslation } from "../../../store/beers/types";
import useIsMobile from "../../../hooks/useIsMobile";
import StyleConstants from "../../../constants";
import "./insight-cards.scss";

const MAX_LOGOS_LENGTH = 6;
const MAX_MOBILE_LOGOS_LENGTH = 5;

export interface IProps {
  beverages?: BeverageTranslation[];
  className?: string;
  expanded?: boolean;
  icon: IconNames;
  status?: "success" | "warning";
  text: string;
  title: string;
}

const InsightCard: React.FC<IProps> = ({
  beverages,
  className,
  expanded = false,
  icon,
  status = "success",
  text,
  title
}) => {
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);
  const maxLogosLength = useMemo(() => (isMobile ? MAX_MOBILE_LOGOS_LENGTH : MAX_LOGOS_LENGTH), [
    isMobile
  ]);
  const showEllipsis = useMemo(() => beverages && beverages.length > maxLogosLength, [
    beverages,
    maxLogosLength
  ]);

  // for UI stacking order purposes, so that visually brands on the left stack over ones on the right
  const reverseBeverages = useMemo(
    () => (showEllipsis ? beverages?.slice(0, maxLogosLength).reverse() : beverages?.reverse()),
    [beverages, maxLogosLength, showEllipsis]
  );

  // dynamically set background color for broken logo imgs
  const handleImgError = (error: SyntheticEvent, beverage: BeverageTranslation) => {
    error.currentTarget.setAttribute(
      "style",
      `background-color: ${beverage.mainColor ?? "var(--color-grey-grey50)"}`
    );
  };

  const titleEl = useMemo(
    () => (
      <span className={classnames("insight-card-title", expanded && "expanded")}>
        <Icon className={classnames("insight-card-icon", className)} name={icon} />
        <BodyHighlight>{title}</BodyHighlight>
      </span>
    ),
    [className, expanded, icon, title]
  );

  return (
    <Card className={classnames("insight-card", expanded && "expanded", status)}>
      <CardBody className={classnames("insight-card-body", expanded && "expanded")}>
        {expanded ? (
          <>
            <div className="insight-content-wrapper">
              {titleEl}
              <Description>{text}</Description>
            </div>
            {!!reverseBeverages && (
              <div className="insight-card-logos">
                {showEllipsis && (
                  <Icon className="insight-card-logo insight-card-logo--ellipsis" name="ellipsis" />
                )}
                {reverseBeverages.map(beverage =>
                  beverage.logoUrl ? (
                    <img
                      className="insight-card-logo"
                      key={beverage.id}
                      src={beverage.logoUrl}
                      alt=""
                      title={beverage.name}
                      onError={error => handleImgError(error, beverage)}
                    />
                  ) : (
                    <div
                      className="insight-card-logo insight-card-logo--fallback"
                      key={beverage.id}
                      style={{ backgroundColor: beverage.mainColor }}
                      title={beverage.name}
                    />
                  )
                )}
              </div>
            )}
          </>
        ) : (
          titleEl
        )}
      </CardBody>
    </Card>
  );
};

export default InsightCard;
