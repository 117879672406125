import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ModalTooltip } from "@ddm-design-system/modal";
import { Body } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import {
  getOutletPressureChambersTags,
  getAllPressureChambersTags
} from "../../store/installation/selectors";
import useContent from "../../hooks/useContent";
import { getChosenOutletId } from "../../store/filter/selectors";
import { IOutletTags } from "../../store/installation/types";
import { AnalyticsContext } from "../../services/analytics";

const InstallationStatusTooltip: React.FC = () => {
  const { managerAppCommon: common, managerAppOverview: content } = useContent();
  const analytics = useContext(AnalyticsContext);

  const selectedOutletId: string = useSelector(getChosenOutletId);
  const { okTags, warningTags, dangerTags, offlineTags }: IOutletTags = useSelector(
    selectedOutletId ? getOutletPressureChambersTags : getAllPressureChambersTags
  );

  const okDescription = (content.manager_app_pressure_chambers_status_description_ok || "").split(
    "%SPLIT%"
  );
  const warningDescription = (
    content.manager_app_pressure_chambers_status_description_warning || ""
  ).split("%SPLIT%");
  const errorDescription = (
    content.manager_app_pressure_chambers_status_description_error || ""
  ).split("%SPLIT%");
  const offlineDescription = (
    content.manager_app_pressure_chambers_status_description_offline || ""
  ).split("%SPLIT%");
  const noneOfflineDescription = (content.manager_app_pressure_chambers_none_offline || "").split(
    "%SPLIT%"
  );

  return (
    <ModalTooltip
      titleIcon={<Icon name="installation" fill="var(--color-grey-grey50)" />}
      title={content.manager_app_pressure_chambers_status}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Installation Status")}
    >
      <Body>
        {content.manager_app_pressure_chambers_status_description}
        <br />
        <br />
        <b>{okTags}</b>
        {okDescription[0]}
        <span style={{ textTransform: "capitalize", color: "var(--color-success-success100" }}>
          <b>{common.common_ok.toLocaleLowerCase()}</b>
        </span>
        {okDescription[1]}
        <br />
        <br />

        <b>{warningTags}</b>
        {warningDescription[0]}
        <span style={{ textTransform: "capitalize", color: "var(--color-warning-warning100" }}>
          <b>{common.common_warning.toLocaleLowerCase()}</b>
        </span>
        {warningDescription[1]}
        <br />
        <br />
        <b>{dangerTags}</b>
        {errorDescription[0]}
        <span style={{ textTransform: "capitalize", color: "var(--color-alert-alert100" }}>
          <b>{common.common_error.toLocaleLowerCase()}</b>
        </span>
        {errorDescription[1]}
        <br />
        <br />
        {offlineTags > 0 ? (
          <>
            <b>{offlineTags}</b>
            {offlineDescription[0]}
            <span style={{ textTransform: "capitalize", color: "var(--color-grey-grey50" }}>
              <b>{common.common_offline.toLocaleLowerCase()}</b>
            </span>
            {offlineDescription[1]}
          </>
        ) : (
          <>
            {noneOfflineDescription[0]}
            <span style={{ textTransform: "capitalize", color: "var(--color-grey-grey50" }}>
              <b>{common.common_offline.toLocaleLowerCase()}</b>
            </span>
            {noneOfflineDescription[1]}
          </>
        )}
      </Body>
    </ModalTooltip>
  );
};

export default InstallationStatusTooltip;
