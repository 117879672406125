import React from "react";
import classnames from "classnames";
import { Divider } from "@ddm-design-system/divider";
import { Body, DescriptionMedium, LabelMedium } from "@ddm-design-system/typography";
import Time from "../../lib/Time/Time";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import { Keg } from "../common/keg/keg";
import "./pressurechamber-info.scss";

interface IProps {
  className?: string;
  pressureChamber: IPressureChamber;
}

export const PressureChamberInfo: React.FC<IProps> = ({ className, pressureChamber }) => {
  const { managerAppCommon: content, managerAppInstallation: productContent, units } = useContent();

  const lastDraught = pressureChamber.lastUsed
    ? new Time(pressureChamber.lastUsed).getTimeAgo(units)
    : "--";

  const isBrandEmpty = pressureChamber.noKeg;
  const isSubbrandEmpty = pressureChamber.noKeg;

  return (
    <div className={classnames("pressurechamber-info", className)}>
      <Keg pressureChamber={pressureChamber} />
      <div className="keg-description">
        {isBrandEmpty && isSubbrandEmpty ? (
          <Body className="keg-brand--empty">--</Body>
        ) : (
          <>
            <Body className="keg-brand" title={isBrandEmpty ? "" : pressureChamber.beverage.brand}>
              {isBrandEmpty ? "--" : pressureChamber.beverage.brand}
            </Body>
            <Body
              className="keg-subbrand"
              title={isSubbrandEmpty ? "" : pressureChamber.beverage.subbrand}
            >
              {isSubbrandEmpty ? "" : pressureChamber.beverage.subbrand}
              &nbsp;
            </Body>
          </>
        )}

        <Divider />
        <div className="keg-details">
          <DescriptionMedium>
            <span className="keg-label">{content.common_keg} </span>
            {pressureChamber.position}
          </DescriptionMedium>
          <Divider orientation="vertical" />
          <DescriptionMedium>
            <span className="keg-label">{content.common_tap} </span>
            {pressureChamber.tapPosition?.join(", ") || "--"}
          </DescriptionMedium>
        </div>
        <Divider />
        <div className="keg-lastdraught">
          <LabelMedium className="keg-label">{productContent.last_draught} </LabelMedium>
          <LabelMedium>{lastDraught}</LabelMedium>
        </div>
      </div>
    </div>
  );
};

export default PressureChamberInfo;
