import React from "react";
import { useSelector } from "react-redux";
import { ModalTooltip } from "@ddm-design-system/modal";
import { Body } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import useContent from "../../../hooks/useContent";
import { getOutletsWithTargetsCount } from "../../../store/filter/selectors";
import { getAllOutlets } from "../../../store/outlet/selectors";

interface IProps {
  isPerformancePage?: boolean;
}

const VsTargetsTooltip: React.FC<IProps> = ({ isPerformancePage = false }) => {
  const { managerAppCommon: content } = useContent();
  const outletsWithTargetsCount = useSelector(getOutletsWithTargetsCount) || 0;
  const allOutletsNr = useSelector(getAllOutlets).length;

  return outletsWithTargetsCount > 0 ? (
    <ModalTooltip
      icon="warning"
      titleIcon={<Icon name="warning" fill="var(--color-warning-warning100)" />}
      title={content.vs_targets_tooltip_title}
    >
      <Body>
        {content.vs_targets_tooltip_text}
        <br />
        <br />
        {isPerformancePage && (
          <>
            {content.vs_targets_tooltip_text_compare}
            <br />
            <br />
          </>
        )}
        {content.vs_targets_tooltip_text_outlets
          .replace("%current_targets_nr%", `${outletsWithTargetsCount}`)
          .replace("%total_targets_nr%", `${allOutletsNr}`)}
      </Body>
    </ModalTooltip>
  ) : (
    <></>
  );
};

export default VsTargetsTooltip;
