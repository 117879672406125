import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Description } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { Toggle } from "@ddm-design-system/toggle";
import useContent from "../../../hooks/useContent";
import { PERFORMANCE_TYPE } from "../../../store/filter/types";
import { getFilter, isOutletChosen } from "../../../store/filter/selectors";
import { getOffHourHighlighting } from "../../../store/aggregator/selectors";
import useIsMobile from "../../../hooks/useIsMobile";
import StyleConstants from "../../../constants";
import "./legend.scss";

interface ILegendItem {
  className: string;
  text: string;
}
interface IProps {
  legend: ILegendItem[];
  isCompare?: boolean;
}
export const Legend: React.FC<IProps> = ({ isCompare = false, legend }) => (
  <div className="card-chart-legend">
    {legend.map((l: any, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="card-chart-legend-item" key={index}>
        <div
          className={classnames("card-chart-legend-color", l.className, isCompare && "is-compare")}
        />
        <Description className="card-chart-legend-text">{l.text}</Description>
      </div>
    ))}
  </div>
);

export const SalesLegend: React.FC<{
  onToggleOffHour?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hideCompare?: boolean;
}> = ({ onToggleOffHour, hideCompare = false }) => {
  const {
    managerAppOverview: content,
    managerAppCommon: common,
    managerAppSales: contentSales
  } = useContent();
  const { performanceType, outletCompare } = useSelector(getFilter);
  const isOutletSet = useSelector(isOutletChosen);
  const offHourHighlighting = useSelector(getOffHourHighlighting);
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);

  return (
    <div className="card-chart-legend">
      {!isMobile && onToggleOffHour && isOutletSet && (
        <div className="card-chart-legend-toggle">
          <Toggle checked={offHourHighlighting} onChange={onToggleOffHour}>
            {contentSales.manager_app_sales_highlight_off_hour}
          </Toggle>
        </div>
      )}
      <div className="card-chart-legend-item">
        <div className="card-chart-legend-color main" />
        <Description className="card-chart-legend-text">
          {isOutletSet ? common.your_outlet : common.all_outlets}
        </Description>
      </div>
      {!(performanceType === PERFORMANCE_TYPE.TARGET && hideCompare) && (
        <div className="card-chart-legend-item">
          {/* <div className="card-chart-legend-color compare" /> */}
          <Icon
            className="card-chart-legend-color"
            name="vs"
            fill="var(--color-warning-warning100)"
          />
          <Description className="card-chart-legend-text">
            {performanceType === PERFORMANCE_TYPE.OUTLET
              ? outletCompare?.name
              : content[
                  `manager_app_recent_sales_${PERFORMANCE_TYPE[
                    performanceType
                  ].toLowerCase()}_title`
                ]}
          </Description>
        </div>
      )}
    </div>
  );
};
export default Legend;
