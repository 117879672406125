import {
  IRequestPressureChambers,
  IRequestPressureChambersError,
  IRequestPressureChambersSuccess,
  REQUEST_PRESSURE_CHAMBERS,
  REQUEST_PRESSURE_CHAMBERS_ERROR,
  REQUEST_PRESSURE_CHAMBERS_SUCCESS,
  SET_PRESSURE_CHAMBER_BEVERAGE,
  ISetPressureChamberBeverage,
  SET_PRESSURE_CHAMBER_BEVERAGE_ERROR,
  GET_FAULTY_SENSORS,
  GET_FAULTY_SENSORS_SUCCESS,
  GET_FAULTY_SENSORS_ERROR,
  IInstallationState
} from "./types";
import { IBeverage } from "../beers/types";

export const requestPressureChambers = (locationId: string): IRequestPressureChambers => ({
  type: REQUEST_PRESSURE_CHAMBERS,
  payload: locationId
});

export const requestPressureChambersError = (): IRequestPressureChambersError => ({
  type: REQUEST_PRESSURE_CHAMBERS_ERROR
});

export const requestPressureChambersSuccess = (data: object): IRequestPressureChambersSuccess => ({
  type: REQUEST_PRESSURE_CHAMBERS_SUCCESS,
  payload: data
});

export const setPressureChamberBeverage = (
  outletId: string,
  thingId: string,
  beverage: IBeverage,
  oldBeverageName?: string
): ISetPressureChamberBeverage => ({
  type: SET_PRESSURE_CHAMBER_BEVERAGE,
  payload: { outletId, thingId, beverage, oldBeverageName }
});

export const setPressureChamberBeverageError = () => ({
  type: SET_PRESSURE_CHAMBER_BEVERAGE_ERROR
});

export const getFaultySensors = (
  locationIds: string[],
  startTimestamp: number,
  endTimestamp: number
) => ({
  type: GET_FAULTY_SENSORS,
  payload: {
    locationIds,
    startTimestamp,
    endTimestamp
  }
});

export const getFaultySensorsSuccess = (faultySensors: IInstallationState["faultySensors"]) => ({
  type: GET_FAULTY_SENSORS_SUCCESS,
  payload: faultySensors
});

export const getFaultySensorsError = () => ({
  type: GET_FAULTY_SENSORS_ERROR
});
