import { call, takeEvery } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IAnalyticsService } from "../services/analytics";
import {
  ISetFilterOutlets,
  ISetTimeFrame,
  ISetBeerIds,
  SET_FILTER_OUTLETS,
  SET_TIME_FRAME,
  SET_BEER_IDS,
  SET_COMPARE_OUTLET,
  ISetCompareOutlet
} from "../store/filter/types";

export function* logFilterOutlets(analyticsService: IAnalyticsService, action: ISetFilterOutlets) {
  yield call([analyticsService, analyticsService.logEvent], "FILTER_OUTLETS");
}

export function* logFilterTimeFrame(analyticsService: IAnalyticsService, action: ISetTimeFrame) {
  yield call(
    [analyticsService, analyticsService.logEvent],
    "FILTER_TIMEFRAME",
    action.payload.type
  );
}

export function* logFilterBeers(analyticsService: IAnalyticsService, action: ISetBeerIds) {
  yield call([analyticsService, analyticsService.logEvent], "FILTER_BEERS");
}

export function* logCompare(analyticsService: IAnalyticsService, action: ISetCompareOutlet) {
  yield call(
    [analyticsService, analyticsService.logEvent],
    "COMPARE_OUTLET",
    action.payload ? action.payload.id : ""
  );
}

export function* analyticsWatcher(context: IAppContext) {
  yield takeEvery(SET_FILTER_OUTLETS, logFilterOutlets, context.analyticsService);
  yield takeEvery(SET_TIME_FRAME, logFilterTimeFrame, context.analyticsService);
  yield takeEvery(SET_BEER_IDS, logFilterBeers, context.analyticsService);
  yield takeEvery(SET_COMPARE_OUTLET, logCompare, context.analyticsService);
}

export default analyticsWatcher;
