import React, { useCallback } from "react";
import classnames from "classnames";
import { Card, CardBody } from "@ddm-design-system/card";
import { IconMenu, MenuItem } from "@ddm-design-system/menu";
import { Body } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import PressureChamberInfo from "./PressureChamberInfo";
import PressureChamberBadges from "./PressureChamberBadges";
import "./pressurechamber-card.scss";

interface IProps {
  pressureChamber: IPressureChamber;
  onEdit: (pressureChamber: IPressureChamber) => void;
}

export const PressureChamberCard: React.FC<IProps> = ({ pressureChamber, onEdit }) => {
  const { managerAppInstallation: content } = useContent();

  const handleEdit = useCallback(() => {
    onEdit(pressureChamber);
  }, [onEdit, pressureChamber]);
  return (
    <Card
      className={classnames("device-card real", {
        error: pressureChamber.offline || pressureChamber.dangerTags > 0
      })}
      key={pressureChamber.id}
    >
      <CardBody className="device-card-body">
        <div className="device-card-body-content">
          <PressureChamberInfo pressureChamber={pressureChamber} />
          <PressureChamberBadges pressureChamber={pressureChamber} />
        </div>
        <div className="device-card-menu-wrapper">
          <IconMenu icon="ellipsis" direction="bottom-right">
            <MenuItem icon="beverage" onClick={handleEdit}>
              <Body>{content.manager_app_product_change_beverage}</Body>
            </MenuItem>
          </IconMenu>
        </div>
      </CardBody>
    </Card>
  );
};

export default PressureChamberCard;
