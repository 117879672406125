import numeral from "numeral";
import { IPressureChamber } from "./store/installation/types";
import StyleConstants, { ERROR_STALE_BEER, WARNING_STALE_BEER } from "./constants";
import Time from "./lib/Time/Time";
import { IUser } from "./store/users/types";
import { BeverageTranslation } from "./store/beers/types";
import { EPerformanceLevel, IProductInfo } from "./store/product/types";

export const colorFromCSSClass = (className: string) => {
  const tmp = document.createElement("div");
  tmp.style.cssText = "position:fixed;left:-100px;top:-100px;width:1px;height:1px";
  tmp.className = className;
  document.body.appendChild(tmp); // required in some browsers
  const color = getComputedStyle(tmp).getPropertyValue("color");
  document.body.removeChild(tmp);
  return color;
};

export const composeStyles = (
  baseStyles: { [key: string]: string },
  customStyles?: { [key: string]: string },
  composeStyle = "merge"
) => {
  if (!customStyles) {
    return baseStyles;
  }

  const composedStyles: { [key: string]: string } = {};
  const keys = Object.keys(baseStyles);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    let newValue = baseStyles[key];

    if (customStyles[key]) {
      if (composeStyle === "overwrite") {
        newValue = customStyles[key];
      } else {
        newValue += ` ${customStyles[key]}`;
      }
    }
    composedStyles[key] = newValue;
  }
  return composedStyles;
};

export function calculatePercentageChange(
  last: number | undefined = 0,
  first: number | undefined = 0
) {
  let val = 0;
  if (!(last === 0 && first === 0)) {
    val = first === 0 ? 100 : ((last - first) / first) * 100;
  }
  return val;
}

export function calculateNotificationBadges(pressureChamber: IPressureChamber) {
  const pressureChamberTag = [];

  if (pressureChamber.offline) {
    pressureChamberTag.push({
      icon: "offline",
      type: "connection",
      level: "danger",
      desc: "connection_danger"
    });
  } else {
    pressureChamberTag.push({
      icon: "offline",
      type: "connection",
      level: "success",
      desc: "connection_ok"
    });
  }

  if (pressureChamber.isFaulty) {
    pressureChamberTag.push({
      icon: "sensor",
      type: "sensor",
      level: "danger",
      desc: "sensor_danger"
    });
  } else if (pressureChamber.offline) {
    pressureChamberTag.push({
      icon: "sensor",
      type: "sensor",
      level: "success",
      desc: "sensor_offline"
    });
  } else if (!pressureChamber.sensorConnected) {
    pressureChamberTag.push({
      icon: "sensor",
      type: "sensor",
      level: "danger",
      desc: "sensor_not_connected"
    });
  } else {
    pressureChamberTag.push({
      icon: "sensor",
      type: "sensor",
      level: "success",
      desc: "sensor_ok"
    });
  }

  const diffStale = new Time(pressureChamber.lastMounted).diff(new Time(), "day");
  if (pressureChamber.offline) {
    pressureChamberTag.push({
      icon: "sleep",
      type: "stale",
      level: "disabled",
      desc: "stale_offline"
    });
  } else if (pressureChamber.volumeLeft === 0) {
    pressureChamberTag.push({
      icon: "sleep",
      type: "stale",
      level: "disabled",
      desc: "stale_empty"
    });
  } else if (diffStale >= WARNING_STALE_BEER) {
    if (diffStale >= ERROR_STALE_BEER) {
      pressureChamberTag.push({
        icon: "sleep",
        type: "stale",
        level: "danger",
        desc: "stale_danger",
        replaceValue: diffStale,
        replaceValue2: diffStale - ERROR_STALE_BEER
      });
    } else {
      pressureChamberTag.push({
        icon: "sleep",
        type: "stale",
        level: "warning",
        desc: "stale_warning",
        replaceValue: diffStale,
        replaceValue2: ERROR_STALE_BEER - diffStale
      });
    }
  } else {
    pressureChamberTag.push({
      icon: "sleep",
      type: "stale",
      level: "success",
      desc: "stale_ok",
      replaceValue: diffStale
    });
  }

  return pressureChamberTag;
}

export function accumulate(arr: any[]) {
  let sum = 0;
  const newArr: any = [];
  arr.forEach((d: any) => {
    sum += +d[1];
    if (d[0] <= new Time().getTime()) {
      newArr.push([d[0], sum]);
    }
  });
  return newArr;
}

export function getDataPerOutlet(data: any, id?: string) {
  return data ? (id ? data[id] : data.all) : [];
}

export function checkOrderVariant() {
  const Window: any = window;
  const variantIdCurrentUser = "xftgXs1WSrWpdQbliqGHgA";
  const variantIdNonUser = "BWTcVJrXRUiXk8tTqgaqfQ";

  return (
    Window &&
    Window.google_optimize &&
    (Window.google_optimize.get(variantIdCurrentUser) === "1" ||
      Window.google_optimize.get(variantIdNonUser) === "1")
  );
}

export function enableExperiment(me: IUser) {
  const currentUsersList = [
    "7c7787c8-83ad-449b-adcc-cd8705693285",
    "b847b37b-a4ba-43ee-982c-f32c0c2f121b",
    "fa771a11-1bdb-3542-bf7f-a4710dca82d0",
    "613e75d1-a36e-4f1a-8e89-d0278e165b41",
    "8bca04e2-bb59-4755-9188-9da5fb2302f8",
    "4e82bb63-6bce-43ac-a450-3f553c09d501",
    "8baf4524-f280-40db-8ad8-01303a0df36e",
    "89eaddb9-af7f-4810-b4fb-893cdae028ae",
    "e04d3b31-5146-44c8-9028-3a7eb46f2463",
    "1fe7c8df-771d-46ba-960b-7bedb7866736",
    "c1da582a-5a2d-4a71-8417-3f008205a92e",
    "468427ff-b9bc-42dd-802b-e7213cd7e2c0",
    "110978d3-40b4-4e5f-9f99-7510acc8ab63",
    "b8d84294-ce50-47e8-a9b1-39343bc195af",
    "fdca2863-cd6b-44da-b968-dfe1d4fe5030",
    "36f45afb-d0dd-47e6-832a-49d01878e5a1",
    "7411ec06-905e-464a-8d1b-c402a8bf3d8f",
    "6a50ccda-a13f-4ee2-8fed-5d84fa48af76",
    "7d02c474-2c51-4432-ac90-5521804896a2",
    "cb7561c9-db03-4ce6-8f6b-bb19288842af",
    "6ac709b6-44a3-4036-80d9-f9a970d84d4e",
    "8c9432db-0333-4308-94bb-084b77fc7ce2",
    "46e92222-fcf9-4043-a173-ad7f40faf84b",
    "b912089a-0d72-4a12-8856-a55eb2c81743",
    "b85e3c23-55d1-472d-8f86-8721d5e74bc8",
    "56ae23f6-5a45-4fa8-ae82-d676e8295ce5",
    "4fdca5d0-6d9a-416f-a854-2e6a171a476c",
    "4ae674c1-ac95-4dad-b0eb-824e96756f70",
    "4c5b67c7-3820-4d13-a30f-80dbdbbc35de",
    "a120068c-0e90-4aa8-b259-dcac37599ee0",
    "3e34b46d-4cbf-4d20-b37e-b9704c0ac185",
    "e81c56ff-abbb-4832-8c67-29dab41a6057",
    "4a818caf-9a8b-46d4-be63-268dbe6afae0",
    "db44023b-7569-4926-95e3-d6d50d115c33",
    "9158ea5e-5758-44e8-ab4d-adaaf5779502",
    "84d2c6c7-4080-45ab-9cc3-1006f03e3cff",
    "93ec8314-164c-48ea-ba04-5008325b9d55",
    "807a51ee-e4b9-4cbd-8042-e779b948d074",
    "16f5e599-20ae-4e62-8ec9-d38793bd3fc5",
    "87c2d748-a441-4ca5-bf5b-28ee72aa894e",
    "49264404-673c-4b1a-baf3-f9ad92dbfc4b",
    "b254e0c1-b1f1-4a8c-814b-adc7ca7ff673",
    "25ae9be7-02dd-46f1-b0be-a837f8911d32",
    "a6e738df-dc0d-4930-9c86-2dd24b9f8aa4",
    "8ad83a10-184e-44d3-8641-19935493d2b0"
  ];

  setTimeout(() => {
    try {
      const Window: any = window;

      const id = me.permissions[0] && me.permissions[0].locationId;
      if (!id) {
        return;
      }
      if (currentUsersList.includes(id)) {
        Window.dataLayer.push({ event: "currentuser.activate" });
      } else {
        Window.dataLayer.push({ event: "nonuser.activate" });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Experiment error: ", e);
    }
  }, 500);
}

export function getIsMobile(breakpoint: number = StyleConstants.mobileBreakpoint1) {
  return window.innerWidth < breakpoint;
}

export function getIsSafari() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return (
    userAgent.includes("safari") &&
    !userAgent.includes("chrome") && // chrome
    !userAgent.includes("crios") &&
    !userAgent.includes("firefox") && // firefox
    !userAgent.includes("fxios") &&
    !userAgent.includes("opios") // opera
  );
}

// App is being run from homescreen
export function getIsStandalone() {
  // eslint-disable-next-line prefer-destructuring
  const navigator: any = window.navigator;

  return getIsSafari()
    ? navigator && navigator.standalone
    : window.matchMedia("(display-mode: standalone)").matches;
}

export function generateInitials(brand: string | undefined, subbrand: string | undefined) {
  let initials = brand ? brand[0] : "";

  const words = subbrand?.split(" ");
  const firstWord = words?.[0];
  const secondWord = words?.[1];

  if (firstWord) {
    initials += Number.isInteger(parseInt(firstWord[0]))
      ? firstWord[firstWord.length - 1]
      : firstWord[0];

    if (secondWord) {
      initials += Number.isInteger(parseInt(secondWord[0]))
        ? secondWord[secondWord.length - 1]
        : secondWord[0];
    }
  } else {
    initials += brand?.[1] || "";
    initials += brand?.[2] || "";
  }

  return initials;
}

export function getBeverageLogoOptions(beverage?: BeverageTranslation) {
  if (!beverage) {
    return {
      backgroundColor: undefined,
      src: "",
      alt: undefined,
      circleClassName: "image-avatar-circle",
      fallbackText: "--"
    };
  }

  const backgroundColor = beverage.mainColor || "var(--color-grey-grey50)";
  return {
    backgroundColor,
    src: beverage.logoUrl || "",
    alt: beverage.name,
    circleClassName: "image-avatar-circle",
    fallbackText: beverage.initials || generateInitials(beverage.brand, beverage.subbrand)
  };
}

export function validateEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
    email
  );
}

export function isInViewport(elem: Element | null, isMobile: boolean) {
  if (!elem) {
    return false;
  }

  const bounding = elem.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // for mobile, the bottom bar's height must be taken into account
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (isMobile ? windowHeight - 55 : windowHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function formatNumber(value: number, unit = "L", usePlus = true, useDecimals = false) {
  return `${numeral(value).format(
    value !== 0
      ? `${usePlus ? "+" : ""}0${useDecimals ? ".00" : ""}`
      : `0${useDecimals ? ".00" : ""}`
  )}${unit ?? ""}`;
}

export function isInteger(
  value: any,
  options: { allowNegative?: boolean; allowZero?: boolean } = {
    allowNegative: false,
    allowZero: false
  }
) {
  const { allowNegative = false, allowZero = false } = options;

  if (allowNegative) {
    return allowZero ? /^-?(0|[1-9]\d*)$/.test(value) : /^-?[1-9]\d*$/.test(value);
  }

  return allowZero ? /^(0|[1-9]\d*)$/.test(value) : /^[1-9]\d*$/.test(value);
}

export function getFeedbackFormUrl(lang: string) {
  return `https://forms.office.com/Pages/ResponsePage.aspx?id=9kctiPRQVEWapkOkZBaw8MExumNE1SJHkxO8-XQ8KSlUQlNYOFgzMFZNUEtTTlNQRksxNjhFTklSQS4u&lang=${lang}`;
}

export function getPerformanceBadgeIcon(
  performance: IProductInfo["performance"] | undefined,
  validVolumePercentage: number | null
) {
  return !validVolumePercentage
    ? "defective"
    : performance === EPerformanceLevel.overperforming
    ? "success"
    : performance === EPerformanceLevel.good
    ? "default"
    : "warning";
}

export const sleep = (milliseconds = 0) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, milliseconds)
  );
};
