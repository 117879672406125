import { useEffect, useState, useCallback } from "react";
import StyleConstants from "../constants";

function useIsMobile(breakpoint: number = StyleConstants.mobileBreakpoint1) {
  const calcIsMobile = useCallback(() => window.innerWidth < breakpoint, [
    breakpoint
  ]);
  const [isMobile, setIsMobile] = useState(calcIsMobile());
  const handleResize = useCallback(() => {
    setIsMobile(calcIsMobile());
  }, [calcIsMobile]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return isMobile;
}

export default useIsMobile;
