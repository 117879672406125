import React, { useRef, forwardRef } from "react";
import { TertiaryButton } from "@ddm-design-system/button";
import { Card, CardBody } from "@ddm-design-system/card";
import { Divider } from "@ddm-design-system/divider";
import { ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { useSelector } from "react-redux";
import { getRank, IOutletUsers, IUser } from "../../store/users/types";
import useContent from "../../hooks/useContent";
import useIsMobile from "../../hooks/useIsMobile";
import { getUser } from "../../store/profile/reducer";
import { getIsRequestingUsers } from "../../store/users/reducer";
import StyleConstants from "../../constants";
import { isInViewport } from "../../helpers";
import UserListItem from "./UserListItem";
import "./outletuserscard.scss";

interface IProps {
  outlet: IOutletUsers;
  filter: string;
  startExpanded?: boolean;
  openModal: (val: boolean) => void;
  removeMe: () => void;
}

const OutletUsersCard = forwardRef<HTMLDivElement, IProps>(
  ({ outlet, filter, startExpanded, openModal, removeMe }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile(StyleConstants.mobileBreakpoint2);
    const { managerAppSettings: contentSettings } = useContent();
    const me = useSelector(getUser);

    const requestingUsers = useSelector(getIsRequestingUsers);

    let filteredUsers: IUser[] = outlet.users.filter(
      (u: IUser) =>
        (u.name && u.name.toLowerCase().includes(filter)) ||
        u.username.toLowerCase().includes(filter)
    );

    if (filteredUsers.length === 0) {
      filteredUsers = outlet.users;
    }

    const usersWithManagePermissions = outlet.users.filter(
      (user: IUser) => getRank(user, outlet.outletId) !== "READER"
    ).length;
    const title = outlet.outletName;
    const subtitle = requestingUsers
      ? contentSettings.manager_app_settings_loading_users
      : (contentSettings.manager_app_settings_outlet_card_description || "")
          .replace("%USERS_NUMBER%", outlet.users.length.toString())
          .replace("%USERS_NUMBER2%", usersWithManagePermissions.toString());

    const handleExpand = (isExpanded: boolean) => {
      // expand animation needs to end in order for isInViewport calculation to be accurate
      setTimeout(() => {
        if (isExpanded && !isInViewport(containerRef?.current, isMobile)) {
          containerRef.current?.scrollIntoView({
            behavior: "smooth",
            block: isMobile ? "center" : "end"
          });
        }
      }, 500);
    };

    return me ? (
      <span ref={containerRef}>
        <ExpandablePanel
          wrappedRef={ref}
          initialExpanded={startExpanded}
          title={title || ""}
          subtitle={subtitle}
          renderHeaderDetails={() =>
            getRank(me, outlet.outletId) !== "READER" && (
              <TertiaryButton
                className="add-user-button"
                icon="more"
                onClick={(e: any) => {
                  e.stopPropagation();
                  openModal(true);
                }}
              >
                {contentSettings.manager_app_settings_add_user_to_outlet}
              </TertiaryButton>
            )
          }
          disabled={filteredUsers.length === 0}
          onClick={handleExpand}
        >
          <div className="users-container">
            {requestingUsers ? (
              <div className="loading-users-container">
                <div className="loading-users">&nbsp;</div>
              </div>
            ) : (
              filteredUsers.map((user: IUser) =>
                isMobile ? (
                  <Card className="user-card" key={user.username}>
                    <CardBody>
                      <UserListItem outletId={outlet.outletId} user={user} removeMe={removeMe} />
                    </CardBody>
                  </Card>
                ) : (
                  <React.Fragment key={user.username}>
                    <Divider />
                    <UserListItem outletId={outlet.outletId} user={user} removeMe={removeMe} />
                  </React.Fragment>
                )
              )
            )}
          </div>
        </ExpandablePanel>
      </span>
    ) : (
      <span />
    );
  }
);

export default OutletUsersCard;
