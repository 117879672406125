import useIsMobile from "./useIsMobile";
import StyleConstants from "../constants";

function useIsTablet() {
  const isMobileWide = useIsMobile(StyleConstants.mobileBreakpoint1);
  const isMobileNarrow = useIsMobile(StyleConstants.mobileBreakpoint3);

  return isMobileWide && !isMobileNarrow;
}

export default useIsTablet;
