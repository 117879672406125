import React from "react";
import classNames from "classnames";
import { Infoicon } from "@ddm-design-system/infoicon";
import { DescriptionMedium } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import { getBeverageLogoOptions } from "../../helpers";
import "./pressurechamber-summary.scss";

interface IProps {
  className?: string;
  pressureChamber: IPressureChamber;
}

export const PressureChamberSummary: React.FC<IProps> = ({ className, pressureChamber }) => {
  const { managerAppCommon: content } = useContent();

  const title = (
    <div className="keg-summary-title">
      <DescriptionMedium className="keg-summary-title--left">
        <span className="keg-label">{content.common_keg} </span>
        {pressureChamber.position}
      </DescriptionMedium>
      <DescriptionMedium>
        <span className="keg-label">{content.common_tap} </span>
        {pressureChamber.tapPosition}
      </DescriptionMedium>
    </div>
  );

  return (
    <Infoicon
      className={classNames("keg-summary", className)}
      title={title}
      withPadding
      avatarOptions={getBeverageLogoOptions(pressureChamber.beverage)}
    >
      {`${pressureChamber.beverage.name}`}
    </Infoicon>
  );
};

export default PressureChamberSummary;
