import React, { useState, useContext } from "react";
import { Flipped } from "react-flip-toolkit";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { NotificationContext } from "@ddm-design-system/notification";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { BodyHighlight, PageTitle } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";
import { AnalyticsContext } from "../../services/analytics";
import { validateEmail } from "../../helpers";

interface IProps {
  username: string;
  setUsername: (s: string) => void;
  setForgotPassword: (b: boolean) => void;
}
export const RecoverPassword: React.FC<IProps> = ({ username, setUsername, setForgotPassword }) => {
  const { managerAppLogin: content, managerAppCommon: commonContent } = useContent();
  const [emailError, setEmailError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const auth = useContext(AuthContext);
  const analytics = useContext(AnalyticsContext);
  const notificationContext = useContext(NotificationContext);

  const sendRecoveryEmail = (email: string) => {
    setEmailError(false);
    if (
      // eslint-disable-next-line no-useless-escape
      !validateEmail(email)
    ) {
      setEmailError(true);
      return false;
    }
    try {
      auth.sendRecoveryEmail(email);
      analytics.logEvent("FORGOT_PASSWORD", email);
      notificationContext.addNotification({
        iconProps: { name: "ok" },
        text: content.manager_app_forgot_password_success
      });
      return true;
    } catch (e) {
      notificationContext.addNotification({
        iconProps: { name: "error" },
        text: content.manager_app_forgot_password_error
      });
      return true;
    }
  };

  const auxForgotPassword = () => {
    if (emailSent) {
      return;
    }
    setEmailSent(true);
    const result = sendRecoveryEmail(username);
    if (result) {
      setEmailSent(false);
    } else {
      setEmailError(true);
    }
  };

  return (
    <>
      <Flipped flipId="title">
        <BodyHighlight className="title-dark">{content.manager_app_login_title}</BodyHighlight>
      </Flipped>
      <PageTitle className="page-title fade-in">
        {content.manager_app_forgot_password_title}
      </PageTitle>
      <form className="login-form-container margin-top--md" onSubmit={e => e.preventDefault()}>
        <Flipped flipId="email-field">
          <ErrorTextInput
            autoComplete="username"
            error={emailError ? content.manager_app_forgot_password_error : undefined}
            label={content.manager_app_email}
            name="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Flipped>
        <div className="button-container">
          <SecondaryButton className="cancel-button" onClick={() => setForgotPassword(false)}>
            {commonContent.common_cancel}
          </SecondaryButton>
          <Flipped flipId="confirm-button">
            <PrimaryButton disabled={username === ""} type="submit" onClick={auxForgotPassword}>
              {commonContent.common_confirm}
            </PrimaryButton>
          </Flipped>
        </div>
      </form>
    </>
  );
};
export default RecoverPassword;
