import { BeverageTranslation, ICountry, IBeverage } from "../beers/types";
import { BeerType } from "../../services/aggregator";

export const REQUEST_PRESSURE_CHAMBERS = "installation/REQUEST_PRESSURE_CHAMBERS";
export const REQUEST_PRESSURE_CHAMBERS_SUCCESS = "installation/REQUEST_PRESSURE_CHAMBERS_SUCCESS";
export const REQUEST_PRESSURE_CHAMBERS_ERROR = "installation/REQUEST_PRESSURE_CHAMBERS_ERROR";
export const SET_PRESSURE_CHAMBER_BEVERAGE = "installation/SET_PRESSURE_CHAMBER_BEVERAGE";
export const SET_PRESSURE_CHAMBER_BEVERAGE_ERROR =
  "installation/SET_PRESSURE_CHAMBER_BEVERAGE_ERROR";
export const GET_FAULTY_SENSORS = "installation/GET_FAULTY_SENSORS";
export const GET_FAULTY_SENSORS_SUCCESS = "installation/GET_FAULTY_SENSORS_SUCCESS";
export const GET_FAULTY_SENSORS_ERROR = "installation/GET_FAULTY_SENSORS_ERROR";

export interface IInstallationState {
  outlets: { [id: string]: IOutlet };
  loadingPressureChambers: boolean;
  error: boolean;
  faultySensors: {
    [key: string]: IFaultySensor[];
  };
}

export interface IFaultySensor {
  beverageId: string;
  pressureChamberId: string;
  startDateTime: number;
  endDateTime: number;
  position: number;
}

export interface IOutletTags {
  dangerTags: number | string;
  offlineTags: number | string;
  okTags: number | string;
  warningTags: number | string;
}

export interface IOutlet {
  id?: string;
  name: string;
  outletId: string;
  outletName: string;
  offlinePressureChambers: number;
  lastTimestamp: any;
  pressureChambers: IPressureChamber[];
}

export interface IPressureChamber {
  id: string;
  thingId: string;
  name: string;
  parentName: string;
  volumeLeft: number;
  beverage: BeverageTranslation;
  beverageId?: string;
  position: number;
  tapPosition: number[];
  serialNumber: string;
  tags: any[];
  successTags: number;
  warningTags: number;
  dangerTags: number;
  noKeg: boolean;
  lastMounted: number;
  lastUsed: number;
  lastCleaning: number | null;
  temperature: number;
  offline: boolean;
  sensorConnected: boolean;
  lastTimestamp: number;
  isFaulty: boolean;
}

export interface IApiDevice {
  id: string;
  name: string;
  beveragesTranslations: { [key: string]: BeverageTranslation };
  pressureChambers: IApiPressureChamber[];
}

export interface IApiPressureChamber {
  id: string;
  thingId: string;
  name: string;
  lastHeartbeat: number;
  lastMounted: string;
  lastUsed: string;
  lastCleaning: string;
  temperature: number;
  firmware: string;
  position: number;
  taps: Array<{ id: string; position: number }>;
  serialNumber: string;
  open: boolean;
  alive: boolean;
  used: boolean;
  keg: IApiKeg;
  sensorConnected: boolean;
  faulty: boolean;
}

export interface IApiKeg {
  id: string;
  volume: number;
  volumeLeft: number;
  beverage: IApiBeverage;
}

export interface IApiBeverage {
  id: string;
  brand: string;
  subbrand: string;
  active: boolean;
  type?: string;
  logoUrl?: string;
  locale?: string;
  description?: string;
  countryOfOrigin?: string;
  abv?: number;
  carlsShopUrl?: string;
  mainColor?: string;
  initials?: string;
  craftAndSpeciality?: BeerType;
  countries: ICountry[];
}

export interface IPressureChamberBeveragePayload {
  outletId: string;
  beverage: IBeverage;
  thingId: string;
  oldBeverageName?: string;
}

export interface IRequestPressureChambers {
  type: typeof REQUEST_PRESSURE_CHAMBERS;
  payload: string;
}

export interface IRequestPressureChambersSuccess {
  type: typeof REQUEST_PRESSURE_CHAMBERS_SUCCESS;
  payload: object;
}

export interface IRequestPressureChambersError {
  type: typeof REQUEST_PRESSURE_CHAMBERS_ERROR;
}

export interface ISetPressureChamberBeverage {
  type: typeof SET_PRESSURE_CHAMBER_BEVERAGE;
  payload: IPressureChamberBeveragePayload;
}

export interface ISetPressureChamberBeverageError {
  type: typeof SET_PRESSURE_CHAMBER_BEVERAGE_ERROR;
}

export interface IGetFaultySensors {
  type: typeof GET_FAULTY_SENSORS;
  payload: {
    locationIds: string[];
    startTimestamp: number;
    endTimestamp: number;
  };
}

export interface IGetFaultySensorsSuccess {
  type: typeof GET_FAULTY_SENSORS_SUCCESS;
  payload: IInstallationState["faultySensors"];
}

export interface IGetFaultySensorsError {
  type: typeof GET_FAULTY_SENSORS_ERROR;
}

export type InstallationActionTypes =
  | IRequestPressureChambers
  | IRequestPressureChambersSuccess
  | IRequestPressureChambersError
  | ISetPressureChamberBeverage
  | ISetPressureChamberBeverageError
  | IGetFaultySensors
  | IGetFaultySensorsSuccess
  | IGetFaultySensorsError;
