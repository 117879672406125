import React from "react";
import { SectionTitle } from "@ddm-design-system/typography";
import classnames from "classnames";
import { BarChart } from "@ddm-design-system/bar-chart";
import { useSelector } from "react-redux";
import { IWithAggRequestProps, withAggRequest } from "../../common/hoc/withAggRequest";
import { SalesLegend } from "../../common/legend/Legend";
import useContent from "../../../hooks/useContent";
import { ChartTooltip } from "../charts/ChartTooltip";
import { AveragePerformancePerHourInfo, AveragePerformancePerWeekdayInfo } from "../SalesInfo";
import { getFilter } from "../../../store/filter/selectors";
import { PERFORMANCE_TYPE } from "../../../store/filter/types";

const GWeek: React.FC<IWithAggRequestProps> = ({ data, loading, error }) => {
  const { managerAppSales: content } = useContent();

  const noLineData = data?.line?.[0] === undefined;

  return content && data ? (
    <div className="card-chart">
      <div className="card-header">
        <div className="card-title">
          <SectionTitle>{content.manager_app_average_per_week_day}</SectionTitle>
          <AveragePerformancePerWeekdayInfo />
        </div>
        <SalesLegend />
      </div>
      <div className={classnames("card-content no-card", { loading, error })}>
        {/* {loading && <Body className="loading-text">{loading && "Loading..."}</Body>} */}
        <BarChart
          domain={data?.bars?.map((b: any) => b.label) || []}
          data={data?.bars || []}
          lineData={noLineData ? [] : data?.line || []}
          height={300}
          tooltipElement={ChartTooltip}
          margin={{ top: 5, left: 40, right: 0, bottom: 20 }}
          tooltipYOffset={-100}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

const GHour: React.FC<IWithAggRequestProps> = ({ data, loading, error }) => {
  const { managerAppSales: content } = useContent();
  const { performanceType } = useSelector(getFilter);

  return content && data ? (
    <div className="card-chart">
      <div className="card-header">
        <div className="card-title">
          <SectionTitle>{content.manager_app_average_per_hour_day}</SectionTitle>
          <AveragePerformancePerHourInfo />
        </div>
        <SalesLegend hideCompare />
      </div>
      <div className={classnames("card-content no-card", { loading, error })}>
        {/* {loading && <Body className="loading-text">{loading && "Loading..."}</Body>} */}
        <BarChart
          domain={data?.bars?.map((b: any) => b.label) || []}
          data={data?.bars || []}
          lineData={performanceType === PERFORMANCE_TYPE.TARGET ? [] : data?.line || []}
          height={300}
          tooltipElement={ChartTooltip}
          margin={{ top: 5, left: 40, right: 0, bottom: 20 }}
          tooltipYOffset={-100}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
export const GroupTimeCardHour = withAggRequest(GHour);
export const GroupTimeCardWeek = withAggRequest(GWeek);
