import React, { useCallback } from "react";
import classnames from "classnames";
import { Body } from "@ddm-design-system/typography";
import { IOutlet, IPressureChamber } from "../../store/installation/types";
import useContent from "../../hooks/useContent";
import PressureChamberCard from "./PressureChamberCard";
import "./installation.scss";

export interface IProps {
  outlet?: IOutlet;
  onPressureChamberEdit: (outletId: string | undefined, pressureChamber: IPressureChamber) => void;
}

export const OutletInstallation: React.FC<IProps> = ({ outlet, onPressureChamberEdit }) => {
  const { managerAppInstallation: content } = useContent();

  const handlePressureChamberEdit = useCallback(
    pressureChamber => {
      onPressureChamberEdit(outlet?.outletId, pressureChamber);
    },
    [outlet, onPressureChamberEdit]
  );

  return (
    <div
      className={classnames("installation-page-installation", {
        empty: outlet?.pressureChambers.length === 0
      })}
    >
      {outlet?.pressureChambers.length === 0 ? (
        <Body>{content.manager_app_pressure_chambers_empty}</Body>
      ) : (
        outlet?.pressureChambers.map(pc => (
          <PressureChamberCard
            key={pc.id}
            pressureChamber={pc}
            onEdit={handlePressureChamberEdit}
          />
        ))
      )}
    </div>
  );
};

export default OutletInstallation;
