import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ButtonText, SectionTitle, Description, Body } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { Menu, MenuItem } from "@ddm-design-system/menu";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "@ddm-design-system/modal";
import { SecondaryButton, DangerButton } from "@ddm-design-system/button";
import { getUser } from "../../../store/profile/reducer";
import useContent from "../../../hooks/useContent";
import Routes from "../../../routes";
import "./profile.scss";

export const Profile: React.FC<RouteComponentProps> = ({ history }) => {
  const { managerAppCommon: content } = useContent();

  const [logoutModal, setLogoutModal] = useState(false);

  const me: any = useSelector(getUser);

  const renderHeader = () => (
    <div className="profileItem">
      <Icon className="profileIcon" name="user" size={24} />
      <ButtonText className="profileName" data-hj-surpress>
        {me.name}
      </ButtonText>
    </div>
  );

  return me && content ? (
    <>
      <div className="profileContainer">
        <Menu renderHeader={renderHeader} direction="bottom-right">
          <MenuItem key="profile-menu-title" className="profile-menu-title">
            <>
              <SectionTitle>{me.name}</SectionTitle>
              <Description className="profile-menu-email">{me.username}</Description>
            </>
          </MenuItem>
          <MenuItem
            key="profile-menu-logout"
            icon="exit"
            className="profile-menu-logout"
            onClick={() => setLogoutModal(true)}
          >
            <ButtonText> {content.manager_app_logout}</ButtonText>
          </MenuItem>
        </Menu>
      </div>

      <Modal
        isOpen={logoutModal}
        className="profile-logout-modal"
        onRequestClose={() => setLogoutModal(false)}
      >
        <ModalHeader icon={<Icon name="exit" />} title={content.manager_app_logout} />
        <ModalBody>
          <Body>{content.manager_app_logout_message}</Body>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={() => setLogoutModal(false)}>Cancel</SecondaryButton>
          <DangerButton
            onClick={() => {
              history.push(Routes.logout);
              setLogoutModal(false);
            }}
          >
            {content.manager_app_logout_confirm}
          </DangerButton>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    <></>
  );
};
export default withRouter(Profile);
