import React, { useCallback, useContext, useMemo, useState } from "react";
import * as d3 from "d3";
import { BarChartAvg } from "@ddm-design-system/bar-chart";
import { PrimaryButton } from "@ddm-design-system/button";
import { StatusChip } from "@ddm-design-system/chip";
import { Divider } from "@ddm-design-system/divider";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { ProgressBar } from "@ddm-design-system/progress-bar";
import {
  Body,
  BodyHighlight,
  Description,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import Time from "../../lib/Time/Time";
import useContent from "../../hooks/useContent";
import useIsMobile from "../../hooks/useIsMobile";
import { EPerformanceLevel, IProductInfo } from "../../store/product/types";
import { BeverageTranslation } from "../../store/beers/types";
import { BeerType } from "../../services/aggregator";
import StyleConstants, {
  translatedBeerType,
  LOWER_PERFORMANCE_THRESHOLD_PCT,
  MAX_PERFORMANCE_BAR_VALUE,
  UPPER_PERFORMANCE_THRESHOLD_PCT
} from "../../constants";
import { getPerformanceBadgeIcon } from "../../helpers";
import ProductBeverageDataItem from "./ProductBeverageDataItem";
import { AnalyticsContext } from "../../services/analytics";
import "./product.scss";

export interface IProps {
  avatarOptions: any;
  beverage?: IProductInfo & { beverage: BeverageTranslation };
  isOpen: boolean;
  handleClose: () => void;
}

const ProductBeverageDetailsModal: React.FC<IProps> = ({
  avatarOptions,
  beverage,
  isOpen,
  handleClose
}) => {
  const analytics = useContext(AnalyticsContext);
  const { managerAppProduct: content, managerAppCommon: common } = useContent();
  const isMobile = useIsMobile(StyleConstants.mobileBreakpoint3);
  const [hasError, setHasError] = useState(false);
  const previousWeeks = useMemo(() => new Time().get4PreviousWeeks().reverse(), []);
  const {
    avgKegsPerWeek = 0,
    beverage: { abv, carlsShopUrl, countryOfOrigin, craftAndSpeciality, name, type },
    kegsPerWeek,
    performance,
    tapPositions,
    volumePerTap,
    validVolumePercentage = 0
  } = beverage || { beverage: {} };

  const domain = useMemo(
    () =>
      previousWeeks.map((previousWeek, i) =>
        i === previousWeeks.length - 1
          ? content.manager_app_product_current_week
          : `${d3.timeFormat("%d %b")(previousWeek.firstWeekDay.toJSDate())} - ${d3.timeFormat(
              "%d %b"
            )(previousWeek.lastWeekDay.toJSDate())}`
      ),
    [content, previousWeeks]
  );

  const data = useMemo(
    () =>
      beverage
        ? domain.map((dataPointLabel, i) => ({
            label: dataPointLabel,
            value: kegsPerWeek?.[i] || 0
          }))
        : [],
    [beverage, domain, kegsPerWeek]
  );

  const showChart = useMemo(() => !!kegsPerWeek?.find(value => !!value), [kegsPerWeek]);

  const onError = () => {
    setHasError(true);
  };

  const handleCarlshopClick = useCallback(() => {
    analytics.logEvent("CLICK_ORDER_CARLSHOP", name);

    if (window) {
      window.open(carlsShopUrl, "_blank");
    }
  }, [analytics, name, carlsShopUrl]);

  return (
    <Modal
      className="product-beverage-details-modal"
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      {
        // separate check for ModalHeader bc it needs to be a direct descendant of Modal for the close button to work
        beverage ? (
          <ModalHeader
            className="product-beverage-details-modal-header"
            title=""
            showDivider={false}
          />
        ) : (
          <></>
        )
      }
      {beverage ? (
        <>
          <ModalBody className="product-beverage-details-modal-body">
            {avatarOptions ? (
              avatarOptions.src && !hasError ? (
                <img
                  src={avatarOptions.src}
                  className="product-beverage-logo"
                  alt={name}
                  onError={onError}
                />
              ) : (
                <div
                  className="product-beverage-logo product-beverage-logo--fallback"
                  style={{ backgroundColor: avatarOptions.backgroundColor }}
                >
                  <SectionTitle>{avatarOptions.fallbackText}</SectionTitle>
                </div>
              )
            ) : null}
            <Subtitle>{name}</Subtitle>
            <Body className="product-beverage-type">
              {common[`common_beer_type_${translatedBeerType[craftAndSpeciality as BeerType]}`]}
            </Body>

            <div className="product-beverage-data product-beverage-data--vertical">
              <ProductBeverageDataItem label={common.common_tap} isVertical>
                {tapPositions && tapPositions.length > 0 ? tapPositions?.join(", ") : "--"}
              </ProductBeverageDataItem>

              <Divider className="product-beverage-data-divider" orientation="vertical" />

              <ProductBeverageDataItem label={content.manager_app_product_beer_type} isVertical>
                {type}
              </ProductBeverageDataItem>

              <Divider className="product-beverage-data-divider" orientation="vertical" />

              <ProductBeverageDataItem label={content.manager_app_product_country} isVertical>
                {countryOfOrigin ?? "--"}
              </ProductBeverageDataItem>

              <Divider className="product-beverage-data-divider" orientation="vertical" />

              <ProductBeverageDataItem label={content.manager_app_product_abv} isVertical>
                {abv ?? "--"}
              </ProductBeverageDataItem>
            </div>

            <Description className="product-beverage-chart-title">
              {content.manager_app_product_kegs_spent}
            </Description>

            {showChart ? (
              <div className="product-beverage-chart-wrapper">
                <BarChartAvg
                  avg={avgKegsPerWeek}
                  data={data}
                  domain={domain}
                  height={180}
                  lineDataLabel={content.manager_app_product_average}
                  // setting left: 0 results in a bug where x axis doesn't have text
                  margin={{ left: 1, top: 16, bottom: isMobile ? 40 : 20 }}
                  numTicks={1}
                />
              </div>
            ) : (
              <BodyHighlight className="product-beverage-chart-missing">
                {content.manager_app_product_details_chart_missing}
              </BodyHighlight>
            )}

            <StatusChip
              className="product-beverage-badge"
              type={getPerformanceBadgeIcon(performance, validVolumePercentage)}
              selected
            >
              {!validVolumePercentage
                ? content.manager_app_insights_not_enough_data
                : performance === EPerformanceLevel.overperforming
                ? content.manager_app_insights_outstanding_performance
                : performance === EPerformanceLevel.good
                ? content.manager_app_insights_good_performance
                : content.manager_app_insights_under_performance}
            </StatusChip>

            <Body>
              {!validVolumePercentage
                ? content.manager_app_insights_not_enough_data_description
                : performance === EPerformanceLevel.overperforming
                ? content.manager_app_insights_consider_adding_tap
                : performance === EPerformanceLevel.good
                ? content.manager_app_insights_pouring_as_expected
                : content.manager_app_insights_consider_promotion}
            </Body>

            <ProgressBar
              className="performance-meter-wrapper"
              label={content.manager_app_insights_performance_meter_label.replace(
                "%litres%",
                `${Math.round((volumePerTap || 0) * 10) / 10}`
              )}
              leftDividerValue={LOWER_PERFORMANCE_THRESHOLD_PCT}
              rightDividerValue={UPPER_PERFORMANCE_THRESHOLD_PCT}
              showDividerIcons
              showDividers
              value={
                ((volumePerTap || 0) * 100.0) /
                (MAX_PERFORMANCE_BAR_VALUE * (validVolumePercentage / 100))
              }
            />
          </ModalBody>
          {carlsShopUrl ? (
            <ModalFooter className="product-beverage-details-modal-footer">
              <PrimaryButton onClick={handleCarlshopClick}>
                {content.manager_app_product_order_at_carl_shop}
              </PrimaryButton>
            </ModalFooter>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default ProductBeverageDetailsModal;
