import React, { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { ExpandablePanels, ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { Icon } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { SectionTitle, BodyHighlight } from "@ddm-design-system/typography";
import { useInfiniteScrollList } from "@ddm-design-system/hooks";
import { IOutlet, IOutletTags, IPressureChamber } from "../../store/installation/types";
import {
  getIsLoadingPressureChambers,
  getAllPressureChambersTags,
  getOutletPressureChambersTags,
  getOutletsWithTranslations,
  getTags
} from "../../store/installation/selectors";
import useContent from "../../hooks/useContent";
import { getIsMobile } from "../../helpers";
import OutletInstallation from "./OutletInstallation";
import EditBeverageModal from "./EditBeverageModal";
import InstallationStatusTooltip from "./InstallationStatusTooltip";
import StyleConstants from "../../constants";
import { getChosenOutletId } from "../../store/filter/selectors";
import "./installation.scss";

const getSortedOutletsArray = (outlets: { [id: string]: IOutlet }) => {
  return Object.values(outlets)
    .filter(outlet => !!outlet.outletName)
    .sort((outletA, outletB) =>
      outletA.outletName.toLocaleLowerCase() < outletB.outletName.toLocaleLowerCase() ? -1 : 1
    );
};

const INTERSECTION_OPTIONS = {
  intersectionOptions: {
    root: document.querySelector("#root"),
    rootMargin: "0px 0px 200px 0px"
  },
  wrappedRef: true
};

export const Installation: React.FC = () => {
  const { managerAppCommon: content } = useContent();
  const isMobile = getIsMobile(StyleConstants.mobileBreakpoint2);

  const selectedOutletId: string = useSelector(getChosenOutletId);
  const outlets: { [id: string]: IOutlet } = useSelector(getOutletsWithTranslations);
  const selectedOutlet = selectedOutletId ? outlets[selectedOutletId] : null;
  const tags: IOutletTags = useSelector(
    selectedOutlet ? getOutletPressureChambersTags : getAllPressureChambersTags
  );
  const loadingPressureChambers: boolean = useSelector(getIsLoadingPressureChambers);
  const [editedPressureChamber, setEditedPressureChamber] = useState<{
    pressureChamber: IPressureChamber;
    outletId: string;
  }>();

  const handlePressureChamberEdit = useCallback((outletId, pressureChamber) => {
    setEditedPressureChamber({ outletId, pressureChamber });
  }, []);

  const handleEditModalClose = useCallback(() => {
    setEditedPressureChamber(undefined);
  }, []);

  const renderOutletTags = useCallback(
    (outlet: IOutlet) => {
      const outletTags = getTags(outlet);
      const iconSize = isMobile ? 24 : undefined;

      return (
        <div className="outlet-panel-tags">
          <span className="outlet-panel-tag">
            <Icon className="outlet-panel-tag-icon" name="ok" size={iconSize} />
            <BodyHighlight>{`${outletTags.okTags}`}</BodyHighlight>
          </span>
          <span className="outlet-panel-tag">
            <Icon className="outlet-panel-tag-icon" name="warning" size={iconSize} />
            <BodyHighlight>{`${outletTags.warningTags}`}</BodyHighlight>
          </span>
          <span className="outlet-panel-tag">
            <Icon className="outlet-panel-tag-icon" name="error" size={iconSize} />
            <BodyHighlight>{`${outletTags.dangerTags}`}</BodyHighlight>
          </span>
          <span className="outlet-panel-tag">
            <Icon className="outlet-panel-tag-icon" name="offline" size={iconSize} />
            <BodyHighlight>{`${outletTags.offlineTags}`}</BodyHighlight>
          </span>
        </div>
      );
    },
    [isMobile]
  );

  const outletsToShow = useMemo(
    () =>
      getSortedOutletsArray(outlets).map((outlet: IOutlet) => (
        <ExpandablePanel
          className="outlet-panel"
          key={outlet.outletName}
          title={outlet.outletName}
          renderHeaderDetails={() => renderOutletTags(outlet)}
        >
          <OutletInstallation outlet={outlet} onPressureChamberEdit={handlePressureChamberEdit} />
        </ExpandablePanel>
      )),
    [handlePressureChamberEdit, outlets, renderOutletTags]
  );

  const infiniteOutlets = useInfiniteScrollList(outletsToShow, INTERSECTION_OPTIONS);

  return (
    <div className="installation-page">
      <div className={classnames("installation-page-status", { loading: loadingPressureChambers })}>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#6CD28B",
            children: <Icon name="ok" fill="white" />
          }}
          className="installation-page-infoicon"
          title="Ok"
          withPadding
        >
          {loadingPressureChambers ? "" : `${tags.okTags}`}
        </Infoicon>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#FFB400",
            children: <Icon name="warning" fill="white" />
          }}
          className="installation-page-infoicon"
          title="Warning"
          withPadding
        >
          {loadingPressureChambers ? "" : `${tags.warningTags}`}
        </Infoicon>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#D86464",
            children: <Icon name="error" fill="white" />
          }}
          className="installation-page-infoicon"
          title="Error"
          withPadding
        >
          {loadingPressureChambers ? "" : `${tags.dangerTags}`}
        </Infoicon>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#CCCCCC",
            children: <Icon name="offline" fill="white" />
          }}
          className="installation-page-infoicon"
          title="Offline"
          withPadding
        >
          {loadingPressureChambers ? "" : `${tags.offlineTags}`}
        </Infoicon>
      </div>

      <div className="header">
        <SectionTitle>{content.manager_app_installation_status}</SectionTitle>
        <InstallationStatusTooltip />
      </div>
      <div className={classnames("devicesRow", loadingPressureChambers && "loading")}>
        {loadingPressureChambers ? (
          [
            <div key={1} className="device-card loading" />,
            <div key={2} className="device-card loading" />,
            <div key={3} className="device-card loading" />,
            <div key={4} className="device-card loading" />,
            <div key={5} className="device-card loading" />,
            <div key={6} className="device-card loading" />,
            <div key={7} className="device-card loading" />,
            <div key={8} className="device-card loading" />
          ]
        ) : selectedOutlet ? (
          <OutletInstallation
            outlet={selectedOutlet}
            onPressureChamberEdit={handlePressureChamberEdit}
          />
        ) : (
          <ExpandablePanels className="outlet-panels">{infiniteOutlets}</ExpandablePanels>
        )}
      </div>

      {editedPressureChamber && (
        <EditBeverageModal
          outletId={editedPressureChamber.outletId}
          pressureChamber={editedPressureChamber.pressureChamber}
          onClose={handleEditModalClose}
        />
      )}
    </div>
  );
};
