import React from "react";
import { IGoogleLocationDataInput } from "../store/outlet/types";

const map = document.createElement("div");

export interface IPlacesService {
  setCountry(newCountry: string): void;
  getAutocompletePredictions(query: string, type: AutocompleteType): Promise<IPlaceSuggestion[]>;
  getPlaceDetails(placeId: string): Promise<IGoogleLocationDataInput>;
}

export type IPlaceSuggestion = google.maps.places.AutocompletePrediction;

export type IOpeningPeriod = google.maps.places.OpeningPeriod;

export type AutocompleteType = "geocode" | "address" | "establishment" | "regions" | "cities";

export class PlacesService implements IPlacesService {
  private country?: string;

  private api = new google.maps.places.AutocompleteService();

  private placeApi = new google.maps.places.PlacesService(map);

  private sessionToken = new google.maps.places.AutocompleteSessionToken();

  setCountry(newCountry: string) {
    this.country = newCountry;
  }

  private convertWeekdayRange(weekday: number) {
    return ((weekday + 6) % 7) + 1;
  }

  private convertOpeningHoursFromString(str: string) {
    return parseInt(str.substr(0, 2)) * 60 + parseInt(str.substr(2, 2));
  }

  // eslint-disable-next-line no-underscore-dangle
  private async _getAutocompletePredictions(query: string, type: AutocompleteType) {
    const results = await new Promise<IPlaceSuggestion[]>(resolve =>
      this.api.getPlacePredictions(
        {
          input: query,
          types: [type],
          componentRestrictions: { country: this.country ?? "" },
          sessionToken: this.sessionToken
        },
        result => resolve(result ?? [])
      )
    );
    if (results.length) {
      return results;
    }

    return new Promise<IPlaceSuggestion[]>(resolve =>
      this.api.getPlacePredictions(
        {
          input: query,
          componentRestrictions: { country: this.country ?? "" },
          sessionToken: this.sessionToken
        },
        result => resolve(result ?? [])
      )
    );
  }

  async getAutocompletePredictions(query: string, type: AutocompleteType) {
    // eslint-disable-next-line no-underscore-dangle
    const predictions = await this._getAutocompletePredictions(query, type);
    if (!predictions.length) {
      return [];
    }
    return predictions;
  }

  getPlaceDetails = (placeId: IPlaceSuggestion["place_id"]) =>
    new Promise<IGoogleLocationDataInput>(resolve =>
      this.placeApi.getDetails({ placeId, sessionToken: this.sessionToken }, data => {
        const { lat, lng } = data.geometry?.location ?? {};
        let openingHours = data.opening_hours?.periods;
        if (openingHours?.length && !openingHours[0].close) {
          openingHours = Array.from({ length: 7 }, (_, i) => i).map(
            i =>
              ({
                open: {
                  day: i,
                  time: "0000"
                },
                close: {
                  day: i,
                  time: "0000"
                }
              } as IOpeningPeriod)
          );
        }

        const locationData: IGoogleLocationDataInput = {
          googleBusinessName: data.name,
          googleBusinessId: placeId,
          coordinates: { latitude: lat!(), longitude: lng!() },
          address: data.formatted_address ?? "",
          locationOpeningHours:
            data.opening_hours?.periods.map(row => {
              const weekday = this.convertWeekdayRange(row.open.day);
              const startTime = this.convertOpeningHoursFromString(row.open.time);
              const endTime = row.close
                ? this.convertOpeningHoursFromString(row.close.time)
                : 24 * 60 - 1;
              return { weekday, startTime, endTime };
            }) ?? []
        };
        resolve(locationData);
      })
    );
}

export const PlacesContext = React.createContext<IPlacesService>(new PlacesService());
export const PlacesProvider = PlacesContext.Provider;
export const PlacesConsumer = PlacesContext.Consumer;
