import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { DarkPrimaryButton } from "@ddm-design-system/button";
import { Card, CardBody } from "@ddm-design-system/card";
import { Divider } from "@ddm-design-system/divider";
import { ModalTooltip } from "@ddm-design-system/modal";
import {
  Body,
  BodyHighlight,
  Description,
  DescriptionMedium,
  SectionTitle
} from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import useIsTablet from "../../../hooks/useIsTablet";
import useScroll from "../../../hooks/useScroll";
import { ITarget } from "../../../store/targets/types";
import { IWithAggRequestProps, withAggRequest } from "../../common/hoc/withAggRequest";
import MonthlyTarget from "./MonthlyTarget";
import AddTargetModal from "./AddTargetModal";
import { getChosenOutletId } from "../../../store/filter/selectors";
import "./target.scss";
import { AnalyticsContext } from "../../../services/analytics";

const TargetCard: React.FC<IWithAggRequestProps> = ({ data }) => {
  const { managerAppOverview: content } = useContent();
  const analytics = useContext(AnalyticsContext);
  const isTablet = useIsTablet();
  const cardBodyRef = useRef<HTMLDivElement>(null);
  const cardBodyScrollObject = useScroll(cardBodyRef.current ?? undefined);
  const selectedOutletId = useSelector(getChosenOutletId);
  const [isAddTargetModalVisible, setIsAddTargetModalVisible] = useState(false);
  const [editedTarget, setEditedTarget] = useState<ITarget | null>(null);

  const hasTargets = data && data.length > 0;
  const isOnlyTarget = (data && data.length === 1) || false;
  const showScrollGradient = useMemo(
    () =>
      !isTablet &&
      cardBodyRef &&
      data &&
      data.length &&
      !isOnlyTarget &&
      !cardBodyScrollObject.hasScrolledToEnd,
    [cardBodyScrollObject.hasScrolledToEnd, data, isOnlyTarget, isTablet]
  );

  const handleShowAddTargetModal = useCallback((target?: ITarget) => {
    setIsAddTargetModalVisible(true);

    if (target) {
      setEditedTarget(target);
    }
  }, []);

  const handleCloseAddTargetModal = useCallback(() => {
    setIsAddTargetModalVisible(false);
    setEditedTarget(null);
  }, []);

  const renderTitle = () => (
    <div className="target-title">
      <SectionTitle>{content.target_title}</SectionTitle>
      <ModalTooltip
        className="target-tooltip"
        title={content.target_tooltip_title}
        onAfterOpen={() => analytics.logEvent("TOOLTIP", "Targets")}
      >
        <div>
          <div className="target-tooltip-section">
            <Description>
              <ReactMarkdown>{content.target_tooltip_text}</ReactMarkdown>
            </Description>
          </div>
          <Divider />
          <div className="target-tooltip-section">
            <DescriptionMedium>{content.target_tooltip_volume_title}</DescriptionMedium>
            <Description>{content.target_tooltip_volume_text}</Description>
          </div>
          <Divider />
          <div className="target-tooltip-section">
            <DescriptionMedium>{content.target_tooltip_speciality_title}</DescriptionMedium>
            <Description>{content.target_tooltip_speciality_text}</Description>
          </div>
        </div>
      </ModalTooltip>
    </div>
  );

  const renderTabletCards = () => (
    <div className="target-card-wrapper">
      {data?.map((target: any, index: number) => (
        <div className="target-card-container" key={target.date.getTime()}>
          <Card className={classnames("target-card", isOnlyTarget && "target-card--single")}>
            <CardBody className="target-card-body">
              <MonthlyTarget
                isOnlyTarget={isOnlyTarget}
                previousTarget={index < data.length - 2 ? data[index + 1] : undefined}
                target={target}
                showAddTargetModal={handleShowAddTargetModal}
              />
            </CardBody>
          </Card>
        </div>
      ))}
    </div>
  );

  return (
    <div className="target">
      {renderTitle()}
      {isTablet && selectedOutletId && data && hasTargets ? (
        renderTabletCards()
      ) : (
        <Card
          className={classnames(
            "target-card",
            !hasTargets && selectedOutletId && "target-card--missing",
            !selectedOutletId && "target-card--unavailable"
          )}
        >
          <CardBody
            wrappedRef={cardBodyRef}
            className={classnames(
              "target-card-body ddm-scrollbar",
              isTablet && "target-card-container"
            )}
          >
            {showScrollGradient ? <div className="target-card-body-overflow" /> : <></>}
            {!selectedOutletId ? (
              <>
                <div className="target-card-content">
                  <Body>{content.target_unavailable}</Body>
                </div>
              </>
            ) : data && hasTargets ? (
              data.map((target: any, index: number) => {
                return (
                  <React.Fragment key={target.date.getTime()}>
                    <MonthlyTarget
                      isOnlyTarget={isOnlyTarget}
                      previousTarget={index <= data.length - 2 ? data[index + 1] : undefined}
                      target={target}
                      showAddTargetModal={handleShowAddTargetModal}
                    />
                    {index + 1 < data.length && <Divider />}
                  </React.Fragment>
                );
              })
            ) : (
              <>
                <div className="target-card-content">
                  <BodyHighlight className="target-card-advice">
                    {content.target_advice}
                  </BodyHighlight>
                  <Body className="target-card-instructions">
                    <ReactMarkdown>{content.target_instructions}</ReactMarkdown>
                  </Body>
                </div>
                <DarkPrimaryButton
                  className="target-card-add-button"
                  onClick={() => handleShowAddTargetModal()}
                >
                  {content.target_add_targets}
                </DarkPrimaryButton>
              </>
            )}
          </CardBody>
        </Card>
      )}
      <AddTargetModal
        isOpen={isAddTargetModalVisible}
        target={editedTarget ?? undefined}
        onClose={handleCloseAddTargetModal}
      />
    </div>
  );
};

export default withAggRequest(TargetCard);
