import React, { useState } from "react";
import "react-dates/initialize";
import { FocusedInputShape, DayPickerRangeController } from "react-dates";
import moment, { Moment } from "moment";
import StyleConstants from "../../../constants";
import "react-dates/lib/css/_datepicker.css";
import "./dates_picker.scss";
import Time from "../../../lib/Time/Time";
import useIsMobile from "../../../hooks/useIsMobile";

export interface IDatesRange {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface IProps {
  startDate?: Time | null;
  endDate?: Time | null;
  onDatesChange: (arg: IDatesRange) => void;
}

export const DatesPicker: React.FC<IProps> = ({ startDate, endDate, onDatesChange }) => {
  const [timeframe, setTimeframe] = useState<FocusedInputShape>("startDate");
  const isMobile1 = useIsMobile();
  const isMobile2 = useIsMobile(StyleConstants.mobileBreakpoint2);
  const isMobile3 = useIsMobile(StyleConstants.mobileBreakpoint3);
  const singleMonth = (isMobile1 && !isMobile2) || isMobile3;
  return (
    <div className="dates-picker">
      <DayPickerRangeController
        noBorder
        hideKeyboardShortcutsPanel
        startDate={startDate ? moment(startDate.toJSDate()) : null}
        endDate={endDate ? moment(endDate.toJSDate()) : null}
        onDatesChange={onDatesChange}
        focusedInput={timeframe}
        minimumNights={0}
        numberOfMonths={singleMonth ? 1 : 2}
        isDayBlocked={(day: Moment) => day.isAfter(moment().endOf("day"))}
        initialVisibleMonth={() => (singleMonth ? moment() : moment().subtract(1, "month"))}
        onFocusChange={focusedInput => setTimeframe(focusedInput || "startDate")}
      />
    </div>
  );
};

export default DatesPicker;
