import produce from "immer";
import { Reducer } from "redux";
import {
  GET_OUTLET_LOCATION_DATA_SUCCESS,
  IOutlet,
  IOutletState,
  OutletActionTypes,
  REQUEST_OUTLETS_ERROR,
  SET_OUTLET,
  SET_OUTLET_FAVOURITE,
  SET_OUTLET_FAVOURITE_ERROR,
  SET_OUTLET_PICKER_TAB,
  SET_OUTLET_VIEWED,
  SET_OUTLETS,
  SET_QUESTIONNAIRE_SUBMITTED
} from "./types";

export const initialState: IOutletState = {
  currentOutlet: null,
  currentOutletPickerTab: 0,
  outlets: [],
  outletsError: false,
  outletsLocationData: {}
};

const reducer: Reducer<IOutletState, OutletActionTypes> = (
  state = initialState,
  action: OutletActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_OUTLET:
        draft.currentOutlet = action.payload;
        break;
      case SET_OUTLET_PICKER_TAB:
        draft.currentOutletPickerTab = action.payload;
        break;
      case SET_OUTLETS:
        const outlets: any = action.payload;
        draft.outlets = outlets.sort((a: any, b: any) =>
          a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
        );
        draft.outletsError = false;
        break;
      case REQUEST_OUTLETS_ERROR:
        draft.outletsError = true;
        break;
      case SET_OUTLET_VIEWED:
        const newOutlets = draft.outlets.slice(0);
        const outlet = newOutlets.find((o: IOutlet) => o.id === action.payload);
        if (outlet) {
          outlet.newAssociation = false;
        }
        draft.outlets = newOutlets;
        break;
      case SET_QUESTIONNAIRE_SUBMITTED:
        const qOutlets = draft.outlets.slice(0);
        const qOutlet = qOutlets.find((o: IOutlet) => o.id === action.payload);
        if (qOutlet) {
          qOutlet.questionnaireSubmitted = true;
          draft.currentOutlet = qOutlet;
        }
        draft.outlets = qOutlets;
        break;
      case GET_OUTLET_LOCATION_DATA_SUCCESS:
        const newData = { ...state.outletsLocationData };
        newData[action.payload.outletId] = action.payload.data;
        draft.outletsLocationData = newData;
        break;
      case SET_OUTLET_FAVOURITE:
        draft.outlets = state.outlets.map(o =>
          o.id !== action.payload.outletId ? o : { ...o, favourite: action.payload.favourite }
        );
        break;
      case SET_OUTLET_FAVOURITE_ERROR:
        draft.outlets = state.outlets.map(o =>
          o.id !== action.payload.outletId ? o : { ...o, favourite: !action.payload.favourite }
        );
        break;
      default:
        break;
    }
  });

export default reducer;
